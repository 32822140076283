import React from 'react';
import Header from '../../components/Header';
import Footer from './Footer';
import { DeveronLogo, TranslatorLogo } from '../../assets/icons';

const AppLayout = (props) => {
  return (
    <>
      <article className="flex flex-col justify-between h-screen">
        <Header logo={<DeveronLogo />} icon={<TranslatorLogo />} />
        <main className="flex-1">{props.children}</main>
        <Footer />
      </article>
    </>
  );
};

export default AppLayout;

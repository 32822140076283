import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  FileInputDraggableContainer,
  SelectedFilesDisplay,
  SelectedFilesDisplayContainer,
} from '../../../components/FileInput';
import Button from '../../../components/Button';
import Modal from '../../../components/Modal';
import Hero from '../../../components/Hero';
import { selectFileMultiple } from '../../../features/rawDataSlice';
import { appendDateToFileName } from '../../../helpers';
import { format } from 'date-fns';
import Papa from 'papaparse';
import PreviewTable from '../PreviewTable/';
import { Check, Download, File, Preview, Refresh } from '../../../assets/icons';
import Analytics from '../../../helpers/analytics';
import { useMemo } from 'react';
import { MIXPANEL_EVENTS } from '../../../constants/analyticsConstants';

const TranslatedPage = () => {
  const storeState = useSelector((state) => state.rawDataStore.rawData);
  const translatedData = useSelector(selectFileMultiple);
  const [showModal, setShowModal] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const date = format(new Date(), 'MM/dd/yyyy');

  const dataForTable = translatedData;

  let finalData = [];

  const bufferObj = useMemo(() => {
    return {
      ...dataForTable,
      fileName: appendDateToFileName(dataForTable?.fileName, date),
    };
  }, [dataForTable, date]);

  finalData.push(bufferObj);
  useEffect(() => {
    Analytics.trackEvent(MIXPANEL_EVENTS.TRANSLATION_COMPLETE, {
      filesSelected: location.state.filesSelected,
      filesTranslated: location.state.filesTranslated,
      totalSamples: location.state.totalSamples,
      validSamples: location.state.validSamples,
      fileName: bufferObj.fileName,
      totalResults: storeState.length,
    });
  }, [bufferObj, storeState, location]);
  const toggleModal = () => {
    setShowModal(!showModal);
    Analytics.trackEvent(showModal ? MIXPANEL_EVENTS.CLICKED_CLOSE_PREVIEW : MIXPANEL_EVENTS.CLICKED_PREVIEW, {
      fileName: bufferObj.fileName,
      type: 'translated',
    });
  };

  const downloadCSVData = () => {
    Analytics.trackEvent(MIXPANEL_EVENTS.CLICKED_DOWNLOAD);
    let csv = Papa.unparse(bufferObj.rows);
    let csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    let csvURL = null;
    if (navigator.msSaveBlob) {
      csvURL = navigator.msSaveBlob(csvData, 'download.csv');
    } else {
      csvURL = window.URL.createObjectURL(csvData);
    }
    let tempLink = document.createElement('a');
    tempLink.href = csvURL;
    tempLink.setAttribute('download', `${bufferObj.fileName}`);
    tempLink.click();
  };

  //This redirects to home if store/page gets refreshed
  useEffect(() => {
    if (storeState <= 0) {
      navigate('/');
    }
  }, [storeState, navigate]);

  return (
    <div>
      {showModal && (
        <Modal title={`Preview Data: ${bufferObj?.fileName}`} onClose={() => toggleModal()}>
          <PreviewTable i={0} data={finalData} />
        </Modal>
      )}
      <Hero title="Translation Complete!" text="Download your translated file" />
      <FileInputDraggableContainer>
        <SelectedFilesDisplayContainer>
          <SelectedFilesDisplay
            fileIcon={<File />}
            previewIcon={<Preview />}
            file={bufferObj}
            finishedIcon={<Check color="#269B53" />}
            onBtnClick={() => toggleModal()}
          />
        </SelectedFilesDisplayContainer>
      </FileInputDraggableContainer>
      <div className="flex justify-between">
        <Button
          color="secondary"
          imgPosition="left"
          className="mt-8"
          btnClick={() => {
            Analytics.trackEvent(MIXPANEL_EVENTS.TRANSLATE_MORE_FILES);
            navigate('/');
          }}
        >
          <Refresh />
          Translate More Files
        </Button>
        <Button
          color="primary"
          imgPosition="right"
          className="mt-8 items-center"
          btnClick={downloadCSVData}
        >
          Download
          <Download />
        </Button>
      </div>
    </div>
  );
};

export default TranslatedPage;

import React from 'react';
import classNames from 'classnames';

/*
Button props:
  color: "primary" || "secondary" || "text",
  className : string,
  imgPosition: "left" || "right",
  imgSrc: "path for image on the left",
*/

const Button = ({
  color,
  className,
  imgPosition,
  imgSrc,
  btnClick,
  children,
  disabled,
  cursor,
  hidden,
}) => {
  const buttonClasses = classNames(
    {
      'bg-primary-500 py-3 px-7 rounded text-neutral-50 font-bold uppercase text-base z-10 flex items-center gap-2.5 hover:bg-primary-800':
        color === 'primary',
    },
    {
      'border border-primary-500 py-3 px-7 rounded font-bold text-primary-500 uppercase text-base z-10 flex items-center gap-2.5 hover:bg-primary-50':
        color === 'secondary',
    },
    {
      'text-primary-500 uppercase text-sm z-10 font-bold flex items-center gap-2': color === 'text',
    },
    {
      'text-neutral-400 cursor-not-allowed opacity-50': disabled,
    },
    {
      'cursor-not-allowed': cursor === 'not-allowed', 
    },
    {
      '!hidden': hidden,
    },
    className,
  );

  return (
    <>
      <button className={buttonClasses} onClick={() => btnClick()} disabled={disabled}>
        {imgPosition === 'left' && imgSrc ? <img src={imgSrc} alt="button_img" /> : null}
        {children}
        {imgPosition === 'right' && imgSrc ? <img src={imgSrc} alt="button_img" /> : null}
      </button>
    </>
  );
};

export default Button;

import React from 'react';
import { Link } from 'react-router-dom';

const Redirect = () => {
  return (
    <main className="text-center">
      <div className="px-2 py-4 sm:px-6 sm:py-10 text-center">
        <div className="w-4/4 sm: mx-auto mt-20">
          <p>There's nothing here!</p>
          <Link to={'/'}>Go Home</Link>
        </div>
      </div>
    </main>
  );
};

export default Redirect;

export const masterCSVData = [
  {
    'Opportunity ID': '0061K00000jm6Gx',
    'Opportunity Name': '2022 CNMP Koehn 19 SW',
    'Account Name': 'David Koehn',
    Field: '19 SW',
    'Program ID': 'DV5102',
  },
  {
    'Opportunity ID': '0061K00000jm5Rl',
    'Opportunity Name': '2022 CNMP J&S 141 NW',
    'Account Name': 'J & S Joint Ventures',
    Field: '141 NW',
    'Program ID': 'DV5055',
  },
  {
    'Opportunity ID': '0061K00000jm5Rm',
    'Opportunity Name': '2022 CNMP J&S 154 SW',
    'Account Name': 'J & S Joint Ventures',
    Field: '154 SW',
    'Program ID': 'DV5058',
  },
  {
    'Opportunity ID': '0061K00000jm5gF',
    'Opportunity Name': '2022 CNMP DeBoer M2',
    'Account Name': 'Abel DeBoer',
    Field: 'M2',
    'Program ID': 'DV5079',
  },
  {
    'Opportunity ID': '0061K00000jlkLk',
    'Opportunity Name': '2022 CNMP K2',
    'Account Name': '6A Farms',
    Field: 'K2',
    'Program ID': 'DV4817',
  },
  {
    'Opportunity ID': '0061K00000jlz9i',
    'Opportunity Name': '2022 CNMP Tom Schlabs SE 1/2',
    'Account Name': 'Tom Schlabs',
    Field: 'SE 1/2',
    'Program ID': 'DV4984',
  },
  {
    'Opportunity ID': '0061K00000jlz9j',
    'Opportunity Name': '2022 CNMP Tom Schlabs R W Pivot W1/2',
    'Account Name': 'Tom Schlabs',
    Field: 'R W Pivot W1/2',
    'Program ID': 'DV4986',
  },
  {
    'Opportunity ID': '0061K00000jlkKS',
    'Opportunity Name': "2022 CNMP Donnie's N1/3",
    'Account Name': 'J & M Meyer Farms',
    Field: "Donnie's N1/3",
    'Program ID': 'DV4811',
  },
  {
    'Opportunity ID': '0061K00000jlkSq',
    'Opportunity Name': '2022 CNMP SE 10-30-30',
    'Account Name': 'Ron Gibson',
    Field: 'SE 10-30-30',
    'Program ID': 'DV4849',
  },
  {
    'Opportunity ID': '0061K00000jlkSr',
    'Opportunity Name': '2022 CNMP N 1/2 3-29-30',
    'Account Name': 'Ron Gibson',
    Field: 'N 1/2 3-29-30',
    'Program ID': 'DV4852',
  },
  {
    'Opportunity ID': '0061K00000jlkLQ',
    'Opportunity Name': '2022 CNMP NW4-29-30',
    'Account Name': 'Levi Hedlund',
    Field: 'NW 4-29-30',
    'Program ID': 'DV4815',
  },
  {
    'Opportunity ID': '0061K00000jlkKr',
    'Opportunity Name': '2022 CNMP Nafzger Wcir',
    'Account Name': 'J & M Meyer Farms',
    Field: 'Nafzger Wcir',
    'Program ID': 'DV4814',
  },
  {
    'Opportunity ID': '0061K00000jlkKs',
    'Opportunity Name': '2022 CNMP 343 SW',
    'Account Name': 'Stan Spain',
    Field: '343 SW',
    'Program ID': 'DV4825',
  },
  {
    'Opportunity ID': '0061K00000jlkRY',
    'Opportunity Name': '2022 CNMP 8',
    'Account Name': '97 Farms',
    Field: '8',
    'Program ID': 'DV4843',
  },
  {
    'Opportunity ID': '0061K00000jlkRZ',
    'Opportunity Name': '2022 CNMP 13 NE',
    'Account Name': '97 Farms',
    Field: '13 NE',
    'Program ID': 'DV4845',
  },
  {
    'Opportunity ID': '0061K00000jlkRa',
    'Opportunity Name': '2022 CNMP 13 S',
    'Account Name': '97 Farms',
    Field: '13 S',
    'Program ID': 'DV4846',
  },
  {
    'Opportunity ID': '0061K00000jlkOA',
    'Opportunity Name': '2022 CNMP 7',
    'Account Name': 'R-Way Farms',
    Field: '7',
    'Program ID': 'DV4833',
  },
  {
    'Opportunity ID': '0061K00000jlkcC',
    'Opportunity Name': '2022 CNMP Langehoff Early',
    'Account Name': 'Dan Kreinke',
    Field: 'Langehoff Early',
    'Program ID': 'DV4875',
  },
  {
    'Opportunity ID': '0061K00000jm3qO',
    'Opportunity Name': '2022 CNMP 352 West W1/2 treated',
    'Account Name': 'Hasta Farms',
    Field: '352 West W1/2 treated',
    'Program ID': 'DV5030',
  },
  {
    'Opportunity ID': '0061K00000jlkW9',
    'Opportunity Name': '2022 CNMP Sec 79 W',
    'Account Name': 'Meyer Family Farms',
    Field: 'Sec 79 W',
    'Program ID': 'DV4854',
  },
  {
    'Opportunity ID': '0061K00000jlkWA',
    'Opportunity Name': '2022 CNMP Sec 80 E & 81 W',
    'Account Name': 'Meyer Family Farms',
    Field: 'Sec 80 E & 81 W',
    'Program ID': 'DV4855',
  },
  {
    'Opportunity ID': '0061K00000jlkcW',
    'Opportunity Name': '2022 CNMP Sec 6',
    'Account Name': 'Dan Kreinke',
    Field: 'Sec 6',
    'Program ID': 'DV4877',
  },
  {
    'Opportunity ID': '0061K00000jlkWY',
    'Opportunity Name': '2022 CNMP Sec 47 WSW',
    'Account Name': 'Meyer Family Farms',
    Field: 'Sec 47 WSW',
    'Program ID': 'DV4856',
  },
  {
    'Opportunity ID': '0061K00000jlkQz',
    'Opportunity Name': '2022 CNMP 2',
    'Account Name': '97 Farms',
    Field: '2',
    'Program ID': 'DV4840',
  },
  {
    'Opportunity ID': '0061K00000jlkRE',
    'Opportunity Name': '2022 CNMP 3',
    'Account Name': '97 Farms',
    Field: '3',
    'Program ID': 'DV4841',
  },
  {
    'Opportunity ID': '0061K00000jlkKE',
    'Opportunity Name': '2022 CNMP HF SE cir',
    'Account Name': 'J & M Meyer Farms',
    Field: 'HF SE cir',
    'Program ID': 'DV4810',
  },
  {
    'Opportunity ID': '0061K00000jlkKF',
    'Opportunity Name': '2022 CNMP D3 sw',
    'Account Name': '6A Farms',
    Field: 'D3 sw',
    'Program ID': 'DV4821',
  },
  {
    'Opportunity ID': '0061K00000jlkNR',
    'Opportunity Name': '2022 CNMP WC S Drip',
    'Account Name': 'Stan Spain',
    Field: 'WC S Drip',
    'Program ID': 'DV4826',
  },
  {
    'Opportunity ID': '0061K00000jlkNS',
    'Opportunity Name': '2022 CNMP WC W 1/4',
    'Account Name': 'Stan Spain',
    Field: 'WC W 1/4',
    'Program ID': 'DV4827',
  },
  {
    'Opportunity ID': '0061K00000jlkNT',
    'Opportunity Name': '2022 CNMP 13',
    'Account Name': 'R-Way Farms',
    Field: '13',
    'Program ID': 'DV4835',
  },
  {
    'Opportunity ID': '0061K00000jlkX3',
    'Opportunity Name': '2022 CNMP #1',
    'Account Name': 'Rod Bohlender',
    Field: '#1',
    'Program ID': 'DV4871',
  },
  {
    'Opportunity ID': '0061K00000jlkPr',
    'Opportunity Name': '2022 CNMP Lemons W',
    'Account Name': 'Gary Schlabs',
    Field: 'Lemons W',
    'Program ID': 'DV4837',
  },
  {
    'Opportunity ID': '0061K00000jlkRO',
    'Opportunity Name': '2022 CNMP 7',
    'Account Name': '97 Farms',
    Field: '7',
    'Program ID': 'DV4842',
  },
  {
    'Opportunity ID': '0061K00000jlkW4',
    'Opportunity Name': '2022 CNMP Sec 78 W',
    'Account Name': 'Meyer Family Farms',
    Field: 'Sec 78 W',
    'Program ID': 'DV4853',
  },
  {
    'Opportunity ID': '0061K00000jlkMn',
    'Opportunity Name': '2022 CNMP VV1',
    'Account Name': 'Valley View Partners',
    Field: 'VV1',
    'Program ID': 'DV4822',
  },
  {
    'Opportunity ID': '0061K00000jlkKX',
    'Opportunity Name': '2022 CNMP FC Ecir',
    'Account Name': 'J & M Meyer Farms',
    Field: 'FC Ecir',
    'Program ID': 'DV4812',
  },
  {
    'Opportunity ID': '0061K00000jlkKY',
    'Opportunity Name': '2022 CNMP S1 east',
    'Account Name': '6A Farms',
    Field: 'S1 east',
    'Program ID': 'DV4818',
  },
  {
    'Opportunity ID': '0061K00000jlkTA',
    'Opportunity Name': '2022 CNMP SW 11-30-30 W1/2',
    'Account Name': 'Ron Gibson',
    Field: 'SW 11-30-30 W1/2',
    'Program ID': 'DV4850',
  },
  {
    'Opportunity ID': '0061K00000jlkTK',
    'Opportunity Name': '2022 CNMP SW 4-29-30',
    'Account Name': 'Ron Gibson',
    Field: 'SW 4-29-30',
    'Program ID': 'DV4851',
  },
  {
    'Opportunity ID': '0061K00000jlkcH',
    'Opportunity Name': '2022 CNMP Langhoff Late',
    'Account Name': 'Dan Kreinke',
    Field: 'Langhoff Late',
    'Program ID': 'DV4876',
  },
  {
    'Opportunity ID': '0061K00000jm5Qd',
    'Opportunity Name': '2022 CNMP J&S 98 NE',
    'Account Name': 'J & S Joint Ventures',
    Field: '98 NE',
    'Program ID': 'DV5049',
  },
  {
    'Opportunity ID': '0061K00000jm5Qe',
    'Opportunity Name': '2022 CNMP J&S 108 NW',
    'Account Name': 'J & S Joint Ventures',
    Field: '108 NW',
    'Program ID': 'DV5052',
  },
  {
    'Opportunity ID': '0061K00000jm1tH',
    'Opportunity Name': '2022 CNMP Reznik 347 SW2/3',
    'Account Name': 'John Reznik',
    Field: '347 SW2/3',
    'Program ID': 'DV5001',
  },
  {
    'Opportunity ID': '0061K00000jm5RM',
    'Opportunity Name': '2022 CNMP J&S 134 NW',
    'Account Name': 'J & S Joint Ventures',
    Field: '134 NW',
    'Program ID': 'DV5053',
  },
  {
    'Opportunity ID': '0061K00000jm5Q4',
    'Opportunity Name': '2022 CNMP J&S 72 NE',
    'Account Name': 'J & S Joint Ventures',
    Field: '72 NE',
    'Program ID': 'DV5047',
  },
  {
    'Opportunity ID': '0061K00000jlkXW',
    'Opportunity Name': '2022 CNMP SE/3 Sec 250 (Oquin)',
    'Account Name': 'McBryde L & C',
    Field: 'SE/3 Sec 250 (Oquin)',
    'Program ID': 'DV4860',
  },
  {
    'Opportunity ID': '0061K00000jlkXX',
    'Opportunity Name': '2022 CNMP W/2 Sec 79',
    'Account Name': 'McBryde L & C',
    Field: 'W/2 Sec 79',
    'Program ID': 'DV4861',
  },
  {
    'Opportunity ID': '0061K00000jlkXY',
    'Opportunity Name': '2022 CNMP NE/Sec 172',
    'Account Name': 'McBryde L & C',
    Field: 'NE/ Sec 172',
    'Program ID': 'DV4863',
  },
  {
    'Opportunity ID': '0061K00000jlkXZ',
    'Opportunity Name': '2022 CNMP NE/ Sec 215 (Red Man)',
    'Account Name': 'McBryde L & C',
    Field: 'NE/ Sec 215 (Red Man)',
    'Program ID': 'DV4864',
  },
  {
    'Opportunity ID': '0061K00000jlkY0',
    'Opportunity Name': '2022 CNMP NE/Sec 240 (Haile)',
    'Account Name': 'McBryde L & C',
    Field: 'NE/Sec 240 (Haile)',
    'Program ID': 'DV4862',
  },
  {
    'Opportunity ID': '0061K00000jlkNg',
    'Opportunity Name': '2022 CNMP 327 NW',
    'Account Name': 'Stan Spain',
    Field: '327 NW',
    'Program ID': 'DV4828',
  },
  {
    'Opportunity ID': '0061K00000jlkNh',
    'Opportunity Name': '2022 CNMP E1 W1/2',
    'Account Name': 'R-Way Farms',
    Field: 'E1 W1/2',
    'Program ID': 'DV4829',
  },
  {
    'Opportunity ID': '0061K00000jlkNi',
    'Opportunity Name': '2022 CNMP S1',
    'Account Name': 'R-Way Farms',
    Field: 'S1',
    'Program ID': 'DV4830',
  },
  {
    'Opportunity ID': '0061K00000jlkLu',
    'Opportunity Name': '2022 CNMP S2',
    'Account Name': '6A Farms',
    Field: 'S2',
    'Program ID': 'DV4819',
  },
  {
    'Opportunity ID': '0061K00000jlkLv',
    'Opportunity Name': '2022 CNMP Sec 343 SE 1/3',
    'Account Name': 'T & J Farms',
    Field: 'Sec 343 SE 1/3',
    'Program ID': 'DV4824',
  },
  {
    'Opportunity ID': '0061K00000jlkLw',
    'Opportunity Name': '2022 CNMP 3',
    'Account Name': 'R-Way Farms',
    Field: '3',
    'Program ID': 'DV4836',
  },
  {
    'Opportunity ID': '0061K00000jlkOK',
    'Opportunity Name': '2022 CNMP 5',
    'Account Name': 'R-Way Farms',
    Field: '5',
    'Program ID': 'DV4834',
  },
  {
    'Opportunity ID': '0061K00000jm5Pf',
    'Opportunity Name': '2022 CNMP J&S 67 SE',
    'Account Name': 'J & S Joint Ventures',
    Field: '67 SE',
    'Program ID': 'DV5046',
  },
  {
    'Opportunity ID': '0061K00000jm5RW',
    'Opportunity Name': '2022 CNMP J&S 136 NE',
    'Account Name': 'J & S Joint Ventures',
    Field: '136 NE',
    'Program ID': 'DV5054',
  },
  {
    'Opportunity ID': '0061K00000jm5RX',
    'Opportunity Name': '2022 CNMP J&S 177 SW',
    'Account Name': 'J & S Joint Ventures',
    Field: '177 SW',
    'Program ID': 'DV5060',
  },
  {
    'Opportunity ID': '0061K00000jm5RY',
    'Opportunity Name': '2022 CNMP J&S 184 SW',
    'Account Name': 'J & S Joint Ventures',
    Field: '184 SW',
    'Program ID': 'DV5061',
  },
  {
    'Opportunity ID': '0061K00000jm1u9',
    'Opportunity Name': '2022 CNMP Reznik 363 N1/2',
    'Account Name': 'John Reznik',
    Field: '363 N1/2',
    'Program ID': 'DV5003',
  },
  {
    'Opportunity ID': '0061K00000jlz9n',
    'Opportunity Name': '2022 CNMP Tom Schlabs M N Pivot S1/2',
    'Account Name': 'Tom Schlabs',
    Field: 'M S Pivot S1/2',
    'Program ID': 'DV4985',
  },
  {
    'Opportunity ID': '0061K00000jlz9o',
    'Opportunity Name': '2022 CNMP Travis Fraiser SW 33-32-32',
    'Account Name': 'Travis Fieser',
    Field: 'SW 33-32-32',
    'Program ID': 'DV4990',
  },
  {
    'Opportunity ID': '0061K00000jlz9p',
    'Opportunity Name': '2022 CNMP Travis Fraiser SE 8-32-31',
    'Account Name': 'Travis Fieser',
    Field: 'SE 8-32-31',
    'Program ID': 'DV4991',
  },
  {
    'Opportunity ID': '0061K00000jlkSS',
    'Opportunity Name': '2022 CNMP NE 16-30-30',
    'Account Name': 'Ron Gibson',
    Field: 'NE 16-30-30',
    'Program ID': 'DV4848',
  },
  {
    'Opportunity ID': '0061K00000jlkQ6',
    'Opportunity Name': '2022 CNMP Mcwhorter W',
    'Account Name': 'Gary Schlabs',
    Field: 'Mcworter W',
    'Program ID': 'DV4838',
  },
  {
    'Opportunity ID': '0061K00000jlkRd',
    'Opportunity Name': '2022 CNMP 10',
    'Account Name': '97 Farms',
    Field: '10',
    'Program ID': 'DV4844',
  },
  {
    'Opportunity ID': '0061K00000jlkRe',
    'Opportunity Name': '2022 CNMP SW 10-30-30 N1/2',
    'Account Name': 'Ron Gibson',
    Field: 'SW 10-30-30 N1/2',
    'Program ID': 'DV4847',
  },
  {
    'Opportunity ID': '0061K00000jlkRg',
    'Opportunity Name': '2022 CNMP NW/3 Sec 266',
    'Account Name': 'McBryde L & C',
    Field: 'NW/3 Sec 266',
    'Program ID': 'DV4859',
  },
  {
    'Opportunity ID': '0061K00000jlkNv',
    'Opportunity Name': '2022 CNMP A1 N1/2',
    'Account Name': 'R-Way Farms',
    Field: 'A1 N1/2',
    'Program ID': 'DV4831',
  },
  {
    'Opportunity ID': '0061K00000jlxMj',
    'Opportunity Name': '2022 CNMP Hasta 302 South',
    'Account Name': 'Hasta Farms',
    Field: '302 South',
    'Program ID': 'DV4910',
  },
  {
    'Opportunity ID': '0061K00000jlxhi',
    'Opportunity Name': '2022 CNMP Spurlock Sec 25 NE',
    'Account Name': 'Spurlock Farms',
    Field: 'Sec 25 NE',
    'Program ID': 'DV4925',
  },
  {
    'Opportunity ID': '0061K00000jlxTv',
    'Opportunity Name': '2022 CNMP Hasta 414 SP',
    'Account Name': 'Hasta Farms',
    Field: '414 SP',
    'Program ID': 'DV4913',
  },
  {
    'Opportunity ID': '0061K00000jlxTw',
    'Opportunity Name': '2022 CNMP Hasta YMCA 2',
    'Account Name': 'Hasta Farms',
    Field: 'YMCA 2',
    'Program ID': 'DV4914',
  },
  {
    'Opportunity ID': '0061K00000jlxVc',
    'Opportunity Name': '2022 CNMP Spurlock ES 2',
    'Account Name': 'Spurlock Farms',
    Field: 'ES 2',
    'Program ID': 'DV4917',
  },
  {
    'Opportunity ID': '0061K00000jlwyD',
    'Opportunity Name': '2022 CNMP Hast 350-sp North',
    'Account Name': 'Hasta Farms',
    Field: '350-sp North',
    'Program ID': 'DV4902',
  },
  {
    'Opportunity ID': '0061K00000jlwyE',
    'Opportunity Name': '2022 CNMP Hasta 350-sp South',
    'Account Name': 'Hasta Farms',
    Field: '350-sp South',
    'Program ID': 'DV4903',
  },
  {
    'Opportunity ID': '0061K00000jlwxe',
    'Opportunity Name': '2022 CNMP 366 sp',
    'Account Name': 'Hasta Farms',
    Field: '366 sp',
    'Program ID': 'DV4900',
  },
  {
    'Opportunity ID': '0061K00000jlxfD',
    'Opportunity Name': '2022 CNMP Spurlock ES 15',
    'Account Name': 'Spurlock Farms',
    Field: 'ES 15',
    'Program ID': 'DV4918',
  },
  {
    'Opportunity ID': '0061K00000jlwzQ',
    'Opportunity Name': '2022 CNMP Hasta 394-SP',
    'Account Name': 'Hasta Farms',
    Field: '394-SP',
    'Program ID': 'DV4906',
  },
  {
    'Opportunity ID': '0061K00000jlxkr',
    'Opportunity Name': '2022 CNMP Spurlock Sec 27 N 1/3',
    'Account Name': 'Spurlock Farms',
    Field: 'Sec 27 N 1/3',
    'Program ID': 'DV4927',
  },
  {
    'Opportunity ID': '0061K00000jlxlV',
    'Opportunity Name': '2022 CNMP Spurlock Sec 28 minus NW 1/4',
    'Account Name': 'Spurlock Farms',
    Field: 'Sec 28 minus NW 1/4',
    'Program ID': 'DV4928',
  },
  {
    'Opportunity ID': '0061K00000jlxlu',
    'Opportunity Name': '2022 CNMP Spurlock Sec 31',
    'Account Name': 'Spurlock Farms',
    Field: 'Sec 31',
    'Program ID': 'DV4929',
  },
  {
    'Opportunity ID': '0061K00000jlxlW',
    'Opportunity Name': '2022 CNMP Spurlock Sec 35 Sunray Oil',
    'Account Name': 'Spurlock Farms',
    Field: 'Sec 35 Sunray Oil',
    'Program ID': 'DV4930',
  },
  {
    'Opportunity ID': '0061K00000jlwxK',
    'Opportunity Name': '2022 CNMP Hasta 409-3',
    'Account Name': 'Hasta Farms',
    Field: '409-3',
    'Program ID': 'DV4898',
  },
  {
    'Opportunity ID': '0061K00000jlwzR',
    'Opportunity Name': '2022 CNMP Hasta 364 SP',
    'Account Name': 'Hasta Farms',
    Field: '364 SP',
    'Program ID': 'DV4907',
  },
  {
    'Opportunity ID': '0061K00000jlwzS',
    'Opportunity Name': '2022 CNMP Hasta 345 SP East',
    'Account Name': 'Hasta Farms',
    Field: '345 SP East',
    'Program ID': 'DV4908',
  },
  {
    'Opportunity ID': '0061K00000jlwzT',
    'Opportunity Name': '2022 CNMP Hasta 328 E S 1/2 SP',
    'Account Name': 'Hasta Farms',
    Field: '328 E S 1/2 SP',
    'Program ID': 'DV4909',
  },
  {
    'Opportunity ID': '0061K00000jlxTW',
    'Opportunity Name': '2022 CNMP Hasta 371 E 1/2 SP',
    'Account Name': 'Hasta Farms',
    Field: '371 E 1/2 SP',
    'Program ID': 'DV4912',
  },
  {
    'Opportunity ID': '0061K00000jlxTX',
    'Opportunity Name': '2022 CNMP Spurlock Sec 12 1/2 mile East',
    'Account Name': 'Spurlock Farms',
    Field: 'Sec 12 1/2 mile East',
    'Program ID': 'DV4919',
  },
  {
    'Opportunity ID': '0061K00000jlxgV',
    'Opportunity Name': '2022 CNMP Spurlock Sec 12 1/2 mile West',
    'Account Name': 'Spurlock Farms',
    Field: 'Sec 12 1/2 mile West',
    'Program ID': 'DV4920',
  },
  {
    'Opportunity ID': '0061K00000jlxTY',
    'Opportunity Name': '2022 CNMP Spurlock Sec 15',
    'Account Name': 'Spurlock Farms',
    Field: 'Sec 15',
    'Program ID': 'DV4921',
  },
  {
    'Opportunity ID': '0061K00000jlwzU',
    'Opportunity Name': '2022 CNMP Spurlock Sec 24',
    'Account Name': 'Spurlock Farms',
    Field: 'Sec 24',
    'Program ID': 'DV4923',
  },
  {
    'Opportunity ID': '0061K00000jlxhx',
    'Opportunity Name': '2022 CNMP Spurlock Sec 25 NW',
    'Account Name': 'Spurlock Farms',
    Field: 'Sec 25 NW',
    'Program ID': 'DV4926',
  },
  {
    'Opportunity ID': '0061K00000jlxks',
    'Opportunity Name': '2022 CNMP Spurlock Sec 35 NW',
    'Account Name': 'Spurlock Farms',
    Field: 'Sec 35 NW',
    'Program ID': 'DV4931',
  },
  {
    'Opportunity ID': '0061K00000jm5Qs',
    'Opportunity Name': '2022 CNMP J&S 99 NW',
    'Account Name': 'J & S Joint Ventures',
    Field: '99 NW',
    'Program ID': 'DV5050',
  },
  {
    'Opportunity ID': '0061K00000jm5Qt',
    'Opportunity Name': '2022 CNMP J&S 142 SW',
    'Account Name': 'J & S Joint Ventures',
    Field: '142 SW',
    'Program ID': 'DV5056',
  },
  {
    'Opportunity ID': '0061K00000jm5Qu',
    'Opportunity Name': '2022 CNMP J&S 143 SW',
    'Account Name': 'J & S Joint Ventures',
    Field: '143 SW',
    'Program ID': 'DV5057',
  },
  {
    'Opportunity ID': '0061K00000jm5Qv',
    'Opportunity Name': '2022 CNMP J&S 168 SE',
    'Account Name': 'J & S Joint Ventures',
    Field: '168 SE',
    'Program ID': 'DV5059',
  },
  {
    'Opportunity ID': '0061K00000jm5Qw',
    'Opportunity Name': '2022 CNMP J&S 279 E',
    'Account Name': 'J & S Joint Ventures',
    Field: '279 E',
    'Program ID': 'DV5064',
  },
  {
    'Opportunity ID': '0061K00000jm5UQ',
    'Opportunity Name': '2022 CNMP J&S 308 W',
    'Account Name': 'J & S Joint Ventures',
    Field: '308 W',
    'Program ID': 'DV5065',
  },
  {
    'Opportunity ID': '0061K00000jlwyw',
    'Opportunity Name': '2022 CNMP Hasta 393 S-SP',
    'Account Name': 'Hasta Farms',
    Field: '393 S-SP',
    'Program ID': 'DV4904',
  },
  {
    'Opportunity ID': '0061K00000jlxUK',
    'Opportunity Name': '2022 CNMP Hasta YMCA 3',
    'Account Name': 'Hasta Farms',
    Field: 'YMCA 3',
    'Program ID': 'DV4915',
  },
  {
    'Opportunity ID': '0061K00000jlwyx',
    'Opportunity Name': '2022 CNMP Hasta 393 E-SP',
    'Account Name': 'Hasta Farms',
    Field: '393 E-SP',
    'Program ID': 'DV4905',
  },
  {
    'Opportunity ID': '0061K00000jlxTM',
    'Opportunity Name': '2022 CNMP Hasta 344 SP',
    'Account Name': 'Hasta Farms',
    Field: '344 SP',
    'Program ID': 'DV4911',
  },
  {
    'Opportunity ID': '0061K00000jly7q',
    'Opportunity Name': '2022 CNMP Caraway 408 SE 2/3',
    'Account Name': 'Caraway Farms',
    Field: '408 SE 2/3',
    'Program ID': 'DV4959',
  },
  {
    'Opportunity ID': '0061K00000jlyC7',
    'Opportunity Name': '2022 CNMP Paschel Home E 1/2',
    'Account Name': 'Ralph Paschel',
    Field: 'Home E 1/2',
    'Program ID': 'DV4972',
  },
  {
    'Opportunity ID': '0061K00000jlyE3',
    'Opportunity Name': '2022 CNMP Silver Sky Section 438-East 1/2 Circle',
    'Account Name': 'Silver Sky Farms LLC',
    Field: 'Section 438-East 1/2 Circle',
    'Program ID': 'DV4978',
  },
  {
    'Opportunity ID': '0061K00000jlyE4',
    'Opportunity Name': '2022 CNMP Silver Sky Section 2-North 1/3 Circle',
    'Account Name': 'Silver Sky Farms LLC',
    Field: 'Section 2-North 1/3 Circle',
    'Program ID': 'DV4981',
  },
  {
    'Opportunity ID': '0061K00000jlxws',
    'Opportunity Name': '2022 CNMP Spurlock Sec 36 S 1/3',
    'Account Name': 'Spurlock Farms',
    Field: 'Sec 36 S 1/3',
    'Program ID': 'DV4937',
  },
  {
    'Opportunity ID': '0061K00000jlxye',
    'Opportunity Name': '2022 CNMP Spurlock Sec 59 NE 1/4 (WBU 26)',
    'Account Name': 'Spurlock Farms',
    Field: 'Sec 59 NE 1/4 (WBU 26)',
    'Program ID': 'DV4943',
  },
  {
    'Opportunity ID': '0061K00000jlxxM',
    'Opportunity Name': '2022 CNMP Spurlock Sec 45',
    'Account Name': 'Spurlock Farms',
    Field: 'Sec 45',
    'Program ID': 'DV4939',
  },
  {
    'Opportunity ID': '0061K00000jly7g',
    'Opportunity Name': '2022 CNMP Caraway 407 NE 2/3',
    'Account Name': 'Caraway Farms',
    Field: '407 NE 2/3',
    'Program ID': 'DV4958',
  },
  {
    'Opportunity ID': '0061K00000jly7h',
    'Opportunity Name': '2022 CNMP Preston Section 39 - West 1/2 circle',
    'Account Name': 'Chandler Preston',
    Field: 'Section 39 - West 1/2 circle',
    'Program ID': 'DV4961',
  },
  {
    'Opportunity ID': '0061K00000jly7i',
    'Opportunity Name': '2022 CNMP Preston Section 92',
    'Account Name': 'Chandler Preston',
    Field: 'Section 92',
    'Program ID': 'DV4967',
  },
  {
    'Opportunity ID': '0061K00000jlxzr',
    'Opportunity Name': '2022 CNMP Spurlock Sec 310 W 1/2',
    'Account Name': 'Spurlock Farms',
    Field: 'Sec 310 W 1/2',
    'Program ID': 'DV4946',
  },
  {
    'Opportunity ID': '0061K00000jlxyg',
    'Opportunity Name': '2022 CNMP Spurlock Sec 310 E 1/2',
    'Account Name': 'Spurlock Farms',
    Field: 'Sec 310 E 1/2',
    'Program ID': 'DV4947',
  },
  {
    'Opportunity ID': '0061K00000jlxUe',
    'Opportunity Name': '2022 CNMP Hasta 352 West E1/2',
    'Account Name': 'Hasta Farms',
    Field: '352 West E 1/2',
    'Program ID': 'DV4916',
  },
  {
    'Opportunity ID': '0061K00000jlxvu',
    'Opportunity Name': '2022 CNMP Spurlock Sec 35 NE',
    'Account Name': 'Spurlock Farms',
    Field: 'Sec 35 NE',
    'Program ID': 'DV4935',
  },
  {
    'Opportunity ID': '0061K00000jlxh4',
    'Opportunity Name': '2022 CNMP Spurlock Sec 22',
    'Account Name': 'Spurlock Farms',
    Field: 'Sec 22',
    'Program ID': 'DV4922',
  },
  {
    'Opportunity ID': '0061K00000jly5a',
    'Opportunity Name': '2022 CNMP Taylor Clark B5 SW 27-1-11',
    'Account Name': 'Taylor Clark',
    Field: 'B5 SW 27-1-11',
    'Program ID': 'DV4952',
  },
  {
    'Opportunity ID': '0061K00000jlxvv',
    'Opportunity Name': '2022 CNMP Spurlock Sec 48 NE 1/3',
    'Account Name': 'Spurlock Farms',
    Field: 'Sec 48 NE 1/3',
    'Program ID': 'DV4940',
  },
  {
    'Opportunity ID': '0061K00000jlxxv',
    'Opportunity Name': '2022 CNMP Spurlock Sec 58 (WBU 23)',
    'Account Name': 'Spurlock Farms',
    Field: 'Sec 58 (WBU 23)',
    'Program ID': 'DV4941',
  },
  {
    'Opportunity ID': '0061K00000jlxxw',
    'Opportunity Name': '2022 CNMP Spurlock Sec 234 N 1/2',
    'Account Name': 'Spurlock Farms',
    Field: 'Sec 234 N 1/2',
    'Program ID': 'DV4944',
  },
  {
    'Opportunity ID': '0061K00000jlxxx',
    'Opportunity Name': '2022 CNMP Spurlock Sec 309 N 1/2',
    'Account Name': 'Spurlock Farms',
    Field: 'Sec 309 N 1/2',
    'Program ID': 'DV4945',
  },
  {
    'Opportunity ID': '0061K00000jly5B',
    'Opportunity Name': '2022 CNMP B2 NE 33-1-11',
    'Account Name': 'Taylor Clark',
    Field: 'B2 NE 33-1-11',
    'Program ID': 'DV4949',
  },
  {
    'Opportunity ID': '0061K00000jly5L',
    'Opportunity Name': '2022 CNMP Taylor Clark B3 NE 33-1-11',
    'Account Name': 'Taylor Clark',
    Field: 'B3 NE 33-1-11',
    'Program ID': 'DV4950',
  },
  {
    'Opportunity ID': '0061K00000jly8y',
    'Opportunity Name': '2022 CNMP Preston Section 6 - West 1/2 Circle',
    'Account Name': 'Chandler Preston',
    Field: 'Section 6 - West 1/2 Circle',
    'Program ID': 'DV4963',
  },
  {
    'Opportunity ID': '0061K00000jly9m',
    'Opportunity Name': '2022 CNMP Preston Section 141-North 1/2 Circle',
    'Account Name': 'Chandler Preston',
    Field: 'Section 141-North 1/2 Circle',
    'Program ID': 'DV4966',
  },
  {
    'Opportunity ID': '0061K00000jlyAV',
    'Opportunity Name': '2022 CNMP Preston Section 451 SW Circle',
    'Account Name': 'Chandler Preston',
    Field: 'Section 451 SW Circle',
    'Program ID': 'DV4969',
  },
  {
    'Opportunity ID': '0061K00000jlyAk',
    'Opportunity Name': '2022 CNMP Preston Section 55',
    'Account Name': 'Chandler Preston',
    Field: 'Section 55',
    'Program ID': 'DV4970',
  },
  {
    'Opportunity ID': '0061K00000jly5M',
    'Opportunity Name': '2022 CNMP Taylor Clark B4 NE 28-1-11',
    'Account Name': 'Taylor Clark',
    Field: 'B4 NE 28-1-11',
    'Program ID': 'DV4951',
  },
  {
    'Opportunity ID': '0061K00000jly5C',
    'Opportunity Name': '2022 CNMP Taylor Clark B6 SW 27-1-11',
    'Account Name': 'Taylor Clark',
    Field: 'B6 SW 27-1-11',
    'Program ID': 'DV4953',
  },
  {
    'Opportunity ID': '0061K00000jly5D',
    'Opportunity Name': '2022 CNMP Taylor Clark BarN NE 16-1-10',
    'Account Name': 'Taylor Clark',
    Field: 'BarN NE 16-1-10',
    'Program ID': 'DV4954',
  },
  {
    'Opportunity ID': '0061K00000jly6J',
    'Opportunity Name': '2022 CNMP Taylor Clark BarS SE 16-1-10',
    'Account Name': 'Taylor Clark',
    Field: 'BarS SE 16-1-10',
    'Program ID': 'DV4955',
  },
  {
    'Opportunity ID': '0061K00000jlyCq',
    'Opportunity Name': "2022 CNMP Paschel MC N 1/2's",
    'Account Name': 'Ralph Paschel',
    Field: "MC N 1/2's",
    'Program ID': 'DV4974',
  },
  {
    'Opportunity ID': '0061K00000jlyD5',
    'Opportunity Name': '2022 CNMP Paschel SE Ford E1/2:NW Ford N1/2',
    'Account Name': 'Ralph Paschel',
    Field: 'SE Ford E1/2:NW Ford N1/2',
    'Program ID': 'DV4975',
  },
  {
    'Opportunity ID': '0061K00000jlyDK',
    'Opportunity Name': '2022 CNMP Ray Schlabs EW',
    'Account Name': 'Ray Schlabs',
    Field: 'EW',
    'Program ID': 'DV4976',
  },
  {
    'Opportunity ID': '0061K00000jly8o',
    'Opportunity Name': '2022 CNMP Preston Section 9 - NW Circle',
    'Account Name': 'Chandler Preston',
    Field: 'Section 9 - NW Circle',
    'Program ID': 'DV4962',
  },
  {
    'Opportunity ID': '0061K00000jlyB9',
    'Opportunity Name': '2022 CNMP Gruhlkey Sec 2 NE',
    'Account Name': 'Gruhlkey',
    Field: 'Sec 2 NE',
    'Program ID': 'DV4971',
  },
  {
    'Opportunity ID': '0061K00000jly7W',
    'Opportunity Name': '2022 CNMP Caraway 406 S cir N 1/2',
    'Account Name': 'Caraway Farms',
    Field: '406 S cir N 1/2',
    'Program ID': 'DV4957',
  },
  {
    'Opportunity ID': '0061K00000jly7X',
    'Opportunity Name': '2022 CNMP Preston Section 8-West 1/2 Circle',
    'Account Name': 'Chandler Preston',
    Field: 'Section 8-West 1/2 Circle',
    'Program ID': 'DV4960',
  },
  {
    'Opportunity ID': '0061K00000jlyAG',
    'Opportunity Name': '2022 CNMP Preston Section 451-NE Circle',
    'Account Name': 'Chandler Preston',
    Field: 'Section 451-NE Circle',
    'Program ID': 'DV4968',
  },
  {
    'Opportunity ID': '0061K00000jlyDU',
    'Opportunity Name': '2022 CNMP Ray Schlabs H',
    'Account Name': 'Ray Schlabs',
    Field: 'H',
    'Program ID': 'DV4977',
  },
  {
    'Opportunity ID': '0061K00000jlyD6',
    'Opportunity Name': '2022 CNMP Silver Sky Section 436-North 1/2 Circle',
    'Account Name': 'Silver Sky Farms LLC',
    Field: 'Section 436-North 1/2 Circle',
    'Program ID': 'DV4980',
  },
  {
    'Opportunity ID': '0061K00000jlyDV',
    'Opportunity Name': '2022 CNMP Silver Sky Section 97 - West Cirlce',
    'Account Name': 'Silver Sky Farms LLC',
    Field: 'Section 97 - West Cirlce',
    'Program ID': 'DV4982',
  },
  {
    'Opportunity ID': '0061K00000jlyDW',
    'Opportunity Name': '2022 CNMP Silver Sky Section 15-East 1/2 Circle',
    'Account Name': 'Silver Sky Farms LLC',
    Field: 'Section 15-East 1/2 Circle',
    'Program ID': 'DV4983',
  },
  {
    'Opportunity ID': '0061K00000jly9S',
    'Opportunity Name': '2022 CNMP Preston Section 10 - SE Circle',
    'Account Name': 'Chandler Preston',
    Field: 'Section 10 - SE Circle',
    'Program ID': 'DV4964',
  },
  {
    'Opportunity ID': '0061K00000jly9c',
    'Opportunity Name': '2022 CNMP Preston Section 11-North 1/2 Circle',
    'Account Name': 'Chandler Preston',
    Field: 'Section 11-North 1/2 Circle',
    'Program ID': 'DV4965',
  },
  {
    'Opportunity ID': '0061K00000jlxy5',
    'Opportunity Name': '2022 CNMP Sec 58 (WBU 24)',
    'Account Name': 'Spurlock Farms',
    Field: 'Sec 58 (WBU 24)',
    'Program ID': 'DV4942',
  },
  {
    'Opportunity ID': '0061K00000jly51',
    'Opportunity Name': '2022 CNMP Taylor Clark B1 NW 33-1-11',
    'Account Name': 'Taylor Clark',
    Field: 'B1 NW 33-1-11',
    'Program ID': 'DV4948',
  },
  {
    'Opportunity ID': '0061K00000jlyCR',
    'Opportunity Name': '2022 CNMP Paschel Dales South',
    'Account Name': 'Ralph Paschel',
    Field: 'Dales South',
    'Program ID': 'DV4973',
  },
  {
    'Opportunity ID': '0061K00000jlyCS',
    'Opportunity Name': '2022 CNMP Silver Sky Section 40-North 1/2 Circle',
    'Account Name': 'Silver Sky Farms LLC',
    Field: 'Section 40-North 1/2 Circle',
    'Program ID': 'DV4979',
  },
  {
    'Opportunity ID': '0061K00000jly5b',
    'Opportunity Name': '2022 CNMP Gibson SW 11-30-30 E1/2',
    'Account Name': 'Ron Gibson',
    Field: 'SW 11-30-30 E1/2 treated',
    'Program ID': 'DV4956',
  },
  {
    'Opportunity ID': '0061K00000jlxwY',
    'Opportunity Name': '2022 CNMP Spurlock Sec 36 E 1/3',
    'Account Name': 'Spurlock Farms',
    Field: 'Sec 36 E 1/3',
    'Program ID': 'DV4936',
  },
  {
    'Opportunity ID': '0061K00000jlxxC',
    'Opportunity Name': '2022 CNMP Spurlock Sec 43/44 W 1/2',
    'Account Name': 'Spurlock Farms',
    Field: 'Sec 43/44 W 1/2',
    'Program ID': 'DV4938',
  },
  {
    'Opportunity ID': '0061K00000jlzA7',
    'Opportunity Name': '2022 CNMP Travis Fraiser NW 33-32-32',
    'Account Name': 'Travis Fieser',
    Field: 'NW 33-32-32',
    'Program ID': 'DV4987',
  },
  {
    'Opportunity ID': '0061K00000jlzA8',
    'Opportunity Name': '2022 CNMP Travis Fraiser NE 32-32-32',
    'Account Name': 'Travis Fieser',
    Field: 'NE 32-32-32',
    'Program ID': 'DV4988',
  },
  {
    'Opportunity ID': '0061K00000jlzAR',
    'Opportunity Name': '2022 CNMP Travis Fraiser SW 20-32-32',
    'Account Name': 'Travis Fieser',
    Field: 'SW 20-32-32',
    'Program ID': 'DV4989',
  },
  {
    'Opportunity ID': '0061K00000jlkcl',
    'Opportunity Name': '2022 CNMP Sec 47 Town E 1/2',
    'Account Name': 'Dan Kreinke',
    Field: 'Sec 47 Town E 1/2',
    'Program ID': 'DV4878',
  },
  {
    'Opportunity ID': '0061K00000jlkcm',
    'Opportunity Name': '2022 CNMP Town South/West side',
    'Account Name': 'Dan Kreinke',
    Field: 'Town South/West side',
    'Program ID': 'DV4879',
  },
  {
    'Opportunity ID': '0061K00000jlkbs',
    'Opportunity Name': '2022 CNMP Boebeck East',
    'Account Name': 'Dan Kreinke',
    Field: 'Boebeck East',
    'Program ID': 'DV4873',
  },
  {
    'Opportunity ID': '0061K00000jlkaB',
    'Opportunity Name': '2022 CNMP #16',
    'Account Name': 'Rod Bohlender',
    Field: '#16',
    'Program ID': 'DV4872',
  },
  {
    'Opportunity ID': '0061K00000jm1tp',
    'Opportunity Name': '2022 CNMP Reznik 396N W1/2',
    'Account Name': 'John Reznik',
    Field: '396N W1/2',
    'Program ID': 'DV5002',
  },
  {
    'Opportunity ID': '0061K00000jm1tq',
    'Opportunity Name': '2022 CNMP Reznik 206',
    'Account Name': 'John Reznik',
    Field: '206',
    'Program ID': 'DV5004',
  },
  {
    'Opportunity ID': '0061K00000jlkKm',
    'Opportunity Name': '2022 CNMP RB S1/2',
    'Account Name': 'J & M Meyer Farms',
    Field: 'RB S1/2',
    'Program ID': 'DV4813',
  },
  {
    'Opportunity ID': '0061K00000jlkKn',
    'Opportunity Name': '2022 CNMP A2',
    'Account Name': '6A Farms',
    Field: 'A2',
    'Program ID': 'DV4816',
  },
  {
    'Opportunity ID': '0061K00000jlkKo',
    'Opportunity Name': '2022 CNMP D1',
    'Account Name': '6A Farms',
    Field: 'D1',
    'Program ID': 'DV4820',
  },
  {
    'Opportunity ID': '0061K00000jlkN3',
    'Opportunity Name': '2022 CNMP 2',
    'Account Name': 'R-Way Farms',
    Field: '2',
    'Program ID': 'DV4832',
  },
  {
    'Opportunity ID': '0061K00000jmRxS',
    'Opportunity Name': '2022 CNMP Nightingale NE 22-27-36',
    'Account Name': 'Barry Nightingale',
    Field: 'NE 22-27-36',
    'Program ID': 'DV5243',
  },
  {
    'Opportunity ID': '0061K00000jmRwy',
    'Opportunity Name': '2022 CNMP Nightingale SE 2-27-36',
    'Account Name': 'Barry Nightingale',
    Field: 'SE 2-27-36',
    'Program ID': 'DV5241',
  },
  {
    'Opportunity ID': '0061K00000jmRxI',
    'Opportunity Name': '2022 CNMP Nightingale SW 2-27-36',
    'Account Name': 'Barry Nightingale',
    Field: 'SW 2-27-36',
    'Program ID': 'DV5242',
  },
  {
    'Opportunity ID': '0061K00000jmVki',
    'Opportunity Name': '2022 CNMP Wylie Martin SE 32-29-39',
    'Account Name': 'Brent & Wylie Martin',
    Field: 'SE 32-29-39',
    'Program ID': 'DV5247',
  },
  {
    'Opportunity ID': '0061K00000jmVkj',
    'Opportunity Name': '2022 CNMP Wylie Martin SW 32-29-39',
    'Account Name': 'Brent & Wylie Martin',
    Field: 'SW 32-29-39',
    'Program ID': 'DV5248',
  },
  {
    'Opportunity ID': '0061K00000jmVl2',
    'Opportunity Name': '2022 CNMP Wylie Martin NE 25-29-40',
    'Account Name': 'Brent & Wylie Martin',
    Field: 'NE 25-29-40',
    'Program ID': 'DV5249',
  },
  {
    'Opportunity ID': '0061K00000jmVlC',
    'Opportunity Name': '2022 CNMP Wylie Martin W/ 5-30-39',
    'Account Name': 'Brent & Wylie Martin',
    Field: 'W/ 5-30-39',
    'Program ID': 'DV5250',
  },
  {
    'Opportunity ID': '0061K00000jmVlR',
    'Opportunity Name': '2022 CNMP Wylie Martin NE 34-29-40',
    'Account Name': 'Brent & Wylie Martin',
    Field: 'NE 34-29-40',
    'Program ID': 'DV5251',
  },
  {
    'Opportunity ID': '0061K00000jmVlg',
    'Opportunity Name': '2022 CNMP Wylie Martin NW 9-30-40',
    'Account Name': 'Brent & Wylie Martin',
    Field: 'NW 9-30-40',
    'Program ID': 'DV5252',
  },
  {
    'Opportunity ID': '0061K00000jmVlv',
    'Opportunity Name': '2022 CNMP Wylie Martin NE 2-30-40',
    'Account Name': 'Brent & Wylie Martin',
    Field: 'NE 2-30-40',
    'Program ID': 'DV5253',
  },
  {
    'Opportunity ID': '0061K00000jmjQW',
    'Opportunity Name': '2022 CNMP FC',
    'Account Name': 'Tim Gee',
    Field: 'FC',
    'Program ID': 'DV5351',
  },
  {
    'Opportunity ID': '0061K00000jmjQl',
    'Opportunity Name': '2022 CNMP 1/2 C',
    'Account Name': 'Tim Gee',
    Field: '1/2 C',
    'Program ID': 'DV5352',
  },
  {
    'Opportunity ID': '0061K00000jnLqu',
    'Opportunity Name': '2022 CNMP W 1/2',
    'Account Name': 'Richard Schlabs',
    Field: 'W 1/2',
    'Program ID': 'DV5473',
  },
  {
    'Opportunity ID': '0061K00000jnLpS',
    'Opportunity Name': '2022 CNMP 83 W',
    'Account Name': 'Roy Carlson',
    Field: 'Sec 83 W',
    'Program ID': 'DV5468',
  },
  {
    'Opportunity ID': '0061K00000kL7VR',
    'Opportunity Name': '2022 CNMP Sec 11',
    'Account Name': 'Brent Carlson',
    Field: 'Sec 11',
    'Program ID': 'DV5507',
  },
  {
    'Opportunity ID': '0061K00000jnLp3',
    'Opportunity Name': '2022 CNMP 98 SE',
    'Account Name': 'C Bar 2',
    Field: 'Sec 98 SE',
    'Program ID': 'DV5466',
  },
  {
    'Opportunity ID': '0061K00000jnLp4',
    'Opportunity Name': '2022 CNMP 99 NE',
    'Account Name': 'C Bar 2',
    Field: 'Sec 99 NE',
    'Program ID': 'DV5467',
  },
  {
    'Opportunity ID': '0061K00000jnLq1',
    'Opportunity Name': '2022 CNMP 52 N',
    'Account Name': 'C Bar 2 / Brent Carlson',
    Field: 'Sec 52 N',
    'Program ID': 'DV5471',
  },
  {
    'Opportunity ID': '0061K00000jnLk3',
    'Opportunity Name': '2022 CNMP NE 7-33-37',
    'Account Name': 'Eagle Precision Ag',
    Field: 'NE 7-33-37',
    'Program ID': 'DV5462',
  },
  {
    'Opportunity ID': '0061K00000jnLpc',
    'Opportunity Name': '2022 CNMP 79 E',
    'Account Name': 'Roy Carlson',
    Field: 'Sec 79 E',
    'Program ID': 'DV5469',
  },
  {
    'Opportunity ID': '0061K00000jnLpd',
    'Opportunity Name': '2022 CNMP 50 SE',
    'Account Name': 'C Bar 2 / Brent Carlson',
    Field: 'Sec 50 SE',
    'Program ID': 'DV5470',
  },
  {
    'Opportunity ID': '0061K00000jnLot',
    'Opportunity Name': '2022 CNMP 100 SW',
    'Account Name': 'Carlson Brothers',
    Field: 'Sec 100 SW',
    'Program ID': 'DV5465',
  },
  {
    'Opportunity ID': '0061K00000kL7Vl',
    'Opportunity Name': '2022 CNMP Sec 12',
    'Account Name': 'Brent Carlson',
    Field: 'Sec 12',
    'Program ID': 'DV5508',
  },
  {
    'Opportunity ID': '0061K00000jm5Tw',
    'Opportunity Name': '2022 CNMP J&S 247 NE',
    'Account Name': 'J & S Joint Ventures',
    Field: '247 NE',
    'Program ID': 'DV5063',
  },
  {
    'Opportunity ID': '0061K00000jm5QE',
    'Opportunity Name': '2022 CNMP J&S 73 SW',
    'Account Name': 'J & S Joint Ventures',
    Field: '73 SW',
    'Program ID': 'DV5048',
  },
  {
    'Opportunity ID': '0061K00000jm5QF',
    'Opportunity Name': '2022 CNMP J&S 102 NE',
    'Account Name': 'J & S Joint Ventures',
    Field: '102 NE',
    'Program ID': 'DV5051',
  },
  {
    'Opportunity ID': '0061K00000jm5QG',
    'Opportunity Name': '2022 CNMP J&S 205 SE',
    'Account Name': 'J & S Joint Ventures',
    Field: '205 SE',
    'Program ID': 'DV5062',
  },
  {
    'Opportunity ID': '0061K00000jm5gP',
    'Opportunity Name': '2022 CNMP DeBoer S1',
    'Account Name': 'Abel DeBoer',
    Field: 'S1',
    'Program ID': 'DV5080',
  },
  {
    'Opportunity ID': '0061K00000jm5gQ',
    'Opportunity Name': '2022 CNMP DeBoer MF8',
    'Account Name': 'Abel DeBoer',
    Field: 'MF8',
    'Program ID': 'DV5081',
  },
  {
    'Opportunity ID': '0061K00000jlwxA',
    'Opportunity Name': '2022 CNMP Hasta 409 Big',
    'Account Name': 'Hasta Farms',
    Field: '409 Big',
    'Program ID': 'DV4897',
  },
  {
    'Opportunity ID': '0061K00000jlwxB',
    'Opportunity Name': '2022 CNMP Hasta 390-sp',
    'Account Name': 'Hasta Farms',
    Field: '390-sp',
    'Program ID': 'DV4899',
  },
  {
    'Opportunity ID': '0061K00000jlwxC',
    'Opportunity Name': '2022 CNMP Hasta 365 East',
    'Account Name': 'Hasta Farms',
    Field: '365 East',
    'Program ID': 'DV4901',
  },
  {
    'Opportunity ID': '0061K00000jm5XK',
    'Opportunity Name': '2022 SNMP J&S 178 GWS',
    'Account Name': 'J & S Joint Ventures',
    Field: '178 GWN',
    'Program ID': 'DV5073',
  },
  {
    'Opportunity ID': '0061K00000jm5XL',
    'Opportunity Name': '2022 SNMP J&S 247 SW',
    'Account Name': 'J & S Joint Ventures',
    Field: '247 SW',
    'Program ID': 'DV5075',
  },
  {
    'Opportunity ID': '0061K00000jm5sQ',
    'Opportunity Name': '2022 SNMP Fangman 208 SE',
    'Account Name': 'Fangman Farms',
    Field: '208 SE',
    'Program ID': 'DV5097',
  },
  {
    'Opportunity ID': '0061K00000jm5qP',
    'Opportunity Name': '2022 SNMP Fangman 129 N',
    'Account Name': 'Fangman Farms',
    Field: '129 N',
    'Program ID': 'DV5090',
  },
  {
    'Opportunity ID': '0061K00000jm5rI',
    'Opportunity Name': '2022 SNMP Fangman 177 SE',
    'Account Name': 'Fangman Farms',
    Field: '177 SE',
    'Program ID': 'DV5095',
  },
  {
    'Opportunity ID': '0061K00000kLnm7',
    'Opportunity Name': '2022 SNMP HM NE 1/3',
    'Account Name': 'Tall Tex',
    Field: 'HM NE 1/3',
    'Program ID': 'DV5530',
  },
  {
    'Opportunity ID': '0061K00000kLnli',
    'Opportunity Name': '2022 SNMP E 1/4',
    'Account Name': 'Tall Tex',
    Field: 'E 1/4',
    'Program ID': 'DV5529',
  },
  {
    'Opportunity ID': '0061K00000jm5WC',
    'Opportunity Name': '2022 SNMP J&S 142 NE',
    'Account Name': 'J & S Joint Ventures',
    Field: '142 NE',
    'Program ID': 'DV5071',
  },
  {
    'Opportunity ID': '0061K00000jm5WD',
    'Opportunity Name': '2022 SNMP J&S 143 NE',
    'Account Name': 'J & S Joint Ventures',
    Field: '143 NE',
    'Program ID': 'DV5072',
  },
  {
    'Opportunity ID': '0061K00000jlzBF',
    'Opportunity Name': '2022 SNMP Ray Schlabs D W1/2',
    'Account Name': 'Ray Schlabs',
    Field: 'D W1/2',
    'Program ID': 'DV4992',
  },
  {
    'Opportunity ID': '0061K00000jlz9k',
    'Opportunity Name': '2022 SNMP Ray Schlabs Ba N1/2',
    'Account Name': 'Ray Schlabs',
    Field: 'Ba N1/2',
    'Program ID': 'DV4993',
  },
  {
    'Opportunity ID': '0061K00000jm6Dd',
    'Opportunity Name': '2022 SNMP Leona Caraway 171 S',
    'Account Name': 'Leona Caraway',
    Field: '171 S',
    'Program ID': 'DV5100',
  },
  {
    'Opportunity ID': '0061K00000jm5qu',
    'Opportunity Name': '2022 SNMP Fangman 242 NW',
    'Account Name': 'Fangman Farms',
    Field: '242 NW',
    'Program ID': 'DV5098',
  },
  {
    'Opportunity ID': '0061K00000jlkYZ',
    'Opportunity Name': '2022 SNMP N2',
    'Account Name': 'Ronald Meyer',
    Field: 'N2',
    'Program ID': 'DV4866',
  },
  {
    'Opportunity ID': '0061K00000jlkYy',
    'Opportunity Name': '2022 SNMP W2',
    'Account Name': 'Ronald Meyer',
    Field: 'W2',
    'Program ID': 'DV4868',
  },
  {
    'Opportunity ID': '0061K00000jlkKD',
    'Opportunity Name': '2022 SNMP Dorlan Burton W1/4',
    'Account Name': 'Dorland Burton',
    Field: 'W 1/4',
    'Program ID': 'DV4809',
  },
  {
    'Opportunity ID': '0061K00000jm5rD',
    'Opportunity Name': '2022 SNMP Fangman 166 W',
    'Account Name': 'Fangman Farms',
    Field: '166 W',
    'Program ID': 'DV5094',
  },
  {
    'Opportunity ID': '0061K00000jm5rS',
    'Opportunity Name': '2022 SNMP Fangman 204 SW',
    'Account Name': 'Fangman Farms',
    Field: '204 SW',
    'Program ID': 'DV5096',
  },
  {
    'Opportunity ID': '0061K00000jm5Vi',
    'Opportunity Name': '2022 SNMP J&S 99 SE',
    'Account Name': 'J & S Joint Ventures',
    Field: '99 SE',
    'Program ID': 'DV5069',
  },
  {
    'Opportunity ID': '0061K00000jm5qt',
    'Opportunity Name': '2022 SNMP Fangman 161 E',
    'Account Name': 'Fangman Farms',
    Field: '161 E',
    'Program ID': 'DV5092',
  },
  {
    'Opportunity ID': '0061K00000jlkY1',
    'Opportunity Name': '2022 SNMP WM1',
    'Account Name': 'Ronald Meyer',
    Field: 'WM1',
    'Program ID': 'DV4865',
  },
  {
    'Opportunity ID': '0061K00000jlwvi',
    'Opportunity Name': '2022 SNMP 328 E N1/2',
    'Account Name': 'Hasta Farms',
    Field: '328 E N1/2',
    'Program ID': 'DV4895',
  },
  {
    'Opportunity ID': '0061K00000jm5h3',
    'Opportunity Name': '2022 SNMP Fangman 128 EN',
    'Account Name': 'Fangman Farms',
    Field: '128 EN',
    'Program ID': 'DV5082',
  },
  {
    'Opportunity ID': '0061K00000jm6Gw',
    'Opportunity Name': '2022 SNMP Leona Caraway 207 W',
    'Account Name': 'Leona Caraway',
    Field: '207 W',
    'Program ID': 'DV5101',
  },
  {
    'Opportunity ID': '0061K00000jm5UR',
    'Opportunity Name': '2022 SNMP J&S 103 Drip',
    'Account Name': 'J & S Joint Ventures',
    Field: '103 Drip',
    'Program ID': 'DV5070',
  },
  {
    'Opportunity ID': '0061K00000jlwvT',
    'Opportunity Name': '2022 SNMP Hasta 345 West',
    'Account Name': 'Hasta Farms',
    Field: '345 West',
    'Program ID': 'DV4894',
  },
  {
    'Opportunity ID': '0061K00000jlwvO',
    'Opportunity Name': '2022 SNMP Hasta 365 West',
    'Account Name': 'Hasta Farms',
    Field: '365 West',
    'Program ID': 'DV4893',
  },
  {
    'Opportunity ID': '0061K00000jlwvs',
    'Opportunity Name': '2022 SNMP Hasta 371 W 1/2',
    'Account Name': 'Hasta Farms',
    Field: '371 W 1/2',
    'Program ID': 'DV4896',
  },
  {
    'Opportunity ID': '0061K00000jlkN2',
    'Opportunity Name': '2022 SNMP VV4',
    'Account Name': 'Valley View Partners',
    Field: 'VV4',
    'Program ID': 'DV4823',
  },
  {
    'Opportunity ID': '0061K00000jlkZD',
    'Opportunity Name': '2022 SNMP E1',
    'Account Name': 'Ronald Meyer',
    Field: 'E1',
    'Program ID': 'DV4869',
  },
  {
    'Opportunity ID': '0061K00000jlkZI',
    'Opportunity Name': '2022 SNMP S2',
    'Account Name': 'Ronald Meyer',
    Field: 'S2',
    'Program ID': 'DV4870',
  },
  {
    'Opportunity ID': '0061K00000jm5XP',
    'Opportunity Name': '2022 SNMP J&S 205 NW',
    'Account Name': 'J & S Joint Ventures',
    Field: '205 NW',
    'Program ID': 'DV5074',
  },
  {
    'Opportunity ID': '0061K00000jmVdj',
    'Opportunity Name': '2022 SNMP T&J Farms 259 E 1/2',
    'Account Name': 'T & J Farms',
    Field: '259 E 1/2',
    'Program ID': 'DV5245',
  },
  {
    'Opportunity ID': '0061K00000jnLpe',
    'Opportunity Name': '2022 SNMP #2',
    'Account Name': 'McBryde L & C',
    Field: '# 2',
    'Program ID': 'DV5474',
  },
  {
    'Opportunity ID': '0061K00000jnLrx',
    'Opportunity Name': '2022 SNMP # 4',
    'Account Name': 'McBryde L & C',
    Field: '# 4',
    'Program ID': 'DV5475',
  },
  {
    'Opportunity ID': '0061K00000jm5Tx',
    'Opportunity Name': '2022 SNMP J&S 65',
    'Account Name': 'J & S Joint Ventures',
    Field: '65',
    'Program ID': 'DV5066',
  },
  {
    'Opportunity ID': '0061K00000jm5Ty',
    'Opportunity Name': '2022 SNMP J&S 67 W',
    'Account Name': 'J & S Joint Ventures',
    Field: '67 W',
    'Program ID': 'DV5067',
  },
  {
    'Opportunity ID': '0061K00000jm5Tz',
    'Opportunity Name': '2022 SNMP J&S 72 SW',
    'Account Name': 'J & S Joint Ventures',
    Field: '72 SW',
    'Program ID': 'DV5068',
  },
  // 2023 data
  {
    'Opportunity ID': '0061K00000lbgdG',
    'Opportunity Name': '2023 CNMP Spurlock - ES 10',
    'Account Name': 'Spurlock Farms',
    Field: 'ES 10',
    'Program ID': 'DV8574',
  },
  {
    'Opportunity ID': '0061K00000lbi73',
    'Opportunity Name': '2023 CNMP Tom Schlabs - R W Pivot E1/2',
    'Account Name': 'Tom Schlabs',
    Field: 'R W Pivot E1/2',
    'Program ID': 'DV8627',
  },
  {
    'Opportunity ID': '0061K00000lbgo6',
    'Opportunity Name': '2023 CNMP Spurlock - Sec 36 NW 1/3',
    'Account Name': 'Spurlock Farms',
    Field: 'Sec 36 NW 1/3',
    'Program ID': 'DV8588',
  },
  {
    'Opportunity ID': '0061K00000lbgnm',
    'Opportunity Name': '2023 CNMP Spurlock - Sec 35 SE',
    'Account Name': 'Spurlock Farms',
    Field: 'Sec 35 SE',
    'Program ID': 'DV8587',
  },
  {
    'Opportunity ID': '0061K00000lbhtT',
    'Opportunity Name': "2023 CNMP J&M Meyer - Donnie's M 1/3",
    'Account Name': 'J & M Meyer Farms',
    Field: "Donnie's M 1/3",
    'Program ID': 'DV8604',
  },
  {
    'Opportunity ID': '0061K00000lbibh',
    'Opportunity Name': "2023 CNMP Cody Fischer - Johnson E1/2 (Cody's)",
    'Account Name': 'Cody Fischer',
    Field: "Johnson E1/2 (Cody's)",
    'Program ID': 'DV8630',
  },
  {
    'Opportunity ID': '0061K00000lbi6P',
    'Opportunity Name': '2023 CNMP Mitch Meyer - Diller West NE 1/2',
    'Account Name': 'Mitch Meyer',
    Field: 'Diller West NE 1/2',
    'Program ID': 'DV8624',
  },
  {
    'Opportunity ID': '0061K00000lbhji',
    'Opportunity Name': '2023 CNMP Cody Fischer - Johnson W1/2',
    'Account Name': 'Cody Fischer',
    Field: 'Johnson W1/2',
    'Program ID': 'DV8601',
  },
  {
    'Opportunity ID': '0061K00000lbhjj',
    'Opportunity Name': '2023 CNMP Gary Schlabs - Lemons E 1/2',
    'Account Name': 'Gary Schlabs',
    Field: 'Lemons E 1/2',
    'Program ID': 'DV8602',
  },
  {
    'Opportunity ID': '0061K00000lbhtn',
    'Opportunity Name': '2023 SNMP Fangman Farms - 128 EN',
    'Account Name': 'Fangman Farms',
    Field: '128 EN',
    'Program ID': 'DV8607',
  },
  {
    'Opportunity ID': '0061K00000lbhto',
    'Opportunity Name': '2023 SNMP Fangman Farms - 177 N',
    'Account Name': 'Fangman Farms',
    Field: '177 N',
    'Program ID': 'DV8610',
  },
  {
    'Opportunity ID': '0061K00000lbi3P',
    'Opportunity Name': '2023 CNMP Ray Schlabs - EE',
    'Account Name': 'Ray Schlabs',
    Field: 'EE',
    'Program ID': 'DV8617',
  },
  {
    'Opportunity ID': '0061K00000lbi6t',
    'Opportunity Name': '2023 CNMP Tom Schlabs - M N Pivot N1/2',
    'Account Name': 'Tom Schlabs',
    Field: 'M N Pivot N1/2',
    'Program ID': 'DV8626',
  },
  {
    'Opportunity ID': '0061K00000lbgmP',
    'Opportunity Name': '2023 CNMP Spurlock - Sec 58 (WBU 23)',
    'Account Name': 'Spurlock Farms',
    Field: 'Sec 58 (WBU 23)',
    'Program ID': 'DV8578',
  },
  {
    'Opportunity ID': '0061K00000lbgmS',
    'Opportunity Name': '2023 CNMP Spurlock - Sec 36 S 1/3',
    'Account Name': 'Spurlock Farms',
    Field: 'Sec 36 S 1/3',
    'Program ID': 'DV8589',
  },
  {
    'Opportunity ID': '0061K00000lbgmT',
    'Opportunity Name': '2023 SNMP Leona Caraway - 171N',
    'Account Name': 'Leona Caraway',
    Field: '171N',
    'Program ID': 'DV8598',
  },
  {
    'Opportunity ID': '0061K00000lbgoa',
    'Opportunity Name': '2023 CNMP Spurlock - Sec 234 S 1/2',
    'Account Name': 'Spurlock Farms',
    Field: 'Sec 234 S 1/2',
    'Program ID': 'DV8590',
  },
  {
    'Opportunity ID': '0061K00000lbgob',
    'Opportunity Name': '2023 CNMP Spurlock - Sec 310 W1/2',
    'Account Name': 'Spurlock Farms',
    Field: 'Sec 310 West 1/2',
    'Program ID': 'DV8592',
  },
  {
    'Opportunity ID': '0061K00000lbgoc',
    'Opportunity Name': '2023 CNMP Ralph Paschel - Ford Middle',
    'Account Name': 'Ralph Paschel',
    Field: 'Ford Middle',
    'Program ID': 'DV8596',
  },
  {
    'Opportunity ID': '0061K00000lbhvy',
    'Opportunity Name': '2023 CNMP John Meyer - FC West',
    'Account Name': 'John Meyer',
    Field: 'FC West',
    'Program ID': 'DV8614',
  },
  {
    'Opportunity ID': '0061K00000lbhvz',
    'Opportunity Name': '2023 CNMP John Meyer - RB NW 1/2',
    'Account Name': 'John Meyer',
    Field: 'RB NW 1/2',
    'Program ID': 'DV8615',
  },
  {
    'Opportunity ID': '0061K00000lbi5q',
    'Opportunity Name': '2023 CNMP Mitch Meyer - Diller East S 1/2',
    'Account Name': 'Mitch Meyer',
    Field: 'Diller East S 1/2',
    'Program ID': 'DV8623',
  },
  {
    'Opportunity ID': '0061K00000lbi5r',
    'Opportunity Name': '2023 CNMP Tom Schlabs - S W1/2',
    'Account Name': 'Tom Schlabs',
    Field: 'S W1/2',
    'Program ID': 'DV8625',
  },
  {
    'Opportunity ID': '0061K00000lbi4x',
    'Opportunity Name': '2023 CNMP Mitch Meyer - Nafzger East 1/4',
    'Account Name': 'Mitch Meyer',
    Field: 'Nafzger East 1/4',
    'Program ID': 'DV8622',
  },
  {
    'Opportunity ID': '0061K00000lbi4y',
    'Opportunity Name': '2023 SNMP Tom Schlabs - Rine E S1/2',
    'Account Name': 'Tom Schlabs',
    Field: 'Rine E S1/2',
    'Program ID': 'DV8628',
  },
  {
    'Opportunity ID': '0061K00000lbi4w',
    'Opportunity Name': '2023 CNMP Richard Schlabs - E 1/2 N',
    'Account Name': 'Richard Schlabs',
    Field: 'E 1/2 N',
    'Program ID': 'DV8621',
  },
  {
    'Opportunity ID': '0061K00000lbi4z',
    'Opportunity Name': '2023 SNMP Dorland Burton - M',
    'Account Name': 'Dorland Burton',
    Field: 'M',
    'Program ID': 'DV8629',
  },
  {
    'Opportunity ID': '0061K00000lbhtx',
    'Opportunity Name': '2023 SNMP Fangman Farms - 129 SE',
    'Account Name': 'Fangman Farms',
    Field: '129 SE',
    'Program ID': 'DV8608',
  },
  {
    'Opportunity ID': '0061K00000lbhty',
    'Opportunity Name': '2023 SNMP Fangman Farms - 208 SE',
    'Account Name': 'Fangman Farms',
    Field: '208 SE',
    'Program ID': 'DV8612',
  },
  {
    'Opportunity ID': '0061K00000lbhtz',
    'Opportunity Name': '2023 SNMP Fangman Farms - 242 SE',
    'Account Name': 'Fangman Farms',
    Field: '242 SE',
    'Program ID': 'DV8613',
  },
  {
    'Opportunity ID': '0061K00000lbhZo',
    'Opportunity Name': '2023 CNMP J&M Meyer - HF NE 1/4',
    'Account Name': 'J & M Meyer Farms',
    Field: 'HF NE 1/4',
    'Program ID': 'DV8605',
  },
  {
    'Opportunity ID': '0061K00000lbgnJ',
    'Opportunity Name': '2023 CNMP Ralph Paschel - Dales North N 1/2',
    'Account Name': 'Ralph Paschel',
    Field: 'Dales North N 1/2',
    'Program ID': 'DV8594',
  },
  {
    'Opportunity ID': '0061K00000lbgnK',
    'Opportunity Name': "2023 CNMP Ralph Paschel - MC S 1/2's",
    'Account Name': 'Ralph Paschel',
    Field: "MC S 1/2's",
    'Program ID': 'DV8595',
  },
  {
    'Opportunity ID': '0061K00000lbgiu',
    'Opportunity Name': '2023 CNMP Spurlock - Sec 12 West',
    'Account Name': 'Spurlock Farms',
    Field: 'Sec 12 West',
    'Program ID': 'DV8575',
  },
  {
    'Opportunity ID': '0061K00000lbi3j',
    'Opportunity Name': '2023 CNMP Ray Schlabs - D E1/2',
    'Account Name': 'Ray Schlabs',
    Field: 'D E1/2',
    'Program ID': 'DV8618',
  },
  {
    'Opportunity ID': '0061K00000lbi3k',
    'Opportunity Name': '2023 SNMP Ray Schlabs - BA S1/2',
    'Account Name': 'Ray Schlabs',
    Field: 'BA S1/2',
    'Program ID': 'DV8619',
  },
  {
    'Opportunity ID': '0061K00000lbi3l',
    'Opportunity Name': '2023 SNMP Gary Schlabs - Rose',
    'Account Name': 'Gary Schlabs',
    Field: 'Rose',
    'Program ID': 'DV8620',
  },
  {
    'Opportunity ID': '0061K00000lbi3F',
    'Opportunity Name': '2023 CNMP Ray Schlabs - R',
    'Account Name': 'Ray Schlabs',
    Field: 'R',
    'Program ID': 'DV8616',
  },
  {
    'Opportunity ID': '0061K00000lbgkk',
    'Opportunity Name': '2023 CNMP Spurlock - Sec 15',
    'Account Name': 'Spurlock Farms',
    Field: 'Sec 15',
    'Program ID': 'DV8576',
  },
  {
    'Opportunity ID': '0061K00000lbgkl',
    'Opportunity Name': '2023 CNMP Spurlock - Sec 35 SW',
    'Account Name': 'Spurlock Farms',
    Field: 'Sec 35 SW',
    'Program ID': 'DV8586',
  },
  {
    'Opportunity ID': '0061K00000lbhtE',
    'Opportunity Name': '2023 CNMP Gary Schlabs - Mcwhorter E S1/2',
    'Account Name': 'Gary Schlabs',
    Field: 'Mcwhorter E S1/2',
    'Program ID': 'DV8603',
  },
  {
    'Opportunity ID': '0061K00000lbhuR',
    'Opportunity Name': '2023 SNMP Fangman Farms - 166 Se',
    'Account Name': 'Fangman Farms',
    Field: '166 SE',
    'Program ID': 'DV8609',
  },
  {
    'Opportunity ID': '0061K00000lbgmj',
    'Opportunity Name': '2023 CNMP Spurlock - Sec 58 (WBU 24)',
    'Account Name': 'Spurlock Farms',
    Field: 'Sec 58 (WBU 24)',
    'Program ID': 'DV8579',
  },
  {
    'Opportunity ID': '0061K00000lbhtd',
    'Opportunity Name': '2023 SNMP Fangman Farms - 161 W',
    'Account Name': 'Fangman Farms',
    Field: '161 W',
    'Program ID': 'DV8606',
  },
  {
    'Opportunity ID': '0061K00000lbgn3',
    'Opportunity Name': '2023 CNMP Spurlock - Sec 24',
    'Account Name': 'Spurlock Farms',
    Field: 'Sec 24',
    'Program ID': 'DV8580',
  },
  {
    'Opportunity ID': '0061K00000lbgq7',
    'Opportunity Name': '2023 CNMP Ralph Paschel - Home W 1/2',
    'Account Name': 'Ralph Paschel',
    Field: 'Home W 1/2',
    'Program ID': 'DV8593',
  },
  {
    'Opportunity ID': '0061K00000lbgq8',
    'Opportunity Name': '2023 SNMP Leona Caraway - 207 E',
    'Account Name': 'Leona Caraway',
    Field: '207 E',
    'Program ID': 'DV8599',
  },
  {
    'Opportunity ID': '0061K00000lbjbv',
    'Opportunity Name': '2023 SNMP Hasta Farms - 328 East South',
    'Account Name': 'Hasta Farms',
    Field: '328 East South',
    'Program ID': 'DV8650',
  },
  {
    'Opportunity ID': '0061K00000lbjbw',
    'Opportunity Name': '2023 CNMP Abel DeBoer - S1',
    'Account Name': 'Abel DeBoer',
    Field: 'S1',
    'Program ID': 'DV8654',
  },
  {
    'Opportunity ID': '0061K00000lbjbx',
    'Opportunity Name': '2023 CNMP Abel DeBoer - MF8',
    'Account Name': 'Abel DeBoer',
    Field: 'MF8',
    'Program ID': 'DV8655',
  },
  {
    'Opportunity ID': '0061K00000lbjby',
    'Opportunity Name': '2023 CNMP Dan Krienke - Sec. 89',
    'Account Name': 'Dan Kreinke',
    Field: 'Sec. 89',
    'Program ID': 'DV8658',
  },
  {
    'Opportunity ID': '0061K00000lbjX1',
    'Opportunity Name': '2023 CNMP 97 Farms - 2',
    'Account Name': '97 Farms',
    Field: '2',
    'Program ID': 'DV8644',
  },
  {
    'Opportunity ID': '0061K00000lbjX2',
    'Opportunity Name': '2023 SNMP Hasta Farms - 345 East',
    'Account Name': 'Hasta Farms',
    Field: '345 East',
    'Program ID': 'DV8651',
  },
  {
    'Opportunity ID': '0061K00000lbjX3',
    'Opportunity Name': '2023 CNMP Dan Krienke - Langhoff S1/2',
    'Account Name': 'Dan Kreinke',
    Field: 'Langhoff S1/2',
    'Program ID': 'DV8657',
  },
  {
    'Opportunity ID': '0061K00000lbjX4',
    'Opportunity Name': '2023 CNMP Dan Krienke - Town South E Circle',
    'Account Name': 'Dan Kreinke',
    Field: 'Town South E Circle',
    'Program ID': 'DV8660',
  },
  {
    'Opportunity ID': '0061K00000lbjVP',
    'Opportunity Name': '2023 CNMP 6A Farms - A1',
    'Account Name': '6A Farms',
    Field: 'A1',
    'Program ID': 'DV8637',
  },
  {
    'Opportunity ID': '0061K00000lbjVQ',
    'Opportunity Name': '2023 CNMP 6A Farms - K1',
    'Account Name': '6A Farms',
    Field: 'K1',
    'Program ID': 'DV8638',
  },
  {
    'Opportunity ID': '0061K00000lbjXR',
    'Opportunity Name': '2023 CNMP Abel DeBoer - M2',
    'Account Name': 'Abel DeBoer',
    Field: 'M2',
    'Program ID': 'DV8653',
  },
  {
    'Opportunity ID': '0061K00000lbjWN',
    'Opportunity Name': '2023 CNMP 6A Farms - S3',
    'Account Name': '6A Farms',
    Field: 'S3',
    'Program ID': 'DV8640',
  },
  {
    'Opportunity ID': '0061K00000lbjWm',
    'Opportunity Name': '2023 SNMP 97 Farms - 9',
    'Account Name': '97 Farms',
    Field: '9',
    'Program ID': 'DV8643',
  },
  {
    'Opportunity ID': '0061K00000lbjWn',
    'Opportunity Name': '2023 CNMP 97 Farms - 13 NE 1/3',
    'Account Name': '97 Farms',
    Field: '13 NE 1/3',
    'Program ID': 'DV8649',
  },
  {
    'Opportunity ID': '0061K00000lbjfW',
    'Opportunity Name': '2023 SNMP Hasta Farms - 371 East',
    'Account Name': 'Hasta Farms',
    Field: '371 East',
    'Program ID': 'DV8652',
  },
  {
    'Opportunity ID': '0061K00000lbjX6',
    'Opportunity Name': '2023 CNMP 97 Farms - 5',
    'Account Name': '97 Farms',
    Field: '5',
    'Program ID': 'DV8645',
  },
  {
    'Opportunity ID': '0061K00000lbjWS',
    'Opportunity Name': '2023 CNMP 6A Farms - D2',
    'Account Name': '6A Farms',
    Field: 'D2',
    'Program ID': 'DV8641',
  },
  {
    'Opportunity ID': '0061K00000lbji9',
    'Opportunity Name': '2023 CNMP Dan Krienke - Boebeck West',
    'Account Name': 'Dan Kreinke',
    Field: 'Boebeck West',
    'Program ID': 'DV8656',
  },
  {
    'Opportunity ID': '0061K00000lbjir',
    'Opportunity Name': '2023 CNMP Dan Krienke - Sec.47 Town W 1/2',
    'Account Name': 'Dan Kreinke',
    Field: 'Sec.47 Town W 1/2',
    'Program ID': 'DV8659',
  },
  {
    'Opportunity ID': '0061K00000lbmJs',
    'Opportunity Name': '2023 CNMP MFF - Sec 80 W / 81 E',
    'Account Name': 'Meyer Family Farms',
    Field: 'Sec 80 W / 81 E',
    'Program ID': 'DV8718',
  },
  {
    'Opportunity ID': '0061K00000lblBg',
    'Opportunity Name': '2023 CNMP R-Way Farms - 13',
    'Account Name': 'R-Way Farms',
    Field: '13',
    'Program ID': 'DV8685',
  },
  {
    'Opportunity ID': '0061K00000lblBh',
    'Opportunity Name': '2023 CNMP C Bar 2 / Brent Carlson - Section 52 SE',
    'Account Name': 'C Bar 2 / Brent Carlson',
    Field: 'Section 52 SE',
    'Program ID': 'DV8686',
  },
  {
    'Opportunity ID': '0061K00000lblC5',
    'Opportunity Name': '2023 CNMP C Bar 2 / Brent Carlson - Section 50 NE',
    'Account Name': 'C Bar 2 / Brent Carlson',
    Field: 'Section 50 NE',
    'Program ID': 'DV8687',
  },
  {
    'Opportunity ID': '0061K00000lblC6',
    'Opportunity Name': '2023 CNMP Valley View Partners - VV4',
    'Account Name': 'Valley View Partners',
    Field: 'VV4',
    'Program ID': 'DV8692',
  },
  {
    'Opportunity ID': '0061K00000lblC7',
    'Opportunity Name': '2023 SNMP Valley View Partners - VV3',
    'Account Name': 'Valley View Partners',
    Field: 'VV3',
    'Program ID': 'DV8696',
  },
  {
    'Opportunity ID': '0061K00000lblC8',
    'Opportunity Name': '2023 CNMP Levi Hedlund - NW 4-29-30',
    'Account Name': 'Levi Hedlund',
    Field: 'NW 4-29-30',
    'Program ID': 'DV8697',
  },
  {
    'Opportunity ID': '0061K00000lblFn',
    'Opportunity Name': '2023 CNMP Ron Gibson - NW NE 3-29-30',
    'Account Name': 'Ron Gibson',
    Field: 'NW NE 3-29-30',
    'Program ID': 'DV8704',
  },
  {
    'Opportunity ID': '0061K00000lblFo',
    'Opportunity Name': '2023 CNMP Travis Fieser - SW 33-32-32',
    'Account Name': 'Travis Fieser',
    Field: 'SW 33-32-32',
    'Program ID': 'DV8706',
  },
  {
    'Opportunity ID': '0061K00000lblFp',
    'Opportunity Name': '2023 CNMP Travis Fieser - NW 25-32-32',
    'Account Name': 'Travis Fieser',
    Field: 'NW 25-32-32',
    'Program ID': 'DV8707',
  },
  {
    'Opportunity ID': '0061K00000lblFq',
    'Opportunity Name': '2023 CNMP Travis Fieser - SE 10-32-34',
    'Account Name': 'Travis Fieser',
    Field: 'SE 10-32-34',
    'Program ID': 'DV8711',
  },
  {
    'Opportunity ID': '0061K00000lblAi',
    'Opportunity Name': '2023 CNMP R-Way Farms - 2',
    'Account Name': 'R-Way Farms',
    Field: '2',
    'Program ID': 'DV8681',
  },
  {
    'Opportunity ID': '0061K00000lblAj',
    'Opportunity Name': '2023 CNMP Brent Carlson - Section 12 NW',
    'Account Name': 'Brent Carlson',
    Field: 'Section 12 NW',
    'Program ID': 'DV8695',
  },
  {
    'Opportunity ID': '0061K00000lblEf',
    'Opportunity Name': '2023 CNMP Ron Gibson - SW 10 N1/2 & NE 16 W1/2',
    'Account Name': 'Ron Gibson',
    Field: 'SW 10 N1/2 & NE 16 W1/2',
    'Program ID': 'DV8699',
  },
  {
    'Opportunity ID': '0061K00000lblEg',
    'Opportunity Name': '2023 CNMP Ron Gibson - SE 10-30-30',
    'Account Name': 'Ron Gibson',
    Field: 'SE 10-30-30',
    'Program ID': 'DV8701',
  },
  {
    'Opportunity ID': '0061K00000lblGM',
    'Opportunity Name': '2023 CNMP Travis Fieser - NW 12-32-32',
    'Account Name': 'Travis Fieser',
    Field: 'NW 12-32-32',
    'Program ID': 'DV8708',
  },
  {
    'Opportunity ID': '0061K00000lblGN',
    'Opportunity Name': '2023 CNMP Travis Fieser - SW 28-32-32',
    'Account Name': 'Travis Fieser',
    Field: 'SW 28-32-32',
    'Program ID': 'DV8709',
  },
  {
    'Opportunity ID': '0061K00000lblEp',
    'Opportunity Name': '2023 CNMP Ron Gibson - NE 16-30-30 East 1/2',
    'Account Name': 'Ron Gibson',
    Field: 'NE 16-30-30 East 1/2',
    'Program ID': 'DV8700',
  },
  {
    'Opportunity ID': '0061K00000lblEq',
    'Opportunity Name': '2023 CNMP Ron Gibson - SW 4-29-30',
    'Account Name': 'Ron Gibson',
    Field: 'SW 4-29-30',
    'Program ID': 'DV8703',
  },
  {
    'Opportunity ID': '0061K00000lblCA',
    'Opportunity Name': '2023 CNMP C Bar 2 - Section 99 SW',
    'Account Name': 'C Bar 2 / Brent Carlson',
    Field: 'Section 99 SW',
    'Program ID': 'DV8688',
  },
  {
    'Opportunity ID': '0061K00000lblFx',
    'Opportunity Name': '2023 CNMP Travis Fieser - NW 33-32-32',
    'Account Name': 'Travis Fieser',
    Field: 'NW 33-32-32',
    'Program ID': 'DV8705',
  },
  {
    'Opportunity ID': '0061K00000lbmJn',
    'Opportunity Name': '2023 CNMP MFF - Sec 79 E',
    'Account Name': 'Meyer Family Farms',
    Field: 'Sec 79 E',
    'Program ID': 'DV8717',
  },
  {
    'Opportunity ID': '0061K00000lblGX',
    'Opportunity Name': '2023 CNMP Travis Fieser - NW 15-32-34',
    'Account Name': 'Travis Fieser',
    Field: 'NW 15-32-34',
    'Program ID': 'DV8710',
  },
  {
    'Opportunity ID': '0061K00000lbmJi',
    'Opportunity Name': '2023 CNMP MFF - Sec 78 E',
    'Account Name': 'Meyer Family Farms',
    Field: 'Sec 78 E',
    'Program ID': 'DV8716',
  },
  {
    'Opportunity ID': '0061K00000lblF9',
    'Opportunity Name': '2023 CNMP Ron Gibson - SW 11-30-30',
    'Account Name': 'Ron Gibson',
    Field: 'SW 11-30-30',
    'Program ID': 'DV8702',
  },
  {
    'Opportunity ID': '0061K00000lbmJx',
    'Opportunity Name': '2023 CNMP MFF - Sec 47 S',
    'Account Name': 'Meyer Family Farms',
    Field: 'Sec 47 S',
    'Program ID': 'DV8719',
  },
  {
    'Opportunity ID': '0061K00000lblAT',
    'Opportunity Name': '2023 CNMP Carlson Brothers - Section 100 NE',
    'Account Name': 'Carlson Brothers',
    Field: 'Section 100 NE',
    'Program ID': 'DV8678',
  },
  {
    'Opportunity ID': '0061K00000lblAU',
    'Opportunity Name': '2023 CNMP Roy Carlson - Section 79 W',
    'Account Name': 'Roy Carlson',
    Field: 'Section 79 W',
    'Program ID': 'DV8679',
  },
  {
    'Opportunity ID': '0061K00000lblAV',
    'Opportunity Name': '2023 CNMP Roy Carlson - Section 83 E',
    'Account Name': 'Roy Carlson',
    Field: 'Section 83 E',
    'Program ID': 'DV8680',
  },
  {
    'Opportunity ID': '0061K00000lblAW',
    'Opportunity Name': '2023 CNMP R-Way Farms - 7',
    'Account Name': 'R-Way Farms',
    Field: '7',
    'Program ID': 'DV8683',
  },
  {
    'Opportunity ID': '0061K00000lblAn',
    'Opportunity Name': '2023 CNMP R-Way Farms - 4',
    'Account Name': 'R-Way Farms',
    Field: '4',
    'Program ID': 'DV8682',
  },
  {
    'Opportunity ID': '0061K00000lblDX',
    'Opportunity Name': '2023 CNMP Valley View Partners - VV5',
    'Account Name': 'Valley View Partners',
    Field: 'VV5',
    'Program ID': 'DV8693',
  },
  {
    'Opportunity ID': '0061K00000lblCU',
    'Opportunity Name': '2023 CNMP C Bar 2 - Section 98 SW',
    'Account Name': 'C Bar 2 / Brent Carlson',
    Field: 'Section 98 SW',
    'Program ID': 'DV8689',
  },
  {
    'Opportunity ID': '0061K00000lblCV',
    'Opportunity Name': '2023 CNMP Eric Riffe - 145',
    'Account Name': 'Eric Riffe',
    Field: '145',
    'Program ID': 'DV8691',
  },
  {
    'Opportunity ID': '0061K00000lblCW',
    'Opportunity Name': '2023 CNMP Paul Dyck - Sec 96 W1/2',
    'Account Name': 'Paul Dyck',
    Field: 'Sec 96 W1/2',
    'Program ID': 'DV8698',
  },
  {
    'Opportunity ID': '0061K00000lbn0v',
    'Opportunity Name': '2023 CNMP John Reznik - 347 NW2/3',
    'Account Name': 'John Reznik',
    Field: '347 NW2/3',
    'Program ID': 'DV8722',
  },
  {
    'Opportunity ID': '0061K00000lbnA6',
    'Opportunity Name': '2023 CNMP Hasta Farms - 414',
    'Account Name': 'Hasta Farms',
    Field: '414',
    'Program ID': 'DV8761',
  },
  {
    'Opportunity ID': '0061K00000lbn6x',
    'Opportunity Name': '2023 CNMP Taylor Clark - BarN NE 16-1-10',
    'Account Name': 'Taylor Clark',
    Field: 'BarN NE 16-1-10',
    'Program ID': 'DV8732',
  },
  {
    'Opportunity ID': '0061K00000lbn8A',
    'Opportunity Name': '2023 CNMP Hasta Farms - 350',
    'Account Name': 'Hasta Farms',
    Field: '350',
    'Program ID': 'DV8747',
  },
  {
    'Opportunity ID': '0061K00000lbn6y',
    'Opportunity Name': '2023 CNMP Hasta Farms - 366 East',
    'Account Name': 'Hasta Farms',
    Field: '366 East',
    'Program ID': 'DV8752',
  },
  {
    'Opportunity ID': '0061K00000lbnA1',
    'Opportunity Name': '2023 CNMP Hasta Farms - 409 Big Treated',
    'Account Name': 'Hasta Farms',
    Field: '409 Big treated',
    'Program ID': 'DV8759',
  },
  {
    'Opportunity ID': '0061K00000lbnA2',
    'Opportunity Name': '2023 CNMP Hasta Farms - 409-3',
    'Account Name': 'Hasta Farms',
    Field: '409-3',
    'Program ID': 'DV8760',
  },
  {
    'Opportunity ID': '0061K00000lbn8L',
    'Opportunity Name': '2023 CNMP Hasta Farms - 390',
    'Account Name': 'Hasta Farms',
    Field: '390',
    'Program ID': 'DV8755',
  },
  {
    'Opportunity ID': '0061K00000lbn9X',
    'Opportunity Name': '2023 CNMP Hasta Farms - 393 East',
    'Account Name': 'Hasta Farms',
    Field: '393 East',
    'Program ID': 'DV8756',
  },
  {
    'Opportunity ID': '0061K00000lbn6d',
    'Opportunity Name': '2023 CNMP Taylor Clark - B6 34-1-11',
    'Account Name': 'Taylor Clark',
    Field: 'B6 34-1-11',
    'Program ID': 'DV8731',
  },
  {
    'Opportunity ID': '0061K00000lbn77',
    'Opportunity Name': '2023 CNMP J&S Joint Ventures - 65',
    'Account Name': 'J & S Joint Ventures',
    Field: '65',
    'Program ID': 'DV8734',
  },
  {
    'Opportunity ID': '0061K00000lbn1A',
    'Opportunity Name': '2023 CNMP John Reznik - 396N E1/2',
    'Account Name': 'John Reznik',
    Field: '396N E1/2',
    'Program ID': 'DV8723',
  },
  {
    'Opportunity ID': '0061K00000lbn1B',
    'Opportunity Name': '2023 CNMP John Reznik - 363 S1/2',
    'Account Name': 'John Reznik',
    Field: '363 S1/2',
    'Program ID': 'DV8724',
  },
  {
    'Opportunity ID': '0061K00000lbn9r',
    'Opportunity Name': '2023 CNMP Hasta Farms - 394',
    'Account Name': 'Hasta Farms',
    Field: '394',
    'Program ID': 'DV8758',
  },
  {
    'Opportunity ID': '0061K00000lbn1F',
    'Opportunity Name': '2023 CNMP John Reznik - 351',
    'Account Name': 'John Reznik',
    Field: '351',
    'Program ID': 'DV8725',
  },
  {
    'Opportunity ID': '0061K00000lbn1G',
    'Opportunity Name': '2023 CNMP Taylor Clark - B3 SE 28-1-11',
    'Account Name': 'Taylor Clark',
    Field: 'B3 SE 28-1-11',
    'Program ID': 'DV8728',
  },
  {
    'Opportunity ID': '0061K00000lbn72',
    'Opportunity Name': '2023 CNMP Taylor Clark - BarS SE 16-1-10',
    'Account Name': 'Taylor Clark',
    Field: 'BarS SE 16-1-10',
    'Program ID': 'DV8733',
  },
  {
    'Opportunity ID': '0061K00000lbn73',
    'Opportunity Name': '2023 CNMP J&S Joint Ventures - 72 SW',
    'Account Name': 'J & S Joint Ventures',
    Field: '72 SW',
    'Program ID': 'DV8737',
  },
  {
    'Opportunity ID': '0061K00000lbn74',
    'Opportunity Name': '2023 CNMP J&S Joint Ventures - 108 SW',
    'Account Name': 'J & S Joint Ventures',
    Field: '108 SW',
    'Program ID': 'DV8740',
  },
  {
    'Opportunity ID': '0061K00000lbn3U',
    'Opportunity Name': '2023 CNMP Taylor Clark - B2 NW33-1-11',
    'Account Name': 'Taylor Clark',
    Field: 'B2 NW33-1-11',
    'Program ID': 'DV8727',
  },
  {
    'Opportunity ID': '0061K00000lbn3V',
    'Opportunity Name': '2023 CNMP Taylor Clark - B5 SW 27-1-11',
    'Account Name': 'Taylor Clark',
    Field: 'B5 SW 27-1-11',
    'Program ID': 'DV8730',
  },
  {
    'Opportunity ID': '0061K00000lbn3W',
    'Opportunity Name': '2023 CNMP J&S Joint Ventures - 67 W',
    'Account Name': 'J & S Joint Ventures',
    Field: '67 W',
    'Program ID': 'DV8735',
  },
  {
    'Opportunity ID': '0061K00000lbn9h',
    'Opportunity Name': '2023 CNMP Hasta Farms - 393 South',
    'Account Name': 'Hasta Farms',
    Field: '393 South',
    'Program ID': 'DV8757',
  },
  {
    'Opportunity ID': '0061K00000lbn7W',
    'Opportunity Name': '2023 CNMP J&S Joint Ventures - 141 SW',
    'Account Name': 'J & S Joint Ventures',
    Field: '141 SW',
    'Program ID': 'DV8741',
  },
  {
    'Opportunity ID': '0061K00000lbn8y',
    'Opportunity Name': '2023 CNMP Hasta Farms - 366 West',
    'Account Name': 'Hasta Farms',
    Field: '366 West',
    'Program ID': 'DV8753',
  },
  {
    'Opportunity ID': '0061K00000lbn8z',
    'Opportunity Name': '2023 CNMP Hasta Farms - 371 West',
    'Account Name': 'Hasta Farms',
    Field: '371 West',
    'Program ID': 'DV8754',
  },
  {
    'Opportunity ID': '0061K00000lbn1n',
    'Opportunity Name': '2023 CNMP Taylor Clark - B1 NW33-1-11',
    'Account Name': 'Taylor Clark',
    Field: 'B1 NW33-1-11',
    'Program ID': 'DV8726',
  },
  {
    'Opportunity ID': '0061K00000lbn1o',
    'Opportunity Name': '2023 CNMP Taylor Clark - B4 NE 28-1-11',
    'Account Name': 'Taylor Clark',
    Field: 'B4 NE 28-1-11',
    'Program ID': 'DV8729',
  },
  {
    'Opportunity ID': '0061K00000lbn80',
    'Opportunity Name': '2023 CNMP Hasta Farms - 344',
    'Account Name': 'Hasta Farms',
    Field: '344',
    'Program ID': 'DV8745',
  },
  {
    'Opportunity ID': '0061K00000lbn81',
    'Opportunity Name': '2023 CNMP Hasta Farms - 352 West',
    'Account Name': 'Hasta Farms',
    Field: '352 West',
    'Program ID': 'DV8749',
  },
  {
    'Opportunity ID': '0061K00000lbn82',
    'Opportunity Name': '2023 CNMP Hasta Farms - 365 West',
    'Account Name': 'Hasta Farms',
    Field: '365 West',
    'Program ID': 'DV8751',
  },
  {
    'Opportunity ID': '0061K00000lbn7C',
    'Opportunity Name': '2023 CNMP J&S Joint Ventures - 70 SE',
    'Account Name': 'J & S Joint Ventures',
    Field: '70 SE',
    'Program ID': 'DV8736',
  },
  {
    'Opportunity ID': '0061K00000lbn7D',
    'Opportunity Name': '2023 CNMP J&S Joint Ventures - 73 SE',
    'Account Name': 'J & S Joint Ventures',
    Field: '73 SE',
    'Program ID': 'DV8738',
  },
  {
    'Opportunity ID': '0061K00000lbn7E',
    'Opportunity Name': '2023 CNMP J&S Joint Ventures - 98 SE',
    'Account Name': 'J & S Joint Ventures',
    Field: '98 SE',
    'Program ID': 'DV8739',
  },
  {
    'Opportunity ID': '0061K00000lbn7F',
    'Opportunity Name': '2023 CNMP Hasta Farms - 302 North',
    'Account Name': 'Hasta Farms',
    Field: '302 North',
    'Program ID': 'DV8742',
  },
  {
    'Opportunity ID': '0061K00000lbn7G',
    'Opportunity Name': '2023 CNMP Hasta Farms - 328 E North',
    'Account Name': 'Hasta Farms',
    Field: '328 E North',
    'Program ID': 'DV8743',
  },
  {
    'Opportunity ID': '0061K00000lbn7l',
    'Opportunity Name': '2023 CNMP Hasta Farms - 328 West W1/2',
    'Account Name': 'Hasta Farms',
    Field: '328 West W1/2',
    'Program ID': 'DV8744',
  },
  {
    'Opportunity ID': '0061K00000lbn7m',
    'Opportunity Name': '2023 CNMP Hasta Farms - 345 West',
    'Account Name': 'Hasta Farms',
    Field: '345 West',
    'Program ID': 'DV8746',
  },
  {
    'Opportunity ID': '0061K00000lbn7n',
    'Opportunity Name': '2023 CNMP Hasta Farms - 364',
    'Account Name': 'Hasta Farms',
    Field: '364',
    'Program ID': 'DV8750',
  },
  {
    'Opportunity ID': '0061K00000lbrEV',
    'Opportunity Name': '2023 CNMP Wylie Martin - NE 25-29-40',
    'Account Name': 'Wylie Martin',
    Field: 'NE 25-29-40',
    'Program ID': 'DV8773',
  },
  {
    'Opportunity ID': '0061K00000lbrEW',
    'Opportunity Name': '2023 CNMP Wylie Martin - SE 32-29-39',
    'Account Name': 'Wylie Martin',
    Field: 'SE 32-29-39',
    'Program ID': 'DV8774',
  },
  {
    'Opportunity ID': '0061K00000lbrFY',
    'Opportunity Name': '2023 CNMP Barry Nightingale - SW 10-27-36',
    'Account Name': 'Barry Nightingale',
    Field: 'SW 10-27-36',
    'Program ID': 'DV8779',
  },
  {
    'Opportunity ID': '0061K00000lbrEu',
    'Opportunity Name': '2023 CNMP Wylie Martin - SW 32-29-39',
    'Account Name': 'Wylie Martin',
    Field: 'SW 32-29-39',
    'Program ID': 'DV8775',
  },
  {
    'Opportunity ID': '0061K00000lbrEv',
    'Opportunity Name': '2023 CNMP Wylie Martin - W/ 5-30-39',
    'Account Name': 'Wylie Martin',
    Field: 'W/ 5-30-39',
    'Program ID': 'DV8776',
  },
  {
    'Opportunity ID': '0061K00000lbrEw',
    'Opportunity Name': '2023 CNMP Barry Nightingale - SE 2-27-36',
    'Account Name': 'Barry Nightingale',
    Field: 'SE 2-27-36',
    'Program ID': 'DV8777',
  },
  {
    'Opportunity ID': '0061K00000lbrCj',
    'Opportunity Name': '2023 CNMP Wylie Martin - NE 34-29-40',
    'Account Name': 'Wylie Martin',
    Field: 'NE 34-29-40',
    'Program ID': 'DV8772',
  },
  {
    'Opportunity ID': '0061K00000lbrCe',
    'Opportunity Name': '2023 CNMP Wylie Martin - NW 4-30-40 W1/2',
    'Account Name': 'Wylie Martin',
    Field: 'NW 4-30-40 W1/2',
    'Program ID': 'DV8771',
  },
  {
    'Opportunity ID': '0061K00000lbrC0',
    'Opportunity Name': '2023 CNMP Wylie Martin - NE 2-30-40 E1/2',
    'Account Name': 'Wylie Martin',
    Field: 'NE 2-30-40 E1/2',
    'Program ID': 'DV8770',
  },
  {
    'Opportunity ID': '0061K00000lbrFO',
    'Opportunity Name': '2023 CNMP Barry Nightingale - SW 2-27-36',
    'Account Name': 'Barry Nightingale',
    Field: 'SW 2-27-36',
    'Program ID': 'DV8778',
  },
  {
    'Opportunity ID': '0061K00000lbrFP',
    'Opportunity Name': '2023 CNMP Barry Nightingale - NW 15-27-36',
    'Account Name': 'Barry Nightingale',
    Field: 'NW 15-27-36',
    'Program ID': 'DV8780',
  },
  {
    'Opportunity ID': '0061K00000lbuR5',
    'Opportunity Name': '2023 CNMP Chandler Preston - Section 6 East 1/2',
    'Account Name': 'Chandler Preston',
    Field: 'Section 6 East 1/2',
    'Program ID': 'DV8800',
  },
  {
    'Opportunity ID': '0061K00000lbuTV',
    'Opportunity Name': '2023 CNMP Chandler Preston - Section 141 South 1/2',
    'Account Name': 'Chandler Preston',
    Field: 'Section 141 South 1/2',
    'Program ID': 'DV8809',
  },
  {
    'Opportunity ID': '0061K00000lbuNX',
    'Opportunity Name': '2023 SNMP Stan Spain - 326 N1/3',
    'Account Name': 'Stan Spain',
    Field: '326 N1/3',
    'Program ID': 'DV8791',
  },
  {
    'Opportunity ID': '0061K00000lbuRj',
    'Opportunity Name': '2023 CNMP Chandler Preston - Section 10 SW',
    'Account Name': 'Chandler Preston',
    Field: 'Section 10 SW',
    'Program ID': 'DV8803',
  },
  {
    'Opportunity ID': '0061K00000lbuRk',
    'Opportunity Name': '2023 CNMP Silver Sky Farms - Section 40 East 1/2',
    'Account Name': 'Silver Sky Farms LLC',
    Field: 'Section 40 East 1/2',
    'Program ID': 'DV8806',
  },
  {
    'Opportunity ID': '0061K00000lbuRl',
    'Opportunity Name': '2023 CNMP Chandler Preston - Section 451 NW',
    'Account Name': 'Chandler Preston',
    Field: 'Section 451 NW',
    'Program ID': 'DV8810',
  },
  {
    'Opportunity ID': '0061K00000lbuNO',
    'Opportunity Name': '2023 CNMP Silver Sky Farms LLC - Section 15 West 1/2',
    'Account Name': 'Silver Sky Farms LLC',
    Field: 'Section 15 West 1/2',
    'Program ID': 'DV8797',
  },
  {
    'Opportunity ID': '0061K00000lbuNP',
    'Opportunity Name': '2023 CNMP Chandler Preston - Section 11 South 1/2',
    'Account Name': 'Chandler Preston',
    Field: 'Section 11 South 1/2',
    'Program ID': 'DV8804',
  },
  {
    'Opportunity ID': '0061K00000lbuNQ',
    'Opportunity Name': '2023 CNMP Chandler Preston - Section 39 West 1/2',
    'Account Name': 'Chandler Preston',
    Field: 'Section 39 West 1/2',
    'Program ID': 'DV8805',
  },
  {
    'Opportunity ID': '0061K00000lbuOR',
    'Opportunity Name': '2023 CNMP Chandler Preston - Section 8 East 1/2',
    'Account Name': 'Chandler Preston',
    Field: 'Section 8 East 1/2',
    'Program ID': 'DV8801',
  },
  {
    'Opportunity ID': '0061K00000lbuNm',
    'Opportunity Name': '2023 CNMP Stan Spain - WC N Drip',
    'Account Name': 'Stan Spain',
    Field: 'WC N Drip',
    'Program ID': 'DV8792',
  },
  {
    'Opportunity ID': '0061K00000lbuNn',
    'Opportunity Name': '2023 CNMP Stan Spain - WC E 1/4',
    'Account Name': 'Stan Spain',
    Field: 'WC E 1/4',
    'Program ID': 'DV8793',
  },
  {
    'Opportunity ID': '0061K00000lbuNo',
    'Opportunity Name': '2023 CNMP Stan Spain - 441',
    'Account Name': 'Stan Spain',
    Field: '441',
    'Program ID': 'DV8794',
  },
  {
    'Opportunity ID': '0061K00000lbuQN',
    'Opportunity Name': '2023 CNMP Chandler Preston - Section 55',
    'Account Name': 'Chandler Preston',
    Field: 'Section 55',
    'Program ID': 'DV8807',
  },
  {
    'Opportunity ID': '0061K00000lc0pW',
    'Opportunity Name': '2023 CNMP McBryde L & C - NW/4 Sec. 246 - Circle #3',
    'Account Name': 'McBryde L & C',
    Field: 'NW/4 Sec. 246 - Circle #3',
    'Program ID': 'DV8833',
  },
  {
    'Opportunity ID': '0061K00000lc0pX',
    'Opportunity Name': '2023 CNMP McBryde L & C - NE/3 Sec. 250 - Oquin',
    'Account Name': 'McBryde L & C',
    Field: 'NE/3 Sec. 250 - Oquin',
    'Program ID': 'DV8835',
  },
  {
    'Opportunity ID': '0061K00000lc0qe',
    'Opportunity Name': '2023 CNMP McBryde L & C - W/3 Sec. 172',
    'Account Name': 'McBryde L & C',
    Field: 'W/3 Sec. 172',
    'Program ID': 'DV8838',
  },
  {
    'Opportunity ID': '0061K00000lc0qP',
    'Opportunity Name': '2023 CNMP McBryde L & C - NW/4 Sec. 240 - Haile W',
    'Account Name': 'McBryde L & C',
    Field: 'NW/4 Sec. 240 - Haile W',
    'Program ID': 'DV8837',
  },
  {
    'Opportunity ID': '0061K00000lc1Hu',
    'Opportunity Name': '2023 CNMP McBryde L & C - NE/4 Sec 79',
    'Account Name': 'McBryde L & C',
    Field: 'NE/4 Sec 79',
    'Program ID': 'DV8842',
  },
  {
    'Opportunity ID': '0061K00000lc0mZ',
    'Opportunity Name': '2023 CNMP McBryde L & C - NW/4 Sec. 233 -Circle #1',
    'Account Name': 'McBryde L & C',
    Field: 'NW/4 Sec. 233 -Circle #1',
    'Program ID': 'DV8830',
  },
  {
    'Opportunity ID': '0061K00000lc0rI',
    'Opportunity Name': "2023 CNMP McBryde L & C - S/3 Sec. 215 - Red Man's",
    'Account Name': 'McBryde L & C',
    Field: "S/3 Sec. 215 - Red Man's",
    'Program ID': 'DV8840',
  },
  {
    'Opportunity ID': '0061K00000lc2zG',
    'Opportunity Name': '2023 CNMP J&S Joint Ventures - 205 N',
    'Account Name': 'J & S Joint Ventures',
    Field: '205 N',
    'Program ID': 'DV8852',
  },
  {
    'Opportunity ID': '0061K00000lc2xy',
    'Opportunity Name': '2023 CNMP J&S Joint Ventures - 143 NE',
    'Account Name': 'J & S Joint Ventures',
    Field: '143 NE',
    'Program ID': 'DV8850',
  },
  {
    'Opportunity ID': '0061K00000lc2yw',
    'Opportunity Name': '2023 CNMP J&S Joint Ventures - 183 Early',
    'Account Name': 'J & S Joint Ventures',
    Field: '183 Early',
    'Program ID': 'DV8851',
  },
  {
    'Opportunity ID': '0061K00000lc2xA',
    'Opportunity Name': '2023 CNMP J&S Joint Ventures - 102 SE',
    'Account Name': 'J & S Joint Ventures',
    Field: '102 SE',
    'Program ID': 'DV8846',
  },
  {
    'Opportunity ID': '0061K00000lc2xK',
    'Opportunity Name': '2023 CNMP J&S Joint Ventures - 136 N',
    'Account Name': 'J & S Joint Ventures',
    Field: '136 N',
    'Program ID': 'DV8847',
  },
  {
    'Opportunity ID': '0061K00000lc2xL',
    'Opportunity Name': '2023 CNMP J&S Joint Ventures - 142 NW',
    'Account Name': 'J & S Joint Ventures',
    Field: '142 NW',
    'Program ID': 'DV8848',
  },
  {
    'Opportunity ID': '0061K00000lc2xM',
    'Opportunity Name': '2023 CNMP J&S Joint Ventures - 247 SW',
    'Account Name': 'J & S Joint Ventures',
    Field: '247 SW',
    'Program ID': 'DV8853',
  },
  {
    'Opportunity ID': '0061K00000lc2zf',
    'Opportunity Name': '2023 CNMP J&S Joint Ventures - 308 E',
    'Account Name': 'J & S Joint Ventures',
    Field: '308 E',
    'Program ID': 'DV8854',
  },
  {
    'Opportunity ID': '0061K00000lc2wl',
    'Opportunity Name': '2023 CNMP J&S Joint Ventures - 98 NE',
    'Account Name': 'J & S Joint Ventures',
    Field: '98 NE',
    'Program ID': 'DV8844',
  },
  {
    'Opportunity ID': '0061K00000lc2wm',
    'Opportunity Name': '2023 CNMP J&S Joint Ventures - 99 SE',
    'Account Name': 'J & S Joint Ventures',
    Field: '99 SE',
    'Program ID': 'DV8845',
  },
  {
    'Opportunity ID': '0061K00000lc2xe',
    'Opportunity Name': '2023 CNMP J&S Joint Ventures - 142 SE',
    'Account Name': 'J & S Joint Ventures',
    Field: '142 SE',
    'Program ID': 'DV8849',
  },
  {
    'Opportunity ID': '0061K00000lc4We',
    'Opportunity Name': '2023 CNMP Spurlock - Sec 37 SE 1/2',
    'Account Name': 'Spurlock Farms',
    Field: 'Sec 37 SE 1/2',
    'Program ID': 'DV8861',
  },
  {
    'Opportunity ID': '0061K00000lc3r6',
    'Opportunity Name': '2023 CNMP Russell Fangman - 204 N1/3',
    'Account Name': 'Fangman Farms',
    Field: '204 N1/3',
    'Program ID': 'DV8857',
  },
  {
    'Opportunity ID': '0061K00000lc4Wo',
    'Opportunity Name': '2023 CNMP Spurlock - Sec 34 NE',
    'Account Name': 'Spurlock Farms',
    Field: 'Sec 34 NE',
    'Program ID': 'DV8862',
  },
  {
    'Opportunity ID': '0061K00000lc7YG',
    'Opportunity Name': '2023 SNMP Tall Tex - Val NW 1/3',
    'Account Name': 'Tall Tex',
    Field: 'Val NW 1/3',
    'Program ID': 'DV8871',
  },
  {
    'Opportunity ID': '0061K00000lc7YB',
    'Opportunity Name': '2023 CNMP Tall Tex - Val NE 1/6',
    'Account Name': 'Tall Tex',
    Field: 'Val NE 1/6',
    'Program ID': 'DV8870',
  },
  {
    'Opportunity ID': '0061K00000lcIFt',
    'Opportunity Name': '2023 SNMP 97 Farms - 12',
    'Account Name': '97 Farms',
    Field: '12',
    'Program ID': 'DV8881',
  },
  {
    'Opportunity ID': '0061K00000lcIga',
    'Opportunity Name': '2023 CNMP Silver Sky Farms - Sec 438 W1/2',
    'Account Name': 'Silver Sky Farms LLC',
    Field: 'Sec 438 W1/2',
    'Program ID': 'DV8889',
  },
  {
    'Opportunity ID': '0061K00000lcIgb',
    'Opportunity Name': '2023 CNMP Silver Sky Farms - Sec 2 SW1/3',
    'Account Name': 'Silver Sky Farms LLC',
    Field: 'Sec 2 SW1/3',
    'Program ID': 'DV8890',
  },
  {
    'Opportunity ID': '0061K00000lcIGI',
    'Opportunity Name': '2023 CNMP Stan Spain - 343 NE1/2',
    'Account Name': 'Stan Spain',
    Field: '343 NE1/2',
    'Program ID': 'DV8882',
  },
  {
    'Opportunity ID': '0061K00000lcIfc',
    'Opportunity Name': '2023 SNMP Ronald Meyer - W1',
    'Account Name': 'Ronald Meyer',
    Field: 'W1',
    'Program ID': 'DV8886',
  },
  {
    'Opportunity ID': '0061K00000lcIfm',
    'Opportunity Name': '2023 SNMP Ronald Meyer - WM2',
    'Account Name': 'Ronald Meyer',
    Field: 'WM2',
    'Program ID': 'DV8887',
  },
  {
    'Opportunity ID': '0061K00000lcIfn',
    'Opportunity Name': '2023 SNMP Ronald Meyer - E2',
    'Account Name': 'Ronald Meyer',
    Field: 'E2',
    'Program ID': 'DV8888',
  },
  {
    'Opportunity ID': '0061K00000mN3aN',
    'Opportunity Name': '2023 CNMP John Reznik - 206',
    'Account Name': 'John Reznik',
    Field: '206',
    'Program ID': 'DV8915',
  },
  {
    'Opportunity ID': '0061K00000mN4ed',
    'Opportunity Name': '2023 CNMP Tom Schlabs - BSE 1/3',
    'Account Name': 'Tom Schlabs',
    Field: 'BSE 1/3',
    'Program ID': 'DV8916',
  },
  {
    'Opportunity ID': '0061K00000mN5vl',
    'Opportunity Name': '2023 CNMP Hasta Farms - 328 West E1/2 treated',
    'Account Name': 'Hasta Farms',
    Field: '328 West E1/2 treated',
    'Program ID': 'DV8917',
  },
  {
    'Opportunity ID': '0061K00000mNG3h',
    'Opportunity Name': '2023 CNMP Mitch Meyer - Diller Section W 1/2',
    'Account Name': 'Mitch Meyer',
    Field: 'Diller Section W 1/2',
    'Program ID': 'DV8918',
  },
  {
    'Opportunity ID': '0061K00000mNG3m',
    'Opportunity Name': '2023 CNMP Mitch Meyer - Diller 1/4 SW',
    'Account Name': 'Mitch Meyer',
    Field: 'Diller 1/4 SW',
    'Program ID': 'DV8919',
  },
  {
    'Opportunity ID': '0061K00000mNdUG',
    'Opportunity Name': '2023 SNMP Hasta Farms - Y-2',
    'Account Name': 'Hasta Farms',
    Field: 'Y-2',
    'Program ID': 'DV8977',
  },
  {
    'Opportunity ID': '0061K00000mNdUL',
    'Opportunity Name': '2023 SNMP Hasta Farms - Y-3',
    'Account Name': 'Hasta Farms',
    Field: 'Y-3',
    'Program ID': 'DV8978',
  },
  {
    'Opportunity ID': '0061K00000mNvMe',
    'Opportunity Name': '2023 SNMP Hasta Farms - 365 East',
    'Account Name': 'Hasta Farms',
    Field: '365 East',
    'Program ID': 'DV9025',
  },
  {
    'Opportunity ID': '0061K00000mNyur',
    'Opportunity Name': '2023 SNMP 97 Farms - 13 NW1/3',
    'Account Name': '97 Farms',
    Field: '13 NW 1/3',
    'Program ID': 'DV9031',
  },
  {
    'Opportunity ID': '0061K00000mNyuc',
    'Opportunity Name': '2023 SNMP 97 Farms - 11',
    'Account Name': '97 Farms',
    Field: '11',
    'Program ID': 'DV9030',
  },
  {
    'Opportunity ID': '0061K00000mO6QV',
    'Opportunity Name': '2023 SNMP Silver Sky Farms - Section 97',
    'Account Name': 'Silver Sky Farms LLC',
    Field: 'Section 97',
    'Program ID': 'DV9034',
  },
  {
    'Opportunity ID': '0061K00000mO6QQ',
    'Opportunity Name': '2023 SNMP Silver Sky Farms - Section 308 E',
    'Account Name': 'Silver Sky Farms LLC',
    Field: '308 E',
    'Program ID': 'DV9033',
  },
  {
    'Opportunity ID': '0061K00000mO6QR',
    'Opportunity Name': '2023 SNMP Chandler Preston - Section 6 West 1/2',
    'Account Name': 'Chandler Preston',
    Field: 'Section 6 West 1/2',
    'Program ID': 'DV9040',
  },
  {
    'Opportunity ID': '0061K00000mO6QS',
    'Opportunity Name': '2023 SNMP Chandler Preston - Section 8 West 1/2',
    'Account Name': 'Chandler Preston',
    Field: 'Section 8 West 1/2',
    'Program ID': 'DV9041',
  },
  {
    'Opportunity ID': '0061K00000mO6QT',
    'Opportunity Name': '2023 SNMP Chandler Preston - Section 11 North 1/2',
    'Account Name': 'Chandler Preston',
    Field: 'Section 11 North 1/2',
    'Program ID': 'DV9042',
  },
  {
    'Opportunity ID': '0061K00000mO6QU',
    'Opportunity Name': '2023 SNMP Chandler Preston - Section 39 East 1/2',
    'Account Name': 'Chandler Preston',
    Field: 'Section 39 East 1/2',
    'Program ID': 'DV9043',
  },
  {
    'Opportunity ID': '0061K00000mO6SC',
    'Opportunity Name': '2023 SNMP Spurlock - Sec 25 NW',
    'Account Name': 'Spurlock Farms',
    Field: 'Sec 25 NW',
    'Program ID': 'DV9052',
  },
  {
    'Opportunity ID': '0061K00000mO6SD',
    'Opportunity Name': '2023 SNMP Spurlock - Sec 31',
    'Account Name': 'Spurlock Farms',
    Field: 'Sec 31',
    'Program ID': 'DV9053',
  },
  {
    'Opportunity ID': '0061K00000mO6SE',
    'Opportunity Name': '2023 SNMP Spurlock - Sec 310 E 1/2',
    'Account Name': 'Spurlock Farms',
    Field: 'Sec 310 E 1/2',
    'Program ID': 'DV9057',
  },
  {
    'Opportunity ID': '0061K00000mO6QB',
    'Opportunity Name': '2023 SNMP Silver Sky Farms - Section 308 W',
    'Account Name': 'Silver Sky Farms LLC',
    Field: '308 W',
    'Program ID': 'DV9032',
  },
  {
    'Opportunity ID': '0061K00000mO6Qp',
    'Opportunity Name': '2023 SNMP Silver Sky Farms - Sec 438 East 1/2',
    'Account Name': 'Silver Sky Farms LLC',
    Field: 'Section 438 East 1/2',
    'Program ID': 'DV9037',
  },
  {
    'Opportunity ID': '0061K00000mO6Qq',
    'Opportunity Name': '2023 SNMP Chandler Preston - Section 92',
    'Account Name': 'Chandler Preston',
    Field: 'Section 92',
    'Program ID': 'DV9045',
  },
  {
    'Opportunity ID': '0061K00000mO6Qr',
    'Opportunity Name': '2023 SNMP Chandler Preston - Section 141 N 1/2',
    'Account Name': 'Chandler Preston',
    Field: 'Section 141 North 1/2',
    'Program ID': 'DV9046',
  },
  {
    'Opportunity ID': '0061K00000mO6Qs',
    'Opportunity Name': '2023 SNMP Chandler Preston - Section 451 SE',
    'Account Name': 'Chandler Preston',
    Field: 'Section 451 SE',
    'Program ID': 'DV9047',
  },
  {
    'Opportunity ID': '0061K00000mO6Rj',
    'Opportunity Name': '2023 SNMP Spurlock - Sec 35 Sunray Oil',
    'Account Name': 'Spurlock Farms',
    Field: 'Sec 35 Sunray Oil',
    'Program ID': 'DV9055',
  },
  {
    'Opportunity ID': '0061K00000mO6RT',
    'Opportunity Name': '2023 SNMP Chandler Preston - Section 9',
    'Account Name': 'Chandler Preston',
    Field: 'Section 9',
    'Program ID': 'DV9039',
  },
  {
    'Opportunity ID': '0061K00000mO6Qk',
    'Opportunity Name': '2023 SNMP Silver Sky Farms - Sec 436 North 1/2',
    'Account Name': 'Silver Sky Farms LLC',
    Field: 'Section 436 North 1/2',
    'Program ID': 'DV9036',
  },
  {
    'Opportunity ID': '0061K00000mO6Qg',
    'Opportunity Name': '2023 SNMP Silver Sky Farms - Section 40 West 1/2',
    'Account Name': 'Silver Sky Farms LLC',
    Field: 'Section 40 West 1/2',
    'Program ID': 'DV9044',
  },
  {
    'Opportunity ID': '0061K00000mO6Rn',
    'Opportunity Name': '2023 SNMP Sid Watkins - J1',
    'Account Name': 'Eric Riffe',
    Field: 'J1',
    'Program ID': 'DV9049',
  },
  {
    'Opportunity ID': '0061K00000mO6S2',
    'Opportunity Name': '2023 SNMP Spurlock - Sec 22',
    'Account Name': 'Spurlock Farms',
    Field: 'Sec 22',
    'Program ID': 'DV9050',
  },
  {
    'Opportunity ID': '0061K00000mO6S3',
    'Opportunity Name': '2023 SNMP Spurlock - Sec 25 NE',
    'Account Name': 'Spurlock Farms',
    Field: 'Sec 25 NE',
    'Program ID': 'DV9051',
  },
  {
    'Opportunity ID': '0061K00000mO6S4',
    'Opportunity Name': '2023 SNMP Spurlock - Sec 28 West 1/2',
    'Account Name': 'Spurlock Farms',
    Field: 'Sec 28 West 1/2',
    'Program ID': 'DV9054',
  },
  {
    'Opportunity ID': '0061K00000mO6S5',
    'Opportunity Name': '2023 SNMP Spurlock - Sec 309',
    'Account Name': 'Spurlock Farms',
    Field: 'Sec 309',
    'Program ID': 'DV9056',
  },
  {
    'Opportunity ID': '0061K00000mOBme',
    'Opportunity Name': '2023 CNMP Richard Schlabs - E 1/2 S',
    'Account Name': 'Richard Schlabs',
    Field: 'E 1/2 S',
    'Program ID': 'DV9067',
  },
  {
    'Opportunity ID': '0061K00000mOGsw',
    'Opportunity Name': '2023 SNMP 6A Farms - H1 E1/2',
    'Account Name': '6A Farms',
    Field: 'H1 E1/2',
    'Program ID': 'DV9087',
  },
  {
    'Opportunity ID': '0061K00000mOGsm',
    'Opportunity Name': '2023 SNMP 6A Farms - D3 NW1/3',
    'Account Name': '6A Farms',
    Field: 'D3 NW 1/3',
    'Program ID': 'DV9086',
  },
  {
    'Opportunity ID': '0061K00000lc4X8',
    'Opportunity Name': '2023 CNMP Spurlock - LS 27 N',
    'Account Name': 'Spurlock Farms',
    Field: 'LS 27 N',
    'Program ID': 'DV8863',
  },
  {
    'Opportunity ID': '0061K00000lbuOB',
    'Opportunity Name': '2023 CNMP Silver Sky Farms LLC - Section 436 South 1/2',
    'Account Name': 'Silver Sky Farms LLC',
    Field: 'Section 436 South 1/2',
    'Program ID': 'DV8795',
  },
  {
    'Opportunity ID': '0061K00000lblBW',
    'Opportunity Name': '2023 CNMP R-Way Farms - 12',
    'Account Name': 'R-Way Farms',
    Field: '12',
    'Program ID': 'DV8684',
  },
  {
    'Opportunity ID': '0061K00000lblDh',
    'Opportunity Name': '2023 CNMP Brent Carlson - Section 11 NE',
    'Account Name': 'Brent Carlson',
    Field: 'Section 11 NE',
    'Program ID': 'DV8694',
  },
];

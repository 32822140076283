export const MASTER_COLUMNS = {
  PROGRAM_ID: 'Program ID',
  OPPORTUNITY_ID: 'Opportunity ID',
  OPPORTUNITY_NAME: 'Opportunity Name',
  ACCOUNT_NAME: 'Account Name',
  FIELD: 'Field',
};

export const SOURCE_COLUMNS = {
  SAMPLE_ID: 'Sample ID',
  DATE_RECEIVED: 'Date Received',
  RECEIVED_DATE: 'Received Date',
  NO3_N_LBS_AC: 'NO3-N (lb/A)',
  AMMONIUM_N_PPM: 'Ammonium-N (ppm)',
  ORGANIC_MATTER: 'Organic Matter',
  NITRATE_NITROGEN_MG_KG: 'Nitrate-Nitrogen (mg/kg)',
  TOTAL_NITROGEN: 'Total Nitrogen (%)',
  PHOSPHORUS: 'Phosphorus (%)',
  POTASSIUM: 'Potassium (%)',
  SULFUR: 'Sulfur (%)',
  N_S_RATIO: 'Nitrogen:Sulfur Ratio',
};

// TODO: Add a mapping for result columns so they can be modified and retrieved programatically
// Example:
// 'result column': {
//    sourceColumns: string[],
//    testTypes: string[],
//    testTimings: string[]
// }

import React from 'react';
import Banner from '../../../components/Banner';
import Modal from '../../../components/Modal';
import Toggle from '../../../components/Toggle';
import { MIXPANEL_EVENTS } from '../../../constants/analyticsConstants';
import Analytics from '../../../helpers/analytics';
import ErrorTable from '../FileSelect/ErrorTable';

const InvalidSamplesModal = ({
  rawDataFromStore,
  errorToggle,
  setErrorToggle,
  bannerText,
  errorModalBanner,
  handleInvalidSamplesBanner,
  navigateTo,
  handleErrorModal,
  buttonText,
}) => {
  return (
    <Modal
      title={`Invalid samples (${bannerText?.errorSpotted} ${
        bannerText?.errorSpotted > 1 ? 'files' : 'file'
      })`}
      footer={true}
      buttonText={buttonText}
      onClose={() => handleErrorModal()}
      footerBtnClick={
        bannerText?.totalErrorSamples === bannerText?.totalSamples ? handleErrorModal : navigateTo
      }
    >
      <Banner
        type={bannerText?.totalErrorSamples === bannerText?.totalSamples ? 'error' : 'warning'}
        visible={errorModalBanner}
        handleBannerVisibility={handleInvalidSamplesBanner}
      >
        {bannerText?.totalErrorSamples === bannerText?.totalSamples ? (
          <span className="text-red-900 ">
            Unable to translate as all samples are invalid. Please upload a file with at least one
            valid sample.
          </span>
        ) : (
          <span className="text-yellow-900 ">
            The following samples have data the wrong format and will be removed
          </span>
        )}
      </Banner>
      <div className="px-4 py-8 flex items-center gap-x-6">
        <span className="text-red-600 text-base font-semibold">
          Show columns with errors first{' '}
        </span>
        <div className="self-center">
          <Toggle
            checked={errorToggle}
            handleToggle={() => {
              Analytics.trackEvent(MIXPANEL_EVENTS.ERROR_TOGGLE, {
                state: !errorToggle,
              });
              setErrorToggle(!errorToggle);
            }}
          />
        </div>
      </div>
      {rawDataFromStore?.map((el, key) => {
        const formattedData = el.rows?.filter((data) => data.isInvalid);
        return formattedData.length !== 0 ? (
          <ErrorTable
            key={key}
            bordered={true}
            dataSource={formattedData}
            reorderColumn={errorToggle}
            fileName={el.fileName}
          />
        ) : (
          ''
        );
      })}
    </Modal>
  );
};

export default InvalidSamplesModal;

/**
 * All Icons types listed here
 * Provided with default size and color.
 * Customization can be done using props
 * * height, width, color
 *
 * ArrowRight
 * Bin
 * Check
 * ChevronRight
 * ChevronLeft
 * Close
 * DeveronLogo
 * Download
 * Error
 * File
 * Preview
 * Refresh
 * TranslatorLogo
 * Upload
 * Warning
 */

export const ArrowRight = ({ height = 11, width = 14, color = 'white' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.86258 0.36201C8.12293 0.101661 8.54504 0.101661 8.80539 0.36201L13.4721 5.02868C13.7324 5.28903 13.7324 5.71114 13.4721 5.97149L8.80539 10.6382C8.54504 10.8985 8.12293 10.8985 7.86258 10.6382C7.60223 10.3778 7.60223 9.95569 7.86258 9.69534L11.3912 6.16675H1.00065C0.632461 6.16675 0.333984 5.86827 0.333984 5.50008C0.333984 5.13189 0.632461 4.83342 1.00065 4.83342H11.3912L7.86258 1.30482C7.60223 1.04447 7.60223 0.62236 7.86258 0.36201Z"
        fill={color}
      />
    </svg>
  );
};

export const Bin = ({ height = 16, width = 14, color = 'white' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 2C4 1.17157 4.67157 0.5 5.5 0.5H8.5C9.32843 0.5 10 1.17157 10 2V3.5H12.2423C12.2469 3.49996 12.2515 3.49996 12.2562 3.5H13C13.4142 3.5 13.75 3.83579 13.75 4.25C13.75 4.66421 13.4142 5 13 5H12.9483L12.3476 13.4103C12.2635 14.5877 11.2838 15.5 10.1033 15.5H3.89668C2.71624 15.5 1.7365 14.5877 1.6524 13.4103L1.05166 5H1C0.585786 5 0.25 4.66421 0.25 4.25C0.25 3.83579 0.585786 3.5 1 3.5H1.74381C1.74846 3.49996 1.75311 3.49996 1.75774 3.5H4V2ZM2.55548 5L3.14858 13.3034C3.17662 13.6959 3.5032 14 3.89668 14H10.1033C10.4968 14 10.8234 13.6959 10.8514 13.3034L11.4445 5H2.55548ZM8.5 3.5H5.5V2H8.5V3.5ZM5.5 6.5C5.91421 6.5 6.25 6.83579 6.25 7.25V11.75C6.25 12.1642 5.91421 12.5 5.5 12.5C5.08579 12.5 4.75 12.1642 4.75 11.75V7.25C4.75 6.83579 5.08579 6.5 5.5 6.5ZM8.5 6.5C8.91421 6.5 9.25 6.83579 9.25 7.25V11.75C9.25 12.1642 8.91421 12.5 8.5 12.5C8.08579 12.5 7.75 12.1642 7.75 11.75V7.25C7.75 6.83579 8.08579 6.5 8.5 6.5Z"
        fill={color}
      />
    </svg>
  );
};

export const Check = ({ height = 14, width = 14, color = 'white' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.1059 1H3.6017C2.16482 1 1 2.19391 1 3.66667V10.3333C1 11.8061 2.16482 13 3.6017 13H10.1059C11.5428 13 12.7076 11.8061 12.7076 10.3333V3.66667C12.7076 2.19391 11.5428 1 10.1059 1Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 7.33333L6.46347 8.66666L8.90259 6"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const ChevronLeft = ({ height = 11, width = 7, color = '#006ACB' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 7 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.47075 0.361766C6.7311 0.622116 6.7311 1.04423 6.47075 1.30458L2.27549 5.49984L6.47075 9.6951C6.7311 9.95545 6.7311 10.3776 6.47075 10.6379C6.2104 10.8983 5.78829 10.8983 5.52794 10.6379L0.861278 5.97124C0.600928 5.71089 0.600928 5.28878 0.861278 5.02843L5.52794 0.361766C5.78829 0.101417 6.2104 0.101417 6.47075 0.361766Z"
        fill={color}
      />
    </svg>
  );
};

export const ChevronRight = ({ height = 11, width = 7, color = '#006ACB' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 7 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.529247 0.361766C0.789596 0.101417 1.21171 0.101417 1.47206 0.361766L6.13872 5.02843C6.39907 5.28878 6.39907 5.71089 6.13872 5.97124L1.47206 10.6379C1.21171 10.8983 0.789596 10.8983 0.529247 10.6379C0.268897 10.3776 0.268897 9.95545 0.529247 9.6951L4.72451 5.49984L0.529247 1.30458C0.268897 1.04423 0.268897 0.622116 0.529247 0.361766Z"
        fill={color}
      />
    </svg>
  );
};

export const Close = ({ height = 14, width = 14, color = '#677D8B' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L7 5.58579L12.2929 0.292893C12.6834 -0.0976311 13.3166 -0.0976311 13.7071 0.292893C14.0976 0.683417 14.0976 1.31658 13.7071 1.70711L8.41421 7L13.7071 12.2929C14.0976 12.6834 14.0976 13.3166 13.7071 13.7071C13.3166 14.0976 12.6834 14.0976 12.2929 13.7071L7 8.41421L1.70711 13.7071C1.31658 14.0976 0.683417 14.0976 0.292893 13.7071C-0.0976311 13.3166 -0.0976311 12.6834 0.292893 12.2929L5.58579 7L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z"
        fill={color}
      />
    </svg>
  );
};

//Pre defined logo, can't customize color
export const DeveronLogo = ({ height = 25, width = 159 }) => {
  return (
    <svg
      data-testid="deveron-logo"
      width={width}
      height={height}
      viewBox="0 0 159 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M155.346 20.0899V0.415443H158.015V24.063C157.194 24.063 156.39 24.0752 155.588 24.0511C155.446 24.0471 155.27 23.9198 155.175 23.7986C150.421 17.7311 145.674 11.6578 140.925 5.58549C140.7 5.29783 140.473 5.01152 140.171 4.6287V24.0243H137.535V0.374613C138.358 0.374613 139.194 0.350443 140.026 0.394813C140.195 0.404093 140.375 0.651653 140.507 0.822913C142.627 3.56864 144.74 6.31919 146.853 9.06925C149.564 12.5975 152.275 16.1257 154.987 19.653C155.07 19.7615 155.161 19.8647 155.346 20.0899H155.346Z"
        fill="white"
      />
      <path
        d="M26.0862 10.804H36.9118V12.7808H26.0769V22.0262H38.2296V24.0193H23.3633V0.334865C23.5503 0.326505 23.7207 0.312035 23.8911 0.312035C28.4889 0.310695 33.0868 0.317715 37.6842 0.301045C38.1422 0.299335 38.3253 0.407305 38.2722 0.882405C38.2204 1.34701 38.3986 1.98672 38.1545 2.23709C37.9143 2.48251 37.2701 2.35568 36.8034 2.35647C33.4517 2.36312 30.1 2.36001 26.7483 2.36001H26.0862V10.804Z"
        fill="white"
      />
      <path
        d="M77.0831 10.8067H87.9404V12.7937H77.0748V22.0491H89.2261V24.0316H74.3594V0.417969H89.234V2.3504H77.0831L77.0831 10.8067Z"
        fill="white"
      />
      <path
        d="M42.2227 24.0797C42.2635 23.9823 42.2723 23.9472 42.2912 23.9186C43.1817 22.5899 43.1817 22.5899 44.7914 22.5899C52.6017 22.5899 60.4121 22.5952 68.222 22.578C68.7305 22.5767 69.0844 22.6843 69.3369 23.1396C69.5112 23.4536 69.7487 23.7324 69.9951 24.0797L42.2227 24.0797Z"
        fill="#8ED13F"
      />
      <path
        d="M43.9277 21.6845C44.2803 21.2028 44.5741 20.7734 44.9038 20.3729C44.9816 20.2789 45.1805 20.2482 45.3236 20.2482C52.5016 20.2425 59.6793 20.2408 66.8573 20.2552C67.0421 20.2557 67.2744 20.3949 67.4026 20.5411C67.6982 20.8784 67.9432 21.2595 68.2536 21.6845L43.9277 21.6845Z"
        fill="#8ED13F"
      />
      <path
        d="M54.2362 17.826C54.3565 17.9521 54.5067 18.0605 54.5919 18.2071C55.0358 18.9722 55.6743 19.2176 56.5564 19.1535C57.1062 19.1139 57.5242 19.0195 57.8434 18.555C58.02 18.2972 58.2571 18.0807 58.467 17.8459C60.6432 17.845 62.8198 17.8524 64.996 17.834C65.3381 17.8309 65.5445 17.9327 65.7214 18.2177C65.9396 18.569 66.2053 18.8909 66.4999 19.2936H45.6855C46.0188 18.8351 46.3135 18.3873 46.659 17.9828C46.7552 17.87 47.002 17.8494 47.1803 17.8485C49.5321 17.8344 51.8839 17.8314 54.2362 17.826Z"
        fill="#8ED13F"
      />
      <path
        d="M52.294 15.514C52.7086 16.002 53.1226 16.4893 53.5371 16.9767C51.5256 16.9767 49.5141 16.9767 47.377 16.9767C47.7489 16.4708 48.0492 16.0322 48.3882 15.6246C48.4633 15.535 48.6736 15.5338 48.8225 15.5325C49.9795 15.5205 51.137 15.5189 52.294 15.514L52.294 15.514Z"
        fill="#8ED13F"
      />
      <path
        d="M60.3706 15.5487C60.624 15.5386 60.8774 15.5285 61.1307 15.5179C61.85 15.522 62.5701 15.5448 63.2885 15.5206C63.6253 15.5088 63.8409 15.6027 64.0192 15.8921C64.2348 16.2425 64.4982 16.5635 64.7902 16.9648C62.8573 16.9648 61.0236 16.9648 59.1895 16.9648C59.5833 16.4928 59.9768 16.0208 60.3706 15.5487Z"
        fill="#8ED13F"
      />
      <path d="M56.2906 12.9133L55.9863 12.692L56.2906 12.9133Z" fill="white" />
      <path
        d="M42.9279 0.704652L42.8238 0.493042L42.8203 0.496522L42.9235 0.708192L42.9279 0.704652Z"
        fill="white"
      />
      <path d="M42.8242 0.492431L42.793 0.477051L42.8206 0.495911L42.8242 0.492431Z" fill="white" />
      <path d="M56.4097 12.9214L56.2969 12.917L56.4097 12.9214Z" fill="white" />
      <path
        d="M40.6094 1.44153L40.7363 1.52448C40.7073 1.54999 40.6783 1.57544 40.6489 1.60132C40.6357 1.54779 40.623 1.49426 40.6098 1.44104L40.6094 1.44153Z"
        fill="white"
      />
      <path
        d="M40.6111 1.44055L40.5246 1.31494L40.5176 1.3233L40.6107 1.44104L40.6111 1.44055Z"
        fill="white"
      />
      <path
        d="M40.5238 1.31496L40.457 1.31057C40.4641 1.30923 40.4715 1.30569 40.4772 1.30752C40.4908 1.31137 40.5031 1.31845 40.5163 1.32376L40.5238 1.31496Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.4475 7.25575C17.5905 2.62404 13.6842 0.720112 8.94366 0.422862C6.111 0.245372 3.25902 0.377572 0.41583 0.373182C0.28278 0.373182 0.14973 0.398692 0 0.413592V24.0633H0.62265C2.92223 24.0633 5.22226 24.1002 7.52053 24.0484C8.73992 24.0208 9.97556 23.9408 11.169 23.7063C13.8603 23.1786 16.231 21.9696 17.9106 19.7561C20.828 15.9118 21.1973 11.6204 19.4475 7.25575ZM16.8923 16.7118C15.7006 19.5379 13.4598 21.0765 10.5275 21.7387C8.95376 22.0939 7.36069 22.0588 5.76674 22.0553C4.7669 22.0531 3.7675 22.0548 2.7097 22.0548V2.45936C2.74088 2.43525 2.7681 2.39576 2.79444 2.39662C5.36539 2.4568 7.94688 2.15864 10.5034 2.68684C14.4869 3.50929 16.9446 6.07454 17.5584 10.127C17.8987 12.3748 17.7841 14.5958 16.8923 16.7118L16.8923 16.7118Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M105.887 12.739C107.108 12.123 108.144 11.3673 108.969 10.3328C110.995 7.79215 110.656 4.14756 108.155 2.16807C106.568 0.912205 104.67 0.457735 102.702 0.409825C99.7208 0.336585 96.7367 0.378755 93.7534 0.372105C93.6164 0.372105 93.4789 0.372105 93.3086 0.372105V24.0293H95.9959V13.2107H96.5694C98.6749 13.2107 100.781 13.2212 102.887 13.201C103.276 13.1975 103.469 13.3186 103.638 13.6705C105.247 17.0045 106.876 20.3285 108.505 23.6534C108.576 23.7997 108.694 24.0342 108.797 24.0381C109.659 24.0688 110.522 24.0556 111.469 24.0556C109.577 20.2209 107.736 16.4877 105.887 12.7391L105.887 12.739ZM104.439 11.1964C101.652 11.2035 98.8659 11.2018 96.0126 11.2018V2.38058C96.3929 2.38058 96.7556 2.38009 97.1187 2.38058C98.6973 2.3815 100.281 2.3016 101.852 2.41091C102.969 2.48867 104.124 2.67519 105.167 3.06692C108.228 4.21603 108.091 8.13241 106.5 9.7264C105.997 10.23 105.395 10.6362 104.828 11.0726C104.726 11.1517 104.57 11.1961 104.439 11.1964V11.1964Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M134.549 9.18689C134.025 6.55444 132.82 4.27594 130.718 2.55597C127.963 0.301637 124.771 -0.362363 121.304 0.177367C117.866 0.712647 115.282 2.52435 113.748 5.63629C111.854 9.47888 111.783 13.4795 113.171 17.493C114.116 20.2264 115.9 22.2819 118.579 23.4665C120.923 24.5028 123.37 24.6596 125.871 24.1634C128.657 23.611 130.904 22.2098 132.564 19.9041C134.208 17.6211 134.83 15.0261 134.927 12.2474C134.805 11.2265 134.749 10.1924 134.549 9.18688V9.18689ZM131.591 16.3166C130.366 20.3822 127.168 22.5699 122.811 22.3881C119.061 22.2322 116.29 19.8694 115.408 15.9794C115.128 14.7433 115.034 13.4655 114.855 12.2066C114.994 9.9461 115.298 7.76287 116.454 5.78466C117.812 3.46099 119.876 2.25957 122.543 2.05846C124.898 1.88152 127.069 2.3759 128.918 3.91149C130.58 5.29204 131.453 7.1314 131.86 9.21148C132.328 11.5984 132.294 13.9819 131.591 16.3166L131.591 16.3166Z"
        fill="white"
      />
      <path
        d="M56.2284 12.9203C59.3227 10.9644 62.4178 9.0099 65.5109 7.05216C67.7018 5.6652 69.889 4.27356 72.0787 2.88563C72.2155 2.7988 72.3602 2.72483 72.5014 2.64491L72.59 2.75582C71.0583 4.34697 69.5262 5.93755 67.9945 7.52821C66.1694 9.40559 64.341 11.2801 62.5205 13.1622C61.6716 14.04 60.8376 14.9329 59.9969 15.8187C59.5711 16.2621 59.1457 16.7056 58.7198 17.149C58.4589 17.4247 58.1979 17.701 57.9374 17.9768C57.7117 18.1965 57.46 18.396 57.2659 18.6408C56.9151 19.0819 56.4915 19.147 55.9403 19.148C55.0558 19.1505 54.4361 18.861 54.0466 18.067C53.9718 17.9148 53.8296 17.7961 53.7184 17.662C53.5055 17.3636 53.2926 17.0653 53.0802 16.7669C52.7007 16.2518 52.3216 15.7367 51.9421 15.2211C50.417 13.1112 48.8933 10.9997 47.3654 8.89137C45.3446 6.10241 43.3201 3.31627 41.2974 0.528923C41.3285 0.505159 41.3592 0.481732 41.3899 0.458366C42.8918 1.72325 44.3937 2.98812 45.8961 4.25261C45.9892 4.33092 46.0829 4.40829 46.1764 4.48615C48.1882 6.20268 50.197 7.92339 52.213 9.63487C53.3731 10.6197 54.5457 11.5895 55.7126 12.566L55.8234 12.6627L56.1115 12.9046L56.1161 12.908L56.2284 12.9203Z"
        fill="#94969A"
      />
      <path
        d="M56.4074 12.9205C59.3578 10.7536 62.309 8.58795 65.258 6.41919C67.3468 4.88281 69.4317 3.34198 71.5192 1.80469C71.6496 1.7085 71.7888 1.62464 71.924 1.53504L72.0202 1.63953C70.6032 3.33362 69.1858 5.02723 67.7688 6.72083C66.0791 8.72095 64.3859 10.7184 62.7011 12.723C61.9155 13.6578 61.1458 14.6067 60.369 15.549C59.9752 16.0211 59.5817 16.4931 59.1878 16.9652C58.9468 17.2584 58.7057 17.5523 58.4651 17.8455C58.2552 18.0804 58.0181 18.2969 57.8415 18.5547C57.5223 19.0193 57.1043 19.1137 56.5545 19.1532C55.6724 19.2174 55.0339 18.9719 54.59 18.207C54.5048 18.0602 54.3546 17.9518 54.2343 17.8257C54.0011 17.543 53.768 17.2603 53.5353 16.9774C53.1208 16.49 52.7067 16.0026 52.2921 15.5148C50.6236 13.5164 48.9563 11.5164 47.2851 9.51971C45.0746 6.87847 42.8607 4.24035 40.6484 1.60089C40.6779 1.57501 40.7068 1.5495 40.7358 1.52405C42.3223 2.68109 43.9088 3.83814 45.4957 4.99475C45.5941 5.06634 45.6929 5.13702 45.7917 5.20813C47.9182 6.78015 50.0422 8.35657 52.1727 9.92322C53.3987 10.8247 54.6361 11.7104 55.8682 12.6031L55.9854 12.6918L56.2898 12.9131L56.2946 12.9161L56.4074 12.9205Z"
        fill="#369BFF"
      />
    </svg>
  );
};

export const Download = ({ height = 13, width = 12, color = '#fff' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 0.5C6.36819 0.5 6.66667 0.798477 6.66667 1.16667V7.55719L8.19526 6.0286C8.45561 5.76825 8.87772 5.76825 9.13807 6.0286C9.39842 6.28895 9.39842 6.71106 9.13807 6.9714L6.4714 9.63807C6.21106 9.89842 5.78895 9.89842 5.5286 9.63807L2.86193 6.9714C2.60158 6.71106 2.60158 6.28895 2.86193 6.0286C3.12228 5.76825 3.54439 5.76825 3.80474 6.0286L5.33333 7.55719V1.16667C5.33333 0.798477 5.63181 0.5 6 0.5ZM0.666667 9.16667C1.03486 9.16667 1.33333 9.46514 1.33333 9.83333C1.33333 10.187 1.47381 10.5261 1.72386 10.7761C1.97391 11.0262 2.31304 11.1667 2.66667 11.1667H9.33333C9.68696 11.1667 10.0261 11.0262 10.2761 10.7761C10.5262 10.5261 10.6667 10.187 10.6667 9.83333C10.6667 9.46514 10.9651 9.16667 11.3333 9.16667C11.7015 9.16667 12 9.46514 12 9.83333C12 10.5406 11.719 11.2189 11.219 11.719C10.7189 12.219 10.0406 12.5 9.33333 12.5H2.66667C1.95942 12.5 1.28115 12.219 0.781049 11.719C0.280952 11.2189 0 10.5406 0 9.83333C0 9.46514 0.298477 9.16667 0.666667 9.16667Z"
        fill={color}
      />
    </svg>
  );
};

export const Error = ({ height = 19.2, width = 19.2, color = '#DC2626' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3996 6.49961C13.3996 8.19699 12.7253 9.82486 11.5251 11.0251C10.3249 12.2253 8.69699 12.8996 6.99961 12.8996C5.30222 12.8996 3.67436 12.2253 2.47413 11.0251C1.27389 9.82486 0.599609 8.19699 0.599609 6.49961C0.599609 4.80222 1.27389 3.17436 2.47413 1.97413C3.67436 0.773893 5.30222 0.0996094 6.99961 0.0996094C8.69699 0.0996094 10.3249 0.773893 11.5251 1.97413C12.7253 3.17436 13.3996 4.80222 13.3996 6.49961ZM7.79961 9.69961C7.79961 9.91178 7.71532 10.1153 7.56529 10.2653C7.41527 10.4153 7.21178 10.4996 6.99961 10.4996C6.78744 10.4996 6.58395 10.4153 6.43392 10.2653C6.2839 10.1153 6.19961 9.91178 6.19961 9.69961C6.19961 9.48744 6.2839 9.28395 6.43392 9.13392C6.58395 8.9839 6.78744 8.89961 6.99961 8.89961C7.21178 8.89961 7.41527 8.9839 7.56529 9.13392C7.71532 9.28395 7.79961 9.48744 7.79961 9.69961ZM6.99961 2.49961C6.78744 2.49961 6.58395 2.58389 6.43392 2.73392C6.2839 2.88395 6.19961 3.08744 6.19961 3.29961V6.49961C6.19961 6.71178 6.2839 6.91527 6.43392 7.06529C6.58395 7.21532 6.78744 7.29961 6.99961 7.29961C7.21178 7.29961 7.41527 7.21532 7.56529 7.06529C7.71532 6.91527 7.79961 6.71178 7.79961 6.49961V3.29961C7.79961 3.08744 7.71532 2.88395 7.56529 2.73392C7.41527 2.58389 7.21178 2.49961 6.99961 2.49961Z"
        fill={color}
      />
    </svg>
  );
};

export const File = ({ height = 24, width = 24 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.75 2.25C0.75 1.42157 1.42157 0.75 2.25 0.75H21.75C22.5784 0.75 23.25 1.42157 23.25 2.25V21.75C23.25 22.5784 22.5784 23.25 21.75 23.25H2.25C1.42157 23.25 0.75 22.5784 0.75 21.75V2.25Z"
        stroke="#1E356A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.25 17.25L8.712 12.634C8.97285 12.286 9.37191 12.0683 9.80575 12.0374C10.2396 12.0065 10.6655 12.1654 10.973 12.473L12 13.5L15.3 9.1C15.5833 8.72229 16.0279 8.5 16.5 8.5C16.9721 8.5 17.4167 8.72229 17.7 9.1L20.37 12.66"
        stroke="#1E356A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.375 8.25C7.41053 8.25 8.25 7.41053 8.25 6.375C8.25 5.33947 7.41053 4.5 6.375 4.5C5.33947 4.5 4.5 5.33947 4.5 6.375C4.5 7.41053 5.33947 8.25 6.375 8.25Z"
        stroke="#1E356A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.75 17.25H23.25"
        stroke="#1E356A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const Preview = ({ height = 16, width = 16, color = '#2E97FF', opacity = 1 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00079 2.40039C4.41839 2.40039 1.38639 4.75479 0.367188 8.00039C1.38639 11.246 4.41839 13.6004 8.00079 13.6004C11.5832 13.6004 14.6152 11.246 15.6344 8.00039C14.6152 4.75479 11.5832 2.40039 8.00079 2.40039ZM10.2635 10.2631C10.8636 9.66302 11.2008 8.84908 11.2008 8.00039C11.2008 7.1517 10.8636 6.33777 10.2635 5.73765C9.66341 5.13753 8.84948 4.80039 8.00079 4.80039C7.15209 4.80039 6.33816 5.13753 5.73805 5.73765C5.13793 6.33777 4.80079 7.1517 4.80079 8.00039C4.80079 8.84908 5.13793 9.66302 5.73805 10.2631C6.33816 10.8632 7.15209 11.2004 8.00079 11.2004C8.84948 11.2004 9.66341 10.8632 10.2635 10.2631ZM9.13219 9.13176C8.83213 9.43182 8.42516 9.60039 8.00082 9.60039C7.57647 9.60039 7.16951 9.43182 6.86945 9.13176C6.56939 8.8317 6.40082 8.42474 6.40082 8.00039C6.40082 7.57604 6.56939 7.16908 6.86945 6.86902C7.16951 6.56896 7.57647 6.40039 8.00082 6.40039C8.42516 6.40039 8.83213 6.56896 9.13219 6.86902C9.43225 7.16908 9.60082 7.57604 9.60082 8.00039C9.60082 8.42474 9.43225 8.8317 9.13219 9.13176Z"
        fill={color}
        opacity={opacity}
      />
    </svg>
  );
};

export const Refresh = ({ height = 13, width = 12, color = '#2E97FF' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.97385 1.31757C4.12084 0.647798 5.45764 0.377501 6.77475 0.549044C8.09185 0.720587 9.31483 1.32427 10.252 2.2655C11.1892 3.20672 11.7876 4.43228 11.9534 5.75012C11.9994 6.11542 11.7406 6.44884 11.3753 6.49482C11.0099 6.54079 10.6765 6.28193 10.6306 5.91662C10.5015 4.8916 10.0361 3.93836 9.30716 3.20627C8.57822 2.47419 7.62699 2.00464 6.60255 1.87121C5.5781 1.73778 4.53833 1.94802 3.64619 2.46898C3.05686 2.81311 2.55395 3.27999 2.16882 3.83337H4C4.36819 3.83337 4.66667 4.13184 4.66667 4.50003C4.66667 4.86822 4.36819 5.1667 4 5.1667H0.666667C0.298477 5.1667 0 4.86822 0 4.50003V1.1667C0 0.813506 0.27546 0.521605 0.628062 0.501153C0.980664 0.4807 1.28803 0.738796 1.32886 1.08962L1.49688 2.53308C1.91822 2.05475 2.41591 1.64338 2.97385 1.31757ZM0.624272 6.50531C0.989547 6.45907 1.32315 6.7177 1.36939 7.08297C1.4991 8.10762 1.96493 9.06033 2.69394 9.79196C3.42295 10.5236 4.37398 10.9928 5.39816 11.1262C6.42234 11.2596 7.46184 11.0496 8.35392 10.5291C8.94286 10.1854 9.44561 9.71928 9.83087 9.1667H8C7.63181 9.1667 7.33333 8.86822 7.33333 8.50003C7.33333 8.13184 7.63181 7.83337 8 7.83337H11.3333C11.7015 7.83337 12 8.13184 12 8.50003V11.8334C12 12.1866 11.7245 12.4785 11.3719 12.4989C11.0192 12.5193 10.7119 12.2612 10.6711 11.9103L10.5033 10.4658C10.0817 10.9439 9.5839 11.3551 9.02586 11.6807C7.87901 12.3499 6.54265 12.6198 5.22597 12.4484C3.9093 12.2769 2.68665 11.6736 1.74944 10.7331C0.812233 9.79251 0.213371 8.56771 0.0466118 7.25043C0.000370383 6.88515 0.258998 6.55155 0.624272 6.50531Z"
        fill={color}
      />
    </svg>
  );
};

export const TranslatorLogo = ({ height = 20, width = 30, color = '#369BFF' }) => {
  return (
    <svg
      width={height}
      height={width}
      viewBox="0 0 30 20"
      data-testid="translator-icon"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7043 0.131208L12.1657 0.131652L0 15.7249V17.0536C0 17.7256 0.262825 18.3421 0.699944 18.8251L12.7043 3.43873V0.131208ZM12.7043 5.48074L1.71784 19.5624C2.13837 19.7534 2.61286 19.8609 3.11401 19.8609L4.60273 19.8621L12.7043 9.47808V5.48074ZM12.7043 11.5201L6.19487 19.8634L9.31157 19.866L12.7043 15.5174V11.5201ZM12.7043 17.5595L10.9037 19.8673L12.7043 19.8688V17.5595ZM15.303 19.8709L20.2411 19.875C21.2843 19.875 22.2185 19.4095 22.779 18.69L28.9448 10.8182C29.1347 10.5801 29.237 10.2938 29.237 10C29.237 9.7062 29.1347 9.41988 28.9448 9.18179L22.779 1.31C22.2185 0.590536 21.2843 0.125 20.2411 0.125L15.303 0.129067V19.8709ZM0 2.94643C0 1.71134 0.8877 0.663919 2.11357 0.287835L0 2.99687V2.94643ZM3.82323 0.138523L0 5.03888V7.64352L5.85666 0.136848L3.82323 0.138523ZM0 13.6829V9.68554L7.45085 0.135535L10.5716 0.132965L0 13.6829Z"
        fill={color}
      />
    </svg>
  );
};

export const Upload = ({ height = 60, width = 48, color = '#D2D2D2' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30 0H6C2.7 0 0.03 2.7 0.03 6L0 54C0 57.3 2.67 60 5.97 60H42C45.3 60 48 57.3 48 54V18L30 0ZM42 54H6V6H27V21H42V54ZM12 39.03L16.23 43.26L21 38.52V51H27V38.52L31.77 43.29L36 39.03L24.03 27L12 39.03Z"
        fill={color}
      />
    </svg>
  );
};

export const Warning = ({ height = 18, width = 20, color = '#EAB308' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.90822 1.71912C8.82622 0.0871172 11.1746 0.0871172 12.0914 1.71912L18.7874 13.6231C19.6874 15.2239 18.5318 17.1991 16.697 17.1991H3.30382C1.46782 17.1991 0.312218 15.2239 1.21222 13.6231L7.90822 1.71912ZM11.1998 13.6003C11.1998 13.9186 11.0734 14.2238 10.8483 14.4488C10.6233 14.6739 10.3181 14.8003 9.99982 14.8003C9.68156 14.8003 9.37633 14.6739 9.15129 14.4488C8.92625 14.2238 8.79982 13.9186 8.79982 13.6003C8.79982 13.2821 8.92625 12.9768 9.15129 12.7518C9.37633 12.5267 9.68156 12.4003 9.99982 12.4003C10.3181 12.4003 10.6233 12.5267 10.8483 12.7518C11.0734 12.9768 11.1998 13.2821 11.1998 13.6003V13.6003ZM9.99982 4.00032C9.68156 4.00032 9.37633 4.12675 9.15129 4.35179C8.92625 4.57683 8.79982 4.88206 8.79982 5.20032V8.80032C8.79982 9.11858 8.92625 9.4238 9.15129 9.64884C9.37633 9.87389 9.68156 10.0003 9.99982 10.0003C10.3181 10.0003 10.6233 9.87389 10.8483 9.64884C11.0734 9.4238 11.1998 9.11858 11.1998 8.80032V5.20032C11.1998 4.88206 11.0734 4.57683 10.8483 4.35179C10.6233 4.12675 10.3181 4.00032 9.99982 4.00032Z"
        fill={color}
      />
    </svg>
  );
};
export const ReturnIcon = ({ height = 12, width = 12, color = '#FFFFFF' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.97384 1.31782C4.12084 0.648042 5.45764 0.377746 6.77475 0.549288C8.09185 0.720831 9.31483 1.32452 10.252 2.26574C11.1892 3.20696 11.7876 4.43253 11.9534 5.75036C11.9994 6.11567 11.7406 6.44908 11.3753 6.49506C11.0099 6.54104 10.6765 6.28217 10.6306 5.91686C10.5015 4.89185 10.0361 3.9386 9.30716 3.20652C8.57822 2.47443 7.62699 2.00488 6.60255 1.87145C5.5781 1.73803 4.53833 1.94827 3.64619 2.46922C3.05686 2.81336 2.55395 3.28023 2.16882 3.83361H4C4.36819 3.83361 4.66667 4.13209 4.66667 4.50028C4.66667 4.86847 4.36819 5.16694 4 5.16694H0.666667C0.298477 5.16694 0 4.86847 0 4.50028V1.16694C0 0.813751 0.27546 0.521849 0.628062 0.501397C0.980664 0.480945 1.28803 0.73904 1.32886 1.08987L1.49688 2.53332C1.91822 2.05499 2.41591 1.64362 2.97384 1.31782ZM0.624272 6.50556C0.989547 6.45932 1.32315 6.71794 1.36939 7.08322C1.4991 8.10787 1.96493 9.06058 2.69394 9.7922C3.42295 10.5238 4.37398 10.9931 5.39816 11.1264C6.42234 11.2598 7.46184 11.0498 8.35392 10.5293C8.94286 10.1857 9.44561 9.71952 9.83087 9.16694H8C7.63181 9.16694 7.33333 8.86847 7.33333 8.50028C7.33333 8.13209 7.63181 7.83361 8 7.83361H11.3333C11.7015 7.83361 12 8.13209 12 8.50028V11.8336C12 12.1868 11.7245 12.4787 11.3719 12.4992C11.0192 12.5196 10.7119 12.2614 10.6711 11.9106L10.5033 10.466C10.0817 10.9442 9.5839 11.3554 9.02586 11.681C7.87901 12.3501 6.54265 12.6201 5.22597 12.4486C3.9093 12.2771 2.68665 11.6739 1.74944 10.7333C0.812233 9.79275 0.213371 8.56795 0.0466118 7.25067C0.000370383 6.8854 0.258998 6.5518 0.624272 6.50556Z"
        fill={color}
      />
    </svg>
  );
};

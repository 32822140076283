import React, { useMemo } from 'react';
import { Table } from 'antd';
import { invalidCheckForColumns } from '../../../../helpers';
import { Error } from '../../../../assets/icons';
const ErrorTable = ({ dataSource, fileName, bordered, reorderColumn }) => {
  const handleErrorOnTable = (val, row, data) => {
    switch (data) {
      case 'Sample ID':
        return {
          props: {
            style: { background: '#FEE2E2' },
          },
          children: (
            <div className="flex items-center text-red-600 font-bold w-full h-full gap-2 ">
              <span>{row['Sample ID']}</span>
              <Error />
            </div>
          ),
        };
      default: {
        return (
          <div className="flex w-full h-full gap-2 ">
            <span>{val}</span>
          </div>
        );
      }
    }
  };

  let columnData = [];

  if (reorderColumn) {
    const invalidArray = dataSource.map((item) => {
      return item.invalidColumns.join();
    });

    let uniqueInvalidArray = [...new Set(invalidArray)];

    const invalidColumnData = uniqueInvalidArray.map((data, i) => {
      return {
        title: () => {
          return (
            <div key={i} className="flex items-center gap-2 text-red-600">
              <span>{data}</span>
              <Error />
            </div>
          );
        },
        dataIndex: data,
        key: i,
        render: (val, row) => {
          return handleErrorOnTable(val, row, data);
        },
        align: 'left',
      };
    });

    const filteredColumnArray = Object.keys(dataSource[0]).filter(
      (item) => !dataSource[0]?.invalidSamples?.includes(item),
    );

    const filteredColumn = filteredColumnArray.map((data, i) => {
      return {
        title: data,
        dataIndex: data,
        key: (i + uniqueInvalidArray.length),
      };
    });

    columnData = [...invalidColumnData, ...filteredColumn];
  } else {
    columnData = Object.keys(dataSource[0]).map((data) => {
      return {
        title: () => {
          return invalidCheckForColumns(data, dataSource) ? (
            <div key={data['Sample ID']} className="flex gap-2 text-red-600">
              <span>{data}</span>
              <Error />
            </div>
          ) : (
            <div key={data['Sample ID']}>{data}</div>
          );
        },
        dataIndex: data,
        key: data['Sample ID'],
        render: (val, row) => {
          return handleErrorOnTable(val, row, data);
        },
        align: 'left',
      };
    });
  }

  const srcWithIndex = useMemo(() => {
    dataSource?.map((data, i) => {
      let indexedData = {
        ...data,
        key: i,
      };
      return indexedData;
    });

    return dataSource;
  }, [dataSource]);

  const tableWidth = columnData.length * 180;
  return (
    <div className="px-4 py-10  ">
      <span className="text-black text-xl">{fileName}</span>
      <Table
        bordered={bordered}
        columns={columnData}
        dataSource={srcWithIndex}
        scroll={{ x: tableWidth, y: 190 }}
        pagination={false}
      />
    </div>
  );
};

export default ErrorTable;

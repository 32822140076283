import React from 'react';
import classNames from 'classnames';
import Button from '../Button';
import { Close, Error, Warning } from '../../assets/icons';
/*
Banner props:
  type: "warning" || "error"
  children: "JSX"
  visible:"Boolean"
  imgSrc: "path for image on the left",
*/
const Banner = ({ type = 'warning', children, visible, handleBannerVisibility, className }) => {
  const bannerClasses = classNames(
    'h-14 px-3 mt-4 rounded-md flex items-center justify-between gap-2.5  font-bold',
    {
      'h-14 px-3 mt-4 rounded-md flex items-center justify-between gap-2.5  font-bold': true,
    },
    {
      'bg-yellow-200  border border-yellow-500 ': type === 'warning',
    },
    {
      'bg-red-100  border border-red-600 ': type === 'error',
    },
    className,
  );
  if (!visible) return null;
  const getIcon = (type) => {
    switch(type) {
      case 'warning':
        return <Warning />;
      case 'error':
        return <Error />;
      default:
       return null;
   }
  }
  return (
    <div className={bannerClasses}>
      <div className="flex items-center justify-between w-full">
        {getIcon(type)}
        <div className="px-2.5 flex justify-between items-center grow">{children}</div>
        {type !== 'error' && (
          <Button
            btnClick={() => handleBannerVisibility()}
            className="p-2 rounded border border-yellow-900"
            color="yellow-900"
          >
            <Close color="#713F12" />
          </Button>
        )}
      </div>
    </div>
  );
};

export default Banner;

import MixpanelBrowser from 'mixpanel-browser';
import { isNil } from 'lodash';
import { MIXPANEL_TOKEN } from '../constants/analyticsConstants';

const PROD_ENV = false;

const skipAnalytics = () => false;

class Analytics {
  static _instance;
  mixpanelApi;
  _skipAnalytics = false;

  constructor() {
    this.mixpanelApi = MixpanelBrowser;
    this.mixpanelApi.init(MIXPANEL_TOKEN, {}, '');
  }

  static get Instance() {
    return this._instance || (this._instance = new this());
  }

  async trackEvent(EventName, data = undefined) {
    return new Promise((resolve, reject) => {
      try {
        if (this._skipAnalytics) {
          return resolve(true);
        }
        this.mixpanelApi.track(EventName, data, () => {
          resolve(true);
        });
      } catch (error) {
        reject(false);
      }
    });
  }

  async trackAnonymousEvent(eventName, data) {
    if (this._skipAnalytics) {
      return;
    }

    try {
      const properties = isNil(data)
        ? { token: MIXPANEL_TOKEN }
        : { token: MIXPANEL_TOKEN, ...data };
      const reqData = {
        event: eventName,
        properties: properties,
      };
      const body = new URLSearchParams();
      body.append('data', JSON.stringify(reqData));
      const options = {
        method: 'POST',
        body: body,
      };
      await fetch('https://api.mixpanel.com/track', options);
    } catch (err) {
      console.error('Error tracking anonymous mixpanel event', err);
    }
  }

  setAlias(userId) {
    try {
      this.mixpanelApi.alias(userId);
    } catch (err) {
      console.error('Error trying to set mix panel alias', err);
    }
  }

  setUserData(userId, isMasterPassword) {
    if (isMasterPassword || skipAnalytics(userId, PROD_ENV)) {
      console.warn('Skipping analytics.');
      this._skipAnalytics = isMasterPassword;
      return;
    }
    this.mixpanelApi.identify(userId);
  }

  increment(prop, amount) {
    try {
      this.mixpanelApi.people.increment(prop, amount);
    } catch (err) {
      console.error('Error trying to increment mix panel', err);
    }
  }

  setPersonData(userId, email, displayName) {
    try {
      this.mixpanelApi.people.set({
        $userId: userId,
        $email: email,
        name: displayName,
      });
    } catch (err) {
      console.error('Error trying to set mix panel person data', err);
    }
  }

  reset() {
    this.mixpanelApi.reset();
  }
}

export default Analytics.Instance;

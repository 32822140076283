import React from 'react';
import { Routes, Route } from 'react-router-dom';
import AppLayout from '../AppLayout';
import FileSelect from './FileSelect';
import Redirect from './Redirect';
import TranslatedPage from './TranslatedPage';

const AppContainer = () => {
  return (
    <>
      <AppLayout>
        <div className="flex justify-center">
          <Routes>
            <Route path="/" element={<FileSelect />} />
            <Route path="/translated" element={<TranslatedPage />} />
            <Route path="*" element={<Redirect />} />
          </Routes>
        </div>
      </AppLayout>
    </>
  );
};

export default AppContainer;

import React, { useState } from 'react';
import classNames from 'classnames';
import { Bin } from '../../assets/icons';
import Button from '../Button';

export const FileInputDraggableContainer = (props) => {
  return (
    <div
      className="border border-dashed relative border-zinc-650 w-[748px] flex flex-col justify-center items-center mt-8"
      onDrop={props.onDrop}
    >
      {props.children}
    </div>
  );
};

export const FileInputHeader = (props) => {
  return (
    <div className="flex flex-col items-center w-full">
      <span className="h-[90px] w-12 mb-2 mt-2">{props.headerIcon}</span>
      <p className="font-thin text-neutral-600 mb-4 text-xl">{props.headerText}</p>
    </div>
  );
};

export const FileInput = (props) => {
  return (
    <input
      id="fileInput"
      className="opacity-0 absolute top-0 left-0 w-full h-full"
      type="file"
      onDrop={(e) => props.onFileDrop(e, 'drop')}
      onChange={props.onFileDrop}
      title=" "
    />
  );
};

export const SelectedFilesDisplayContainer = (props) => {
  return <div className="w-full flex flex-col items-center my-5">{props.children}</div>;
};

export const SelectedFilesDisplay = ({
  fileIcon,
  file,
  onBtnClick,
  previewIcon,
  disabledPreviewIcon,
  fileRemove,
  binIcon,
  finishedIcon,
  errorText,
  errorType,
  className,
}) => {
  const [color, setColor] = useState('black');
  const displayClass = classNames(
    'w-40 truncate font-normal',
    {
      'text-blue-400': !errorType,
    },
    className,
  );
  return (
    <div className="border py-4 px-8 rounded w-[90%] my-2 flex justify-between">
      <span className="flex items-center">
        <span className="mr-2">{fileIcon}</span>
        <span className={displayClass}>{file?.fileName} </span>
        <span className="text-red-600 px-4 font-normal">{errorText}</span>
      </span>
      <span className="flex items-center z-10">
        {errorType !== 'error' ? (
          <span className="flex items-center gap-2 cursor-pointer">
            {previewIcon}
            <Button
              color="text"
              className="text-primary-500 text-sm font-bold"
              btnClick={() => onBtnClick()}
            >
              Preview
            </Button>
          </span>
        ) : (
          <span className="flex items-center gap-2 cursor-not-allowed">
            {disabledPreviewIcon}
            <Button
              color="text"
              className="text-primary-500 opacity-50 text-sm font-bold"
              cursor="not-allowed"
            >
              Preview
            </Button>
          </span>
        )}

        {binIcon && (
          <div
            className="border ml-12 rounded p-2 cursor-pointer z-10 hover:bg-[#DC2626]"
            onClick={() => fileRemove(file?.index)}
            onMouseOver={() => setColor('white')}
            onMouseLeave={() => setColor('black')}
          >
            <Bin color={color} />
          </div>
        )}
        {finishedIcon && (
          <div className="flex ml-14 gap-2 cursor-default items-center">
            {finishedIcon}
            <span className="text-[#269B53] font-normal text-sm">Finished</span>
          </div>
        )}
      </span>
    </div>
  );
};

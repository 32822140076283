import classNames from 'classnames';
import React from 'react';

const Hero = (props) => {
  const heroContainerClass = classNames('flex flex-col items-center mt-20');

  const heroTitleClass = classNames('text-primary-500 text-4xl font-medium');

  const heroTextClass = classNames('text-lg');

  return (
    <section className={heroContainerClass}>
      <p className={heroTitleClass}>{props.title}</p>
      <p className={heroTextClass}>{props.text}</p>
    </section>
  );
};

export default Hero;

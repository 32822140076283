import { createSlice, createSelector } from '@reduxjs/toolkit';
import { groupDataBySampleId, translateCSVData, trimRecords, validFileTypes } from '../../helpers';

const initialState = {
  rawData: [],
};

export const RawDataSlice = createSlice({
  name: 'rawData',
  initialState,
  reducers: {
    setRawData: (state, action) => {
      state.rawData = action?.payload;
    },
    removeRawData: (state, action) => {
      state.rawData = state.rawData.filter((data) => data.index !== action?.payload);
    },
  },
});

export const { setRawData, removeRawData } = RawDataSlice.actions;

export default RawDataSlice.reducer;

const selectRawData = (state) => state.rawDataStore.rawData;
const selectDataFromMultipleFiles = (state) => state?.rawDataStore?.rawData;

export const selectSortedRawData = createSelector(selectRawData, (rawData) => {
  let arrayForSort = [...rawData];
  arrayForSort.sort((a, b) => a.index - b.index);
  return arrayForSort;
});

export const selectFileMultiple = createSelector(selectDataFromMultipleFiles, (data) => {
  if (data) {
    let finalArray = [];

    let rowFromEachFile = data.map((obj) => {
      return obj.rows;
    });

    finalArray = [].concat.apply([], rowFromEachFile);

    let translatedRowData = finalArray.map((rowData) => {
      return translateCSVData(rowData);
    });

    let groupedRows = groupDataBySampleId(translatedRowData);
    let filteredRows = trimRecords(groupedRows);

    let finalData = {};
    const checkForName = data.filter((el) => validFileTypes.includes(el.fileType));
    if (checkForName.length === 1) {
      finalData = {
        fileName: `${checkForName[0]?.fileName}`,
        rows: filteredRows,
      };
    } else {
      finalData = {
        fileName: 'Multiple',
        rows: filteredRows,
      };
    }

    return finalData;
  }
});

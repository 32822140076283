import classNames from 'classnames';
import React from 'react';

const Header = ({ logo, icon }) => {
  const titleConstainerClass = classNames(
    'uppercase flex items-center justify-end h-full container mx-auto',
  );

  const headerContainerClass = classNames('flex items-center container mx-auto h-24');

  return (
    <header>
      <div className="bg-[#4E6978] h-[3.125rem]">
        <div className={titleConstainerClass}>
          <p data-testid="header-logo-text" className="text-neutral-50 font-light font-family: Montserrat text-[0.813rem] tracking-[0.65px] mr-2">
            powered by
          </p>
          <div>
            {logo}
          </div>
        </div>
      </div>
      <div className="border-b border-[#CCD4D8] shadow-md">
        <div className={headerContainerClass}>
          {icon}
          <p className="font-bold text-2xl ml-1">Translator</p>
        </div>
      </div>
    </header>
  );
};

export default Header;

import React from 'react';
import { Close } from '../../assets/icons';
import Button from '../Button';

const Modal = ({
  title,
  children,
  onClose,
  footer,
  buttonAlign = 'end',
  buttonText = 'Button',
  footerBtnClick,
  disableBtn
}) => {
  return (
    <div
      onClick={() => onClose()}
      className="v-screen h-screen bg-black/[.5] z-20 fixed inset-0 flex justify-center items-center "
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="bg-neutral-100 text-black z-30 w-[1140px] overflow-y-scroll max-h-[80%] "
      >
        <div className=" sticky top-0 flex justify-between items-center text-xl border-b bg-neutral-50 z-10">
          <p className="p-4 mb-0 font-medium">{title}</p>
          <div className='p-4 cursor-pointer' onClick={() => onClose()}>
            <Close />
          </div>
        </div>
        <div className="py-10 px-10 flex h-full flex-col justify-between ">{children}</div>
        {footer && (
          <div
            className={`flex sticky items-center justify-${buttonAlign} h-20 w-full bottom-0	bg-neutral-50 z-10 shadow-3xl`}
          >
            <div className="px-11">
              <Button  disabled={disableBtn}className="h-11" color="primary" btnClick={() => footerBtnClick()}>
                {buttonText}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Modal;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Papa from 'papaparse';
import Button from '../../../components/Button';
import Modal from '../../../components/Modal';
import PreviewTable from '../PreviewTable';
import { useNavigate } from 'react-router-dom';
import Hero from '../../../components/Hero';
import Banner from '../../../components/Banner';
import InvalidSamplesModal from '../InvalidSamplesModal';
import { setRawData, removeRawData } from '../../../features/rawDataSlice';
import { fileTypeValidator, validateSamples, validFileTypes } from '../../../helpers';
import {
  FileInput,
  FileInputDraggableContainer,
  FileInputHeader,
  SelectedFilesDisplay,
  SelectedFilesDisplayContainer,
} from '../../../components/FileInput';
import ErrorText from '../../../components/ErrorText';
import {
  ArrowRight,
  Error,
  ChevronRight,
  ChevronLeft,
  File,
  Preview,
  Upload,
  Warning,
  ReturnIcon,
} from '../../../assets/icons';
import classNames from 'classnames';
import Analytics from '../../../helpers/analytics';
import { MIXPANEL_EVENTS } from '../../../constants/analyticsConstants';

const INVALID_FILE_WARNING = 'invalidFile';
const INVALID_SAMPLES_WARNING = 'invalidSamples';
const INVALID_SAMPLES_PREVIEW_WARNING = 'invalidSamplesPreview';
const INVALID_SAMPLES_HIGHLIGHTED_WARNING = 'invalidSamplesHighlighted';
const WARNING_BANNER_TYPES = [
  INVALID_FILE_WARNING,
  INVALID_SAMPLES_WARNING,
  INVALID_SAMPLES_PREVIEW_WARNING,
  INVALID_SAMPLES_HIGHLIGHTED_WARNING,
];

const FileSelect = () => {
  const [showModal, setShowModal] = useState(false);
  const [tableIndex, setTableIndex] = useState(0);
  const [isInvalidSampleBannerVisible, setIsInvalidSampleBannerVisible] = useState(false);
  const [errorModalOpen, setErrorModelOpen] = useState(false);
  const [bannerText, setBannerText] = useState({ totalFiles: 0, errorSpotted: 0, totalSamples: 0 });
  const [individualErrorFileCount, setIndividualErrorFileCount] = useState();
  const [previewData, setPreviewData] = useState([]);
  const [errorToggle, setErrorToggle] = useState(true);
  const [invalidFormatBanner, setInvalidFormatBanner] = useState(false);
  const [totalInvalidFormat, setTotalInvalidFormat] = useState(0);
  const [errorModalBanner, setErrorModalBanner] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const rawDataFromStore = useSelector((state) => state.rawDataStore.rawData);
  const maxFileSizeLimitInByte = 12500000;

  useEffect(() => {
    const totalSamples = rawDataFromStore.reduce((previousValue, currentValue) => {
      return parseInt(previousValue) + parseInt(currentValue.rows.length);
    }, 0);
    const validationCheckOnRows = rawDataFromStore.map((el) => {
      const data = el?.rows.filter((bool) => bool?.isInvalid === true);
      return data.length;
    });
    const totalErrorSamples = validationCheckOnRows.reduce((previousValue, currentValue) => {
      return previousValue + currentValue;
    }, 0);
    let errorModalTotalInvalidSamples = [];
    rawDataFromStore?.map((el) => {
      const innerArray = el?.rows?.filter((inner) => inner.isInvalid);
      return innerArray.length !== 0 && errorModalTotalInvalidSamples.push(innerArray);
    });
    setBannerText({
      errorSpotted: errorModalTotalInvalidSamples.length,
      totalSamples: totalSamples,
      totalErrorSamples: totalErrorSamples,
    });
    if (
      rawDataFromStore.filter((el) => validFileTypes.includes(el.fileType)).length !== 0 &&
      fileTypeValidator(rawDataFromStore).length !== 0
    ) {
      setInvalidFormatBanner(true);
    } else {
      setInvalidFormatBanner(false);
    }
    setTotalInvalidFormat(fileTypeValidator(rawDataFromStore).length);
    setIsInvalidSampleBannerVisible(totalErrorSamples > 0);
  }, [rawDataFromStore]);

  const onFileDrop = (e, type) => {
    const newFile = e.target.files[0];
    if (newFile) {
      Papa.parse(newFile, {
        header: true,
        skipEmptyLines: true,
        complete: (result) => {
          const newData = {
            fileName: e.target.files[0].name,
            index: rawDataFromStore.length,
            fileSize: newFile?.size,
            fileType: newFile?.type,
            rows: validFileTypes.includes(newFile?.type) ? validateSamples(result?.data) : [], // Validation of sample Id in rows
          };
          const fileError = errorTextHandler(newData);
          dispatch(
            setRawData([
              ...rawDataFromStore,
              {
                ...newData,
                error: fileError,
              },
            ]),
          );
        },
      });
    }
    Analytics.trackEvent(MIXPANEL_EVENTS.SELECTED_FILES, {
      count: e.target.files.length,
      method: type === 'drop' ? 'drag n drop' : 'dialog',
    });
  };

  const fileRemove = (index) => {
    Analytics.trackEvent(MIXPANEL_EVENTS.CLICKED_REMOVE, {
      fileName: rawDataFromStore[index].fileName,
    });
    dispatch(removeRawData(index));
    document.getElementById('fileInput').value = null;
  };

  const toggleModal = (index, data, type) => {
    setShowModal(!showModal);
    setPreviewData(rawDataFromStore.filter((el) => validFileTypes.includes(el.fileType) && el));
    setTableIndex(index); //this is to pass in props to PreviewTable, to get that particular index
    let finalData = rawDataFromStore[index]?.rows?.filter((el) => el?.isInvalid);
    setIndividualErrorFileCount(finalData?.length);
    if (type === 'close') {
      Analytics.trackEvent(MIXPANEL_EVENTS.CLICKED_CLOSE_PREVIEW);
    } else {
      Analytics.trackEvent(MIXPANEL_EVENTS.CLICKED_PREVIEW, {
        fileName: rawDataFromStore[index].fileName,
        type: 'Uploaded',
      });
    }
  };

  const translate = () => {
    Analytics.trackEvent(MIXPANEL_EVENTS.CLICKED_TRANSLATE);
    navigateTo();
  };

  const removeAndTranslate = () => {
    Analytics.trackEvent(MIXPANEL_EVENTS.CLICKED_REMOVE_AND_TRANSLATE);
    navigateTo();
  };

  const navigateTo = () => {
    navigate('/translated', {
      state: {
        filesSelected: rawDataFromStore?.length,
        filesTranslated: rawDataFromStore?.length - previewData.length,
        totalSamples: bannerText.totalSamples,
        validSamples: bannerText.totalSamples - bannerText.errorSpotted,
      },
    });
  };

  const handleFileSelect = () => {
    document.getElementById('fileInput').click();
    Analytics.trackEvent('Clicked Choose Files');
  };

  const tableSwitchHandler = (type) => {
    if (type === 'prev') {
      let index = tableIndex - 1;
      setTableIndex(index);
      Analytics.trackEvent(MIXPANEL_EVENTS.CLICKED_PREVIOUS_PREVIEW, {
        fileName: previewData[index].fileName,
      });
    }

    if (type === 'next') {
      let index = tableIndex + 1;
      setTableIndex(index);
      Analytics.trackEvent(MIXPANEL_EVENTS.CLICKED_NEXT_PREVIEW, {
        fileName: previewData[index].fileName,
      });
    }
  };

  const handleErrorChecker = (data) => {
    return (
      invalidFileChecker(data) ||
      !validFileTypes.includes(data?.fileType) ||
      data.fileSize > maxFileSizeLimitInByte ||
      data.rows.some((data) => !data['Sample ID'])
    );
  };

  const handleErrorModal = () => {
    setErrorModelOpen(!errorModalOpen);
    Analytics.trackEvent(
      errorModalOpen ? MIXPANEL_EVENTS.CLICKED_CLOSE_INVALID : MIXPANEL_EVENTS.CLICKED_VIEW,
      {
        type: bannerText?.totalErrorSamples !== bannerText?.totalSamples ? 'warning' : 'error',
      },
    );
  };

  const handleInvalidSampleBannerVisible = (type) => {
    if (WARNING_BANNER_TYPES.includes(type)) {
      Analytics.trackEvent(MIXPANEL_EVENTS.CLICKED_CLOSE_WARNING, {
        type: type,
      });
    }
    setIsInvalidSampleBannerVisible(!isInvalidSampleBannerVisible);
  };

  const handleInvalidFormatBannerVisible = (type) => {
    Analytics.trackEvent(MIXPANEL_EVENTS.CLICKED_CLOSE_WARNING, {
      type: INVALID_FILE_WARNING,
    });
    setInvalidFormatBanner(!invalidFormatBanner);
  };

  const handleInvalidSamplePreviewBannerVisible = () => {
    Analytics.trackEvent(MIXPANEL_EVENTS.CLICKED_CLOSE_WARNING, {
      type: INVALID_SAMPLES_HIGHLIGHTED_WARNING,
    });
    setErrorModalBanner(!errorModalBanner);
  };

  const invalidFileChecker = (data) => {
    return data?.rows?.filter((el) => el.isInvalid === true).length === data.rows?.length;
  };

  const handleFileIcon = (validate, data) => {
    switch (true) {
      case handleErrorChecker(data):
        return <Error />;
      case validate?.length > 0:
        return <Warning />;
      default:
        return <File />;
    }
  };
  const errorTextHandler = (data) => {
    if (!validFileTypes.includes(data?.fileType)) {
      Analytics.trackEvent(MIXPANEL_EVENTS.INVALID_FILE_TYPE, {
        fileName: data.fileName,
        fileSize: data.fileSize,
      });
      return 'Invalid file format, please upload a CSV file';
    } else if (data.fileSize > maxFileSizeLimitInByte) {
      Analytics.trackEvent(MIXPANEL_EVENTS.INVALID_FILE_SIZE, {
        fileName: data.fileName,
        fileSize: data.fileSize,
      });
      return 'File exceeds maximum size of 100 Mb';
    } else if (data.rows.some((data) => !data['Sample ID'])) {
      Analytics.trackEvent(MIXPANEL_EVENTS.NO_SAMPLE_ID, {
        fileName: data.fileName,
        fileSize: data.fileSize,
      });
      return 'Unable to upload file, please try again.';
    } else {
      Analytics.trackEvent(MIXPANEL_EVENTS.VALID_FILE, {
        fileName: data.fileName,
        fileSize: data.fileSize,
        totalSamples: data.rows.length,
        validSamples: data.rows.filter((row) => !row.isInvalid).length,
      });
      return '';
    }
  };
  const buttonClasses = classNames(
    {
      'py-1 px-4 rounded h-9 w-78px': true,
    },
    {
      ' bg-yellow-400': bannerText?.totalErrorSamples !== bannerText?.totalSamples,
    },
    {
      ' bg-red-300': bannerText?.totalErrorSamples === bannerText?.totalSamples,
    },
  );
  const handleTranslateButton = () => {
    switch (true) {
      case fileTypeValidator(rawDataFromStore).length !== rawDataFromStore.length &&
        fileTypeValidator(rawDataFromStore).length !== 0 &&
        bannerText?.totalErrorSamples !== bannerText?.totalSamples:
        return (
          <Button color="primary" imgPosition="right" btnClick={removeAndTranslate}>
            Remove and Translate
            <ReturnIcon />
          </Button>
        );
      case bannerText?.totalErrorSamples === bannerText?.totalSamples:
        return (
          <Button color="primary" imgPosition="right" btnClick={() => handleFileSelect()}>
            Translate Another File
            <ReturnIcon />
          </Button>
        );
      default:
        return (
          <Button color="primary" imgPosition="right" btnClick={translate}>
            Translate
            <ArrowRight />
          </Button>
        );
    }
  };

  return (
    <div>
      <Banner
        type={bannerText?.totalErrorSamples === bannerText?.totalSamples ? 'error' : 'warning'}
        visible={isInvalidSampleBannerVisible}
        handleBannerVisibility={() => handleInvalidSampleBannerVisible(INVALID_SAMPLES_WARNING)}
      >
        <div className="flex align-center gap-2.5">
          {bannerText?.totalErrorSamples !== bannerText?.totalSamples ? (
            <span className="text-yellow-900 ">
              {bannerText?.totalErrorSamples} of {bannerText?.totalSamples}{' '}
              {bannerText?.totalSamples > 1 ? 'samples' : 'sample'} have data in the wrong format
              and will be removed.
            </span>
          ) : (
            <span className="text-red-900 ">
              Unable to translate as all samples are invalid. Please upload a file with at least one
              valid sample.
            </span>
          )}
        </div>
        <div className="flex align-center gap-2.5">
          <Button btnClick={() => handleErrorModal()} className={buttonClasses} color="yellow-900">
            VIEW{' '}
          </Button>
        </div>
      </Banner>
      <Banner
        type="warning"
        visible={invalidFormatBanner}
        handleBannerVisibility={() => handleInvalidFormatBannerVisible(INVALID_FILE_WARNING)}
      >
        {totalInvalidFormat} file is not a CSV and will be removed.
      </Banner>
      <Hero title={'CSV Translator'} text={'Translate your CSV file'} />
      {errorModalOpen && (
        <InvalidSamplesModal
          {...{
            rawDataFromStore,
            errorToggle,
            setErrorToggle,
            bannerText,
            errorModalBanner,
            handleInvalidSamplesBanner: handleInvalidSamplePreviewBannerVisible,
            navigateTo: removeAndTranslate,
            handleErrorModal,
            buttonText:
              bannerText?.totalErrorSamples === bannerText?.totalSamples
                ? 'Close'
                : 'REMOVE & TRANSLATE',
          }}
        />
      )}
      {showModal ? (
        <Modal
          title={`Preview Data - ${rawDataFromStore[tableIndex]?.fileName}`}
          onClose={() => toggleModal(null, null, 'close')}
        >
          {individualErrorFileCount !== 0 && (
            <Banner
              className="mb-9"
              type={'warning'}
              visible={isInvalidSampleBannerVisible}
              handleBannerVisibility={() =>
                handleInvalidSampleBannerVisible(INVALID_SAMPLES_PREVIEW_WARNING)
              }
            >
              <div className="flex align-center gap-2.5">
                <span className="text-yellow-900 ">
                  The {individualErrorFileCount} marked samples have data in the wrong format and
                  will be removed.
                </span>
              </div>
            </Banner>
          )}
          <PreviewTable i={tableIndex} data={previewData} />
          {previewData.length > 1 && (
            <div className="flex pt-8 w-full">
              <Button
                btnClick={() => tableSwitchHandler('prev')}
                imgPosition="left"
                color="text"
                hidden={tableIndex < 1}
              >
                <ChevronLeft />
                Previous
              </Button>
              <Button
                btnClick={() => tableSwitchHandler('next')}
                imgPosition="right"
                color="text"
                hidden={tableIndex === previewData.length - 1}
                className="ml-auto"
              >
                Next
                <ChevronRight />
              </Button>
            </div>
          )}
        </Modal>
      ) : null}
      <FileInputDraggableContainer>
        {rawDataFromStore.length === 0 ? (
          <FileInputHeader
            headerIcon={<Upload />}
            headerText="Drag and Drop your CSV file here"
          ></FileInputHeader>
        ) : null}
        <FileInput onFileDrop={onFileDrop} />
        <SelectedFilesDisplayContainer>
          {rawDataFromStore &&
            rawDataFromStore.map((data, i) => {
              const validate = data.rows.filter((row) => row?.isInvalid === true); // check for warning icon
              return (
                <React.Fragment key={i}>
                  <SelectedFilesDisplay
                    errorType={data.error === '' ? false : 'error'}
                    fileIcon={handleFileIcon(validate, data)} // check for warning icon
                    previewIcon={<Preview />}
                    disabledPreviewIcon={<Preview opacity={0.5} />}
                    onBtnClick={() => toggleModal(i, data)}
                    fileRemove={fileRemove}
                    key={i}
                    file={data}
                    errorText={
                      validate?.length > 0
                        ? `${validate?.length} of ${data?.rows?.length} ${
                            data?.rows?.length > 1
                              ? 'samples contain error'
                              : 'sample contain  error'
                          }`
                        : ''
                    }
                    binIcon
                  />
                  <div className="w-full px-9">
                    <ErrorText message={data.error} />
                  </div>
                </React.Fragment>
              );
            })}
        </SelectedFilesDisplayContainer>
        {rawDataFromStore.length === 0 ? (
          <Button color="primary" className="mb-6" btnClick={handleFileSelect}>
            Choose Files
          </Button>
        ) : (
          <div className="flex justify-end items-center gap-4 w-full px-10 mb-4">
            <p className="text-neutral-600 text-base">
              Want to upload another? Drag and drop another file here or
            </p>
            <Button color="secondary" btnClick={handleFileSelect}>
              Choose Files
            </Button>
          </div>
        )}
      </FileInputDraggableContainer>
      {rawDataFromStore.length > 0 ? (
        <div className="my-6 mb-6 flex justify-end">{handleTranslateButton()}</div>
      ) : null}
    </div>
  );
};

export default FileSelect;

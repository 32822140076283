export const MIXPANEL_DEV_TOKEN = 'fd5bfb34423733567c4fb589f1c9da16';
export const MIXPANEL_PRODUCTION_TOKEN = 'e28c3d5d2f6f7649f355ab96287a7270';
export const MIXPANEL_TOKEN =
  process.env.REACT_APP_ENV === 'production' ? MIXPANEL_PRODUCTION_TOKEN : MIXPANEL_DEV_TOKEN;
export const MIXPANEL_EVENTS = {
  SELECTED_FILES: 'Selected Files',
  CLICKED_REMOVE: 'Clicked Remove',
  CLICKED_PREVIOUS_PREVIEW: 'Clicked Previous Preview',
  CLICKED_CLOSE_PREVIEW: 'Clicked Close Preview',
  CLICKED_PREVIEW: 'Clicked Preview',
  CLICKED_NEXT_PREVIEW: 'Clicked Next Preview',
  CLICKED_DOWNLOAD: 'Clicked Download',
  CLICKED_VIEW: 'Clicked View Invalid Samples',
  CLICKED_CLOSE_INVALID: 'Clicked Close Invalid Samples view',
  CLICKED_CLOSE_WARNING: 'Clicked Close Warning',
  INVALID_FILE_TYPE: 'Selected a file with an invalid file type',
  INVALID_FILE_SIZE: 'Selected a file with an invalid file Size',
  NO_SAMPLE_ID: 'Selected a file with no Sample ID',
  VALID_FILE: 'Selected a Valid file',
  ERROR_TOGGLE: 'Clicked show columns with errors first',
  TRANSLATION_COMPLETE: 'Successfully completed translation',
  TRANSLATE_MORE_FILES: 'Clicked translate more files',
  CLICKED_TERMS: 'Clicked Terms',
  CLICKED_PRIVACY_POLICY: 'Clicked Privacy Policy',
  CLICKED_TRANSLATE: 'Clicked Translate',
  CLICKED_REMOVE_AND_TRANSLATE: 'Clicked Remove and Translate',
};

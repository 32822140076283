import React from 'react';
import { MIXPANEL_EVENTS } from '../../../constants/analyticsConstants';
import Analytics from '../../../helpers/analytics';

const Footer = () => {
  return (
    <footer className="flex gap-8 justify-center mb-4 mt-4">
      <a
        className="text-sm uppercase tracking-wide"
        href="https://www.deveronapp.com/terms.html"
        onClick={() => Analytics.trackEvent(MIXPANEL_EVENTS.CLICKED_TERMS)}
      >
        Terms
      </a>
      <a
        className="text-sm uppercase tracking-wide"
        href="https://www.deveronapp.com/privacy.html"
        onClick={() => Analytics.trackEvent(MIXPANEL_EVENTS.CLICKED_PRIVACY_POLICY)}
      >
        Privacy Policy
      </a>
    </footer>
  );
};

export default Footer;

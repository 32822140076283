import { MASTER_COLUMNS, SOURCE_COLUMNS } from '../constants/sourceConstants';
import { masterCSVData } from './masterCSVData';

export const validFileTypes = ['text/csv'];

export const fileTypeValidator = (rawDataFromStore) => {
  return rawDataFromStore.filter((el) => !validFileTypes.includes(el.fileType));
};

export const groupDataBySampleId = (arr = []) => {
  let dict = {};
  /*
    Checking for each scenario
    1. where prevDict value is emply and srcDict value is also empty
    2. where prevDict value is empty and srcDict has a value
    3. where prevDict has a vlaue and srcDict is empty
    4. where value is type of array (for Test Type and Timing) -> to concat
  */
  const objectConcat = (prevDict, srcDict) => {
    let res = {};
    for (const [key, value] of Object.entries(prevDict)) {
      if (value === '' || srcDict[key] === '') {
        res[key] = value + srcDict[key];
      } else if (value === '' && srcDict[key]) {
        res[key] = srcDict[key];
      } else if (value === srcDict[key]) {
        res[key] = value;
      } else if (Array.isArray(value)) {
        let typeSet = new Set();
        value.forEach((val) => typeSet.add(val));
        srcDict[key].forEach((val) => typeSet.add(val));
        res[key] = Array.from(typeSet);
      } else {
        res[key] = value;
      }
    }
    return res;
  };

  // Iteratig array and and building a dictonary to handle data
  arr.forEach((item) => {
    if (!dict[item['Program ID']]) {
      dict[item['Program ID']] = item;
    } else {
      dict[item['Program ID']] = objectConcat(dict[item['Program ID']], item);
    }
  });

  // create final array with test type and test timing
  let finalArray = [];
  // eslint-disable-next-line
  for (const [key, value] of Object.entries(dict)) {
    value['Test Type'] = value['Test Type'].join(', ');
    value['Test Timing'] = value['Test Timing'].join(', ');
    finalArray.push(value);
  }

  return finalArray;
};

export const getTestType = (data) => {
  if (data === 'S') {
    return ['Shallow'];
  } else if (data === 'D') {
    return ['Deep'];
  } else if (data === 'T') {
    return ['Tissue'];
  } else {
    return [];
  }
};
export const getTestTiming = (data) => {
  if (data === 'PP') {
    return ['Preplant'];
  } else if (data === 'V4' || data === 'V6') {
    return ['4 Leaf'];
  } else if (data === 'R1') {
    return ['Pollination'];
  } else if (data === 'BL') {
    return ['Maturity'];
  } else {
    return [];
  }
};

// See readme for spec
export const translateCSVData = (dataRow) => {
  let programID = dataRow['Sample ID']?.slice(0, 6);
  let testType = dataRow['Sample ID']?.substring(dataRow['Sample ID'].length - 1);
  let testTiming = dataRow['Sample ID']?.charAt(6) + '' + dataRow['Sample ID']?.charAt(7);
  let masterObj = {};
  // This checks if masterCSVData has the sample ID
  let masterObjExists = masterCSVData.some((el) => el['Program ID'] === programID);
  if (masterObjExists) {
    masterObj = masterCSVData.find((el) => el['Program ID'] === programID);
  } else {
    masterObj = {
      'Program ID': '',
      'Opportunity ID': '',
      'Opportunity Name': '',
      'Account Name': '',
      Field: '',
    };
  }

  let columnValues = {
    // Master CSV Object
    sampleId: dataRow[SOURCE_COLUMNS.SAMPLE_ID],
    programId: masterObj[MASTER_COLUMNS.PROGRAM_ID],
    opportunityId: masterObj[MASTER_COLUMNS.OPPORTUNITY_ID],
    accountName: masterObj[MASTER_COLUMNS.ACCOUNT_NAME],
    field: masterObj[MASTER_COLUMNS.FIELD],
    key: dataRow[SOURCE_COLUMNS.SAMPLE_ID],
    // from Source CSV Data
    PP_Date: dataRow[SOURCE_COLUMNS.DATE_RECEIVED] || dataRow[SOURCE_COLUMNS.RECEIVED_DATE],
    PPS0_10_NO3_LBS_AC: dataRow[SOURCE_COLUMNS.NO3_N_LBS_AC],
    PPS0_10_NH4_LBS_AC: multiplyColumn(dataRow[SOURCE_COLUMNS.AMMONIUM_N_PPM], 3),
    PPS0_10_OM: dataRow[SOURCE_COLUMNS.ORGANIC_MATTER],
    PPS10_20_N03_LBS_AC: dataRow[SOURCE_COLUMNS.NO3_N_LBS_AC],
    V4_Date: dataRow[SOURCE_COLUMNS.DATE_RECEIVED] || dataRow[SOURCE_COLUMNS.RECEIVED_DATE],
    V4_S0_10_NO3_LBS_AC: dataRow[SOURCE_COLUMNS.NO3_N_LBS_AC],
    V4_S0_10_NH4_LBS_AC: multiplyColumn(dataRow[SOURCE_COLUMNS.AMMONIUM_N_PPM], 3),
    V4_S10_20_NO3_LBS_AC: dataRow[SOURCE_COLUMNS.NO3_N_LBS_AC],
    V4_T_NO3_LB_AC: dataRow[SOURCE_COLUMNS.NITRATE_NITROGEN_MG_KG],
    V4_T_Total_N: dataRow[SOURCE_COLUMNS.TOTAL_NITROGEN],
    V4_T_Total_P: dataRow[SOURCE_COLUMNS.PHOSPHORUS],
    V4_T_Total_K: dataRow[SOURCE_COLUMNS.POTASSIUM],
    V4_T_Total_S: dataRow[SOURCE_COLUMNS.SULFUR],
    V6_DATE: dataRow[SOURCE_COLUMNS.DATE_RECEIVED] || dataRow[SOURCE_COLUMNS.RECEIVED_DATE],
    V6_NO3: dataRow[SOURCE_COLUMNS.NO3_N_LBS_AC],
    V6_NH4: multiplyColumn(dataRow[SOURCE_COLUMNS.AMMONIUM_N_PPM], 3),
    V6_10_20_NO3: dataRow[SOURCE_COLUMNS.NO3_N_LBS_AC],
    V6_T_NO3_PPM: dataRow[SOURCE_COLUMNS.NITRATE_NITROGEN_MG_KG],
    V6_T_Total_N: dataRow[SOURCE_COLUMNS.TOTAL_NITROGEN],
    V6_T_Total_P: dataRow[SOURCE_COLUMNS.PHOSPHORUS],
    V6_T_Total_K: dataRow[SOURCE_COLUMNS.POTASSIUM],
    V6_T_Total_S: dataRow[SOURCE_COLUMNS.SULFUR],
    V6_T_N_S_RATIO: dataRow[SOURCE_COLUMNS.N_S_RATIO],
    R1_Date: dataRow[SOURCE_COLUMNS.DATE_RECEIVED] || dataRow[SOURCE_COLUMNS.RECEIVED_DATE],
    R1_S0_10_NO3_LBS_AC: dataRow[SOURCE_COLUMNS.NO3_N_LBS_AC],
    R1_S0_10_NH4_LBS_AC: multiplyColumn(dataRow[SOURCE_COLUMNS.AMMONIUM_N_PPM], 3),
    R1_S10_20_NO3_LBS_AC: dataRow[SOURCE_COLUMNS.NO3_N_LBS_AC],
    R1_T_NO3_LBS_AC: dataRow[SOURCE_COLUMNS.NITRATE_NITROGEN_MG_KG],
    R1_T_Total_N: dataRow[SOURCE_COLUMNS.TOTAL_NITROGEN],
    R1_T_Total_P: dataRow[SOURCE_COLUMNS.PHOSPHORUS],
    R1_T_Total_K: dataRow[SOURCE_COLUMNS.POTASSIUM],
    R1_T_Total_S: dataRow[SOURCE_COLUMNS.SULFUR],
    BL_Date: dataRow[SOURCE_COLUMNS.DATE_RECEIVED] || dataRow[SOURCE_COLUMNS.RECEIVED_DATE],
    BL_S0_10_NO3: dataRow[SOURCE_COLUMNS.NO3_N_LBS_AC],
    BL_T_NO3_PPM: dataRow[SOURCE_COLUMNS.NITRATE_NITROGEN_MG_KG],
  };

  return {
    // Master CSV Data
    'Program ID': programID,
    'Opportunity ID': masterObj['Opportunity ID'],
    'Opportunity Name': masterObj['Opportunity Name'],
    'Account Name': masterObj['Account Name'],
    Field: masterObj['Field'],
    // Source CSV Data
    'Test Type': getTestType(testType),
    'Test Timing': getTestTiming(testTiming),
    'PP Date': testTiming === 'PP' ? columnValues.PP_Date : '',
    'PPS0-10 NO3 (lbs/ac)':
      testType === 'S' && testTiming === 'PP' ? columnValues.PPS0_10_NO3_LBS_AC : '',
    'PPS0-10 NH4 (lbs/ac)':
      testType === 'S' && testTiming === 'PP' ? columnValues.PPS0_10_NH4_LBS_AC : '',
    'PPS0-10 OM': testType === 'S' && testTiming === 'PP' ? columnValues.PPS0_10_OM : '',
    'PPS10-20 N03 (lbs/ac)':
      testType === 'D' && testTiming === 'PP' ? columnValues.PPS10_20_N03_LBS_AC : '',
    'V4 Date': testTiming === 'V4' ? columnValues.V4_Date : '',
    'V4S0-10 NO3 (lbs/ac)':
      testType === 'S' && testTiming === 'V4' ? columnValues.V4_S0_10_NO3_LBS_AC : '',
    'V4S0-10 NH4(lbs/ac)':
      testType === 'S' && testTiming === 'V4' ? columnValues.V4_S0_10_NH4_LBS_AC : '',
    'V4S10-20 NO3(lbs/ac)':
      testType === 'D' && testTiming === 'V4' ? columnValues.V4_S10_20_NO3_LBS_AC : '',
    'V4T NO3 (lb/ac)': testType === 'T' && testTiming === 'V4' ? columnValues.V4_T_NO3_LB_AC : '',
    'V4T Total N%': testType === 'T' && testTiming === 'V4' ? columnValues.V4_T_Total_N : '',
    'V4T Total P%': testType === 'T' && testTiming === 'V4' ? columnValues.V4_T_Total_P : '',
    'V4T Total K%': testType === 'T' && testTiming === 'V4' ? columnValues.V4_T_Total_K : '',
    'V4T Total S%': testType === 'T' && testTiming === 'V4' ? columnValues.V4_T_Total_S : '',
    'V6 Date': testTiming === 'V6' ? columnValues.V6_DATE : '',
    'V6 NO3': testType === 'S' && testTiming === 'V6' ? columnValues.V6_NO3 : '',
    'V6 NH4': testType === 'S' && testTiming === 'V6' ? columnValues.V6_NH4 : '',
    'V6 10-20" NO3': testType === 'D' && testTiming === 'V6' ? columnValues.V6_10_20_NO3 : '',
    'V6 T NO3 (ppm)': testType === 'T' && testTiming === 'V6' ? columnValues.V6_T_NO3_PPM : '',
    'V6 T Total N%': testType === 'T' && testTiming === 'V6' ? columnValues.V6_T_Total_N : '',
    'V6 T Total P%': testType === 'T' && testTiming === 'V6' ? columnValues.V6_T_Total_P : '',
    'V6 T Total K%': testType === 'T' && testTiming === 'V6' ? columnValues.V6_T_Total_K : '',
    'V6 T Total S%': testType === 'T' && testTiming === 'V6' ? columnValues.V6_T_Total_S : '',
    'V6 T N:S Ratio': testType === 'T' && testTiming === 'V6' ? columnValues.V6_T_N_S_RATIO : '',
    'R1 Date': testTiming === 'R1' ? columnValues.R1_Date : '',
    'R1S0-10 NO3 (lbs/ac)':
      testType === 'S' && testTiming === 'R1' ? columnValues.R1_S0_10_NO3_LBS_AC : '',
    'R1S0-10 NH4(lbs/ac)':
      testType === 'S' && testTiming === 'R1' ? columnValues.R1_S0_10_NH4_LBS_AC : '',
    'R1S10-20 NO3(lbs/ac)':
      testType === 'D' && testTiming === 'R1' ? columnValues.R1_S10_20_NO3_LBS_AC : '',
    'R1T NO3 (lb/ac)': testType === 'T' && testTiming === 'R1' ? columnValues.R1_T_NO3_LBS_AC : '',
    'R1T Total N%': testType === 'T' && testTiming === 'R1' ? columnValues.R1_T_Total_N : '',
    'R1T Total P%': testType === 'T' && testTiming === 'R1' ? columnValues.R1_T_Total_P : '',
    'R1T Total K%': testType === 'T' && testTiming === 'R1' ? columnValues.R1_T_Total_K : '',
    'R1T Total S%': testType === 'T' && testTiming === 'R1' ? columnValues.R1_T_Total_S : '',
    'BL Date': testTiming === 'BL' ? columnValues.BL_Date : '',
    'BLS0-10 NO3(lbs/ac)': testType === 'S' && testTiming === 'BL' ? columnValues.BL_S0_10_NO3 : '',
    'BLT NO3(ppm)': testType === 'T' && testTiming === 'BL' ? columnValues.BL_T_NO3_PPM : '',
  };
};

export const appendDateToFileName = (fileName, date) => {
  let dotIndex = fileName?.lastIndexOf('.');
  if (dotIndex === -1) {
    return `${fileName}_${date}`;
  } else {
    return fileName?.substring(0, dotIndex) + '_' + date + fileName?.substring(dotIndex);
  }
};

export const trimRecords = (data) => {
  return data?.filter(
    (el) => el['Program ID'] !== '' && el['Test Type'] !== '' && el['Test Timing'] !== '',
  );
};

const validateSampleId = (sample) => {
  if (!/\w+(R1|V4|V6|BL|PP|$)+(T|S|D)/.test(sample['Sample ID'])) {
    return {
      ...sample,
      isInvalid: true,
      invalidColumns: [...sample.invalidColumns, 'Sample ID'],
    };
  }
  return {
    ...sample,
    isInvalid: sample.isInvalid ? sample.isInvalid : false,
    invalidColumns: [...sample.invalidColumns],
  };
};

export const validateSamples = (validate) => {
  //This function is to validate the sampleId
  let validArray = [];
  for (let i = 0; i < validate.length; i++) {
    let obj = {
      ...validate[i],
      invalidColumns: [],
    };
    Object.keys(obj)?.forEach((data, key) => {
      switch (data) {
        case 'Sample ID':
          obj = validateSampleId(obj);
          break;
        default:
          return validate;
      }
    });
    validArray.push(obj);
  }
  return validArray;
};

export const invalidCheckForColumns = (dataToBeChecked, dataSource) => {
  let bool = false;
  dataSource?.map((el) => {
    return el?.invalidColumns?.includes(dataToBeChecked) ? (bool = true) : '';
  });
  return bool;
};

export const multiplyColumn = (value, multiplier) => {
  let parsed;
  if (!value || value === '') {
    return '';
  } else if (value.includes('.')) {
    parsed = parseFloat(value);
  } else {
    parsed = parseInt(value);
  }
  return isNaN(parsed) ? value : value * multiplier;
};

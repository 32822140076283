import React from 'react';
import ReactDOM from 'react-dom/client';
import 'tw-elements';
import App from './App';
import { store } from './store';
import { Provider } from 'react-redux';
import 'antd/dist/antd.min.css';
//Import external css file above global css
import './index.css';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
);

import React, { useMemo } from 'react';
import { Table } from 'antd';
import { invalidCheckForColumns } from '../../../helpers';
import { Error } from '../../../assets/icons';
const PreviewTable = ({ i, data }) => {
  let dataForTable = data[i]?.rows;

  const handleErrorOnTable = (val, rowData, data) => {
    return rowData?.invalidColumns?.includes(data) ? (
      <div className="flex items-center text-red-600 font-bold w-full h-full gap-2">
        <span>{rowData[data]}</span>
        <Error />
      </div>
    ) : (
      <div className="flex w-full h-full gap-2 ">
        <span>{rowData[data]}</span>
      </div>
    );
  };

  let columnData;

  dataForTable[0]
    ? (columnData = Object.keys(dataForTable[0])
        ?.map((data, key) => {
          return {
            title: () => {
              return invalidCheckForColumns(data, dataForTable) ? (
                <div className="flex items-center gap-2 text-red-600">
                  <span>{data}</span>
                  <Error />
                </div>
              ) : (
                data
              );
            },
            dataIndex: data,
            key: data['Sample ID'],
            align: 'left',
            render: (val, rowData) => handleErrorOnTable(val, rowData, data),
          };
        })
        .filter(
          (item) => !(item.dataIndex === 'invalidColumns') && !(item.dataIndex === 'isInvalid'),
        ))
    : (columnData = []);

  //For calculating table width based on number of columns
  const tableWidth = columnData.length * 180;

  const srcWithIndex = useMemo(() => {
    const formattedData = dataForTable?.map((data, i) => {
      let indexedData = {
        ...data,
        key: i,
      };
      return indexedData;
    });
    return formattedData;
  }, [dataForTable]);

  return (
    <Table
      dataSource={srcWithIndex}
      columns={columnData}
      scroll={{ x: tableWidth, y: 380 }}
      pagination={false}
      rowClassName={(rowData) => {
        return rowData.isInvalid ? 'bg-red-100' : '';
      }}
    />
  );
};

export default PreviewTable;
